import Breadcrumbs from '@storeblocks/breadcrumbs';
import React from 'react';
import styled from 'styled-components';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { InternalLink } from '@/components/InternalLink';
import { useTexts } from '@/hooks/useTexts';

export const SigningRightBreadcrumb: React.FC = () => {
  const texts = useTexts();
  const organizationCmId = useOrganizationCmId();

  return (
    <StyledBreadcrumbs>
      <InternalLink to={`/${organizationCmId}`}>
        {texts.menu.links.home}
      </InternalLink>

      {/*
       * Do not use a link here as we don't want the user to go
       * to the order pages before the signing process is complete.
       */}
      <div>{texts.menu.links.trading.trading}</div>
    </StyledBreadcrumbs>
  );
};

const StyledBreadcrumbs = styled(Breadcrumbs)`
  & > * + * {
    margin-left: 16px;
  }
`;
