import React from 'react';

import { useFundEsgQuery } from '@/api/esg/esgApi';
import { Center } from '@/components/Center';
import { Column, Row } from '@/components/Grid';
import { Helper } from '@/components/Helper';
import { H2, Paragraph, TextShortBold } from '@/components/Typography';
import { KeyStatistics3 } from '@/components/Typography/KeyStatistic3';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';

import { EsgSolutionsGraph } from '../components/EsgSolutionsGraph';
import {
  InvestmentSolutionsRow,
  InvestmentSolutionsTable,
} from '../components/InvestmentSolutionsTable';
import { LargeText } from '../components/LargeText';

interface Props {
  isin: string;
}

export const EsgSolutions: React.FC<Props> = ({ isin }) => {
  const texts = useTexts();
  const esgFundQuery = useFundEsgQuery({ isin });

  const hasSustainableDebtInstruments =
    esgFundQuery.data.greenBond ||
    esgFundQuery.data.socialBond ||
    esgFundQuery.data.sustainabilityLinkedBond ||
    esgFundQuery.data.sustainabilityBond;

  return (
    <>
      <Center vertical>
        <H2>{texts.pages.esg.solutions.title}</H2>

        {texts.pages.esg.solutions.helpDescription && (
          <Helper
            id="fund-esg-carbon-intensities"
            description={texts.pages.esg.solutions.helpDescription}
          />
        )}
      </Center>

      <Row>
        <Column $span={6}>
          <WithGap gap="16">
            <LargeText>
              {esgFundQuery.data.solutionCompanies.kpi.value} %
            </LargeText>

            <KeyStatistics3>
              {texts.pages.fundEsg.solutions.subtitle}
            </KeyStatistics3>

            <Paragraph>{texts.pages.esg.solutions.description}</Paragraph>

            <WithGap gap="48">
              <InvestmentSolutionsTable>
                {esgFundQuery.data.solutionCompaniesCircularEconomy && (
                  <InvestmentSolutionsRow
                    title={texts.pages.fundEsg.solutions.companyCircularEconomy}
                    value={
                      esgFundQuery.data.solutionCompaniesCircularEconomy.kpi
                        .value
                    }
                  />
                )}

                {esgFundQuery.data.solutionCompaniesSmartCities && (
                  <InvestmentSolutionsRow
                    title={texts.pages.fundEsg.solutions.companySmartCities}
                    value={
                      esgFundQuery.data.solutionCompaniesSmartCities.kpi.value
                    }
                  />
                )}

                {esgFundQuery.data.solutionCompaniesEqualOpportunities && (
                  <InvestmentSolutionsRow
                    title={
                      texts.pages.fundEsg.solutions.companyEqualOpportunities
                    }
                    value={
                      esgFundQuery.data.solutionCompaniesEqualOpportunities.kpi
                        .value
                    }
                  />
                )}

                {esgFundQuery.data.solutionCompaniesRenewableEnergy && (
                  <InvestmentSolutionsRow
                    title={texts.pages.fundEsg.solutions.companyRenewableEnergy}
                    value={
                      esgFundQuery.data.solutionCompaniesRenewableEnergy.kpi
                        .value
                    }
                  />
                )}

                {esgFundQuery.data.solutionCompaniesCrossCutting && (
                  <InvestmentSolutionsRow
                    title={texts.pages.fundEsg.solutions.companyCrossCutting}
                    value={
                      esgFundQuery.data.solutionCompaniesCrossCutting.kpi.value
                    }
                  />
                )}
              </InvestmentSolutionsTable>

              {hasSustainableDebtInstruments && (
                <div>
                  <TextShortBold>
                    {texts.pages.fundEsg.solutions.sustainableDebt}
                  </TextShortBold>

                  <InvestmentSolutionsTable>
                    {esgFundQuery.data.greenBond && (
                      <InvestmentSolutionsRow
                        title={texts.pages.fundEsg.solutions.greenBond}
                        value={esgFundQuery.data.greenBond.kpi.value}
                      />
                    )}

                    {esgFundQuery.data.socialBond && (
                      <InvestmentSolutionsRow
                        title={texts.pages.fundEsg.solutions.socialBonds}
                        value={esgFundQuery.data.socialBond.kpi.value}
                      />
                    )}

                    {esgFundQuery.data.sustainabilityBond && (
                      <InvestmentSolutionsRow
                        title={texts.pages.fundEsg.solutions.sustainabilityBond}
                        value={esgFundQuery.data.sustainabilityBond.kpi.value}
                      />
                    )}

                    {esgFundQuery.data.sustainabilityLinkedBond && (
                      <InvestmentSolutionsRow
                        title={
                          texts.pages.fundEsg.solutions.sustainabilityLinkedBond
                        }
                        value={
                          esgFundQuery.data.sustainabilityLinkedBond.kpi.value
                        }
                      />
                    )}
                  </InvestmentSolutionsTable>
                </div>
              )}
            </WithGap>
          </WithGap>
        </Column>

        <Column $span={6}>
          <EsgSolutionsGraph
            solutionCompaniesEqualOpportunities={
              esgFundQuery.data.solutionCompaniesEqualOpportunities
            }
            solutionCompaniesRenewableEnergy={
              esgFundQuery.data.solutionCompaniesRenewableEnergy
            }
            solutionCompaniesCircularEconomy={
              esgFundQuery.data.solutionCompaniesCircularEconomy
            }
            solutionCompaniesCrossCutting={
              esgFundQuery.data.solutionCompaniesCrossCutting
            }
            solutionCompaniesSmartCities={
              esgFundQuery.data.solutionCompaniesSmartCities
            }
          />
        </Column>
      </Row>
    </>
  );
};
