import React, { PropsWithChildren } from 'react';

import { useOrganizationsQuery } from '@/api/organizations/organizationsApi';
import { InternalUserDoesNotHaveAccessToConnectPage } from '@/auth/InternalUserDoesNotHaveAccessToConnectPage';
import { isExternalUser } from '@/config/utils';

export const HasInternalUserAccess: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { isError } = useOrganizationsQuery(undefined, {
    skip: isExternalUser(),
  });

  if (isExternalUser()) {
    return <>{children}</>;
  }

  if (isError) {
    return <InternalUserDoesNotHaveAccessToConnectPage />;
  }

  return <>{children}</>;
};
