import Button from '@storeblocks/button';
import { Icon } from '@storeblocks/icon';
import React, { FC } from 'react';
import styled from 'styled-components';

import { h2Style } from '@/components/Typography';
import { useTexts } from '@/hooks/useTexts';
import { TrackingElement } from '@/tracking';

export const SustainabilityWidget: FC = () => {
  const texts = useTexts();

  return (
    <Container>
      <Title>{texts.news.assets.sustainability.title}</Title>
      <Illustration name="sustainability" />
      <Button
        as="a"
        variant="outlined"
        href={texts.news.assets.sustainability.link}
        rel="noreferrer"
        target="_blank"
        // We can't use `getTrackId` here because it adds the page name to the track-id.
        // This widget is only used in the menu and we don't want the track-ids in the menu
        // to change for every page change, therefor we're using `menu` to describe placement.
        data-trackid={`${TrackingElement.Link}_go-to-news-assets-sustainability_menu`}
      >
        {texts.news.assets.sustainability.buttonLabel}
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;
  padding: 48px;
  width: 100%;
`;

const Title = styled.h1`
  /* Hierarchical this is a h1, but visually this is a h2. */
  ${h2Style}
  margin-top: 0;
  text-align: center;
  width: 400px;
`;

const Illustration = styled(Icon)`
  & svg {
    width: 115px;
    height: 115px;
  }
`;
