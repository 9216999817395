import Button from '@storeblocks/button';
import React from 'react';

import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';

interface Props {
  onClick(): void;
}

export const SubmitButton: React.FC<Props> = ({ onClick }) => {
  const texts = useTexts();
  const getTrackId = useGetTrackId();

  return (
    <Button
      onClick={onClick}
      data-test="confirm-selection-add-funds-modal"
      data-trackid={getTrackId(
        'buy-selected-funds:add-funds-modal',
        TrackingElement.Button,
      )}
      mr="8px"
    >
      {texts.orders.form.addFundModal.button.confirm}
    </Button>
  );
};
