import React from 'react';

import { useTexts } from '@/hooks/useTexts';
import { FilterChipGroup } from '@/pages/Presentation/filters/FilterChipGroup';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { AssetAllocationViewMode } from '../types/AssetAllocationViewMode';

interface Props {
  checked: AssetAllocationViewMode;
  onChange(selectedViewMode: AssetAllocationViewMode): void;
}

export const AssetAllocationViewModeChips: React.FC<Props> = ({
  checked,
  onChange,
}) => {
  const texts = useTexts();

  const getTrackId = useGetTrackId();
  const trackId = getTrackId(
    'select-asset-allocation-view-mode',
    TrackingElement.FilterChip,
  );

  return (
    <FilterChipGroup
      id="toggle-view-buttons"
      type="choice"
      options={[
        {
          id: 'holdings',
          value: 'holdings',
          label: texts.widgets.assetAllocation.filters.viewMode.holdings,
          inputProps: {
            'data-trackid': trackId,
            'data-trackid-selected-filter': 'holdings',
            'data-trackid-source': 'asset-allocation-view',
            'data-trackid-type': 'filter-chips',
          },
        },
        {
          id: 'performance',
          value: 'performance',
          label: texts.widgets.assetAllocation.filters.viewMode.performance,
          inputProps: {
            'data-trackid': trackId,
            'data-trackid-selected-filter': 'performance',
            'data-trackid-source': 'asset-allocation-view',
            'data-trackid-type': 'filter-chips',
          },
        },
      ]}
      checked={[checked]}
      onChange={(value) => onChange(value[0] as AssetAllocationViewMode)}
    />
  );
};
