import InlineAlert from '@storeblocks/inline-alert';
import React from 'react';

import { useTexts } from '@/hooks/useTexts';

import { FundInfo } from './FundInfo';

interface Props {
  isin: string;
}

export const SellAllInfo: React.FC<Props> = ({ isin }) => {
  const texts = useTexts();

  return (
    <FundInfo>
      <InlineAlert
        data-test={`sell-all-info-inline-${isin}`}
        variant="info"
        title={texts.orders.form.redemption.unitsWillBeUsed}
      />
    </FundInfo>
  );
};
