import Alert from '@storeblocks/alert';
import React from 'react';
import { styled } from 'styled-components';

import { Holdings } from '@/api/fundHoldings/models/Holdings';
import { Fund } from '@/api/funds/models';
import { H2 } from '@/components/Typography';
import { useTexts } from '@/hooks/useTexts';

import { FundListAccordion } from './FundListAccordion';

interface Props {
  allFunds: Fund[];
  holdings: Holdings;
  isHoldingsQueryError: boolean;
}

/**
 * List of all the funds the customer has holdings in.
 */
export const FundsWithHoldings: React.FC<Props> = ({
  allFunds,
  holdings,
  isHoldingsQueryError,
}) => {
  const texts = useTexts();

  if (isHoldingsQueryError) {
    // Display an error message to make it more obvious to it is this
    // component that is failing.
    return (
      <>
        <Heading>{texts.pages.fundList.fundsInYourPortfolio.title}</Heading>

        <Alert
          title={texts.genericError}
          variant="error"
          data-test="fund-list-my-funds-error"
        />
      </>
    );
  }

  const holdingIsins = holdings.all.map((holding) => holding.isin);

  const myFunds = allFunds.filter((fund) => holdingIsins.includes(fund.isin));

  if (myFunds.length === 0) {
    // The customer has no holdings in any Connect funds.
    // Thus, there is nothing to display.
    return null;
  }

  return (
    <>
      <Heading>{texts.pages.fundList.fundsInYourPortfolio.title}</Heading>

      <FundListAccordion
        funds={myFunds}
        data-test="fund-list-my-funds"
        hasHoldingsInFunds
      />
    </>
  );
};

const Heading = styled(H2)`
  margin-bottom: 16px;
`;
