import { format } from 'date-fns';
import * as Yup from 'yup';

import { UserType } from '@/config/types';
import { useTexts } from '@/hooks/useTexts';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { useUserDateFnsLocale } from '@/i18n/hooks';
import { CurrencyCode } from '@/types/CurrencyCode';
import { isMessageValid } from '@/util/isMessageValid';
import { nameof } from '@/util/nameof';

import { TransferOrderFormValues } from '../../types/TransferOrderFormValues';
import { expectedTransferDate } from '../../utils/expectedTransferDate';
import { cosignerFieldValidationSchema } from '../rules/cosignerFieldValidationSchema';
import { portfolioNameFieldValidationSchema } from '../rules/portfolioNameFieldValidationSchema';
import { signatoryOptionValidationSchema } from '../rules/signatoryOptionValidationSchema';
import { signerFieldValidationSchema } from '../rules/signerFieldValidationSchema';
import { signingProviderFieldValidationSchema } from '../rules/signingProviderFieldValidationSchema';

interface ValidationSchema {
  userType: UserType;
}

export const useCreateTransferValidationSchema = ({
  userType,
}: ValidationSchema): Yup.SchemaOf<TransferOrderFormValues> => {
  const texts = useTexts();
  const errorTexts = texts.orders.form.errors;
  const dateFnsLocale = useUserDateFnsLocale();

  return Yup.object({
    fromPortfolioId: portfolioNameFieldValidationSchema({
      portfolioRequired: errorTexts.portfolioRequired,
    }),

    fromPortfolioBankAccountNumber: Yup.string().required(
      errorTexts.portfolioBankAccountRequired,
    ),

    fromCurrency: Yup.mixed()
      .oneOf(Object.values(CurrencyCode))
      .required(errorTexts.currencyRequired),

    toPortfolioId: portfolioNameFieldValidationSchema({
      portfolioRequired: errorTexts.portfolioRequired,
    }),

    toPortfolioBankAccountNumber: Yup.string()
      .required(errorTexts.portfolioBankAccountRequired)
      .when(
        nameof<TransferOrderFormValues>('fromPortfolioBankAccountNumber'),
        (fromPortfolioBankAccountNumber: string, schema) => {
          return schema.test(
            'to-portfolio-bank-account-must-be-different',
            errorTexts.transfer.mustHaveDifferentBankAccount,
            (toPortfolioBankAccountNumber: string) => {
              if (
                !toPortfolioBankAccountNumber ||
                !fromPortfolioBankAccountNumber
              ) {
                // No need to validate if neither bank account is set.
                // We don't want to show the error message in this case.
                return true;
              }

              // The bank account numbers must be different.
              // Makes no sense to transfer money to the same account.
              return (
                toPortfolioBankAccountNumber !== fromPortfolioBankAccountNumber
              );
            },
          );
        },
      ),

    toCurrency: Yup.mixed()
      .oneOf(Object.values(CurrencyCode))
      .required(errorTexts.currencyRequired)
      .when(
        nameof<TransferOrderFormValues>('fromCurrency'),
        (fromCurrency: CurrencyCode, schema) => {
          return schema.test(
            'currency-must-match',
            errorTexts.transfer.mustHaveSameCurrency,
            (toCurrency: CurrencyCode) => {
              if (!toCurrency || !fromCurrency) {
                // No need to validate if neither currency is set.
                // We don't want to show the error message in this case.
                return true;
              }

              return toCurrency === fromCurrency;
            },
          );
        },
      ),

    amount: Yup.number()
      .required(errorTexts.amountRequired)
      .min(1, errorTexts.amountRequired),

    expectedTransferDate: Yup.date()
      .required(texts.orders.form.errors.dateRequired)
      .typeError(
        // Type error is when date parsing fails due to
        // the input value not being parsable by `new Date()`.
        fmsWithTemplate(texts.orders.form.errors.dateFormat, {
          format: expectedTransferDate.dateFormat(dateFnsLocale),
          example: format(
            expectedTransferDate.minimumDate(),
            expectedTransferDate.dateFormat(dateFnsLocale),
          ),
        }),
      )
      .min(
        expectedTransferDate.minimumDate(),
        fmsWithTemplate(texts.orders.form.errors.dateInPast, {
          date: format(
            expectedTransferDate.minimumDate(),
            expectedTransferDate.dateFormat(dateFnsLocale),
          ),
        }),
      ),

    messageToSam: Yup.string()
      .optional()
      .max(
        500,
        fmsWithTemplate(errorTexts.maxMessageLength, {
          max: '500',
        }),
      )
      .test(
        'is-message-valid',
        errorTexts.invalidCharactersInMessage,
        (value, context) => {
          const validationResult = isMessageValid(value || '');

          if (validationResult.isValid) {
            // All good!
            // The message is valid.
            return true;
          }

          // The message is invalid.
          // Return an error with the invalid characters.
          return context.createError({
            message: fmsWithTemplate(errorTexts.invalidCharactersInMessage, {
              characters: validationResult.invalidCharacters.join(' '),
            }),
          });
        },
      ),

    signatoryOption: signatoryOptionValidationSchema({
      signatoryOptionRequired: errorTexts.signatoryOptionRequired,
    }),

    signer: signerFieldValidationSchema({
      signerRequired: errorTexts.signerRequired,
    }),

    cosigner: cosignerFieldValidationSchema({
      cosignerRequired: errorTexts.cosignerRequired,
    }),

    signingProvider: signingProviderFieldValidationSchema(userType, {
      signingProviderRequired: errorTexts.signingProviderRequired,
    }),
  });
};
