import React from 'react';
import styled from 'styled-components';

import { Hint } from '@/components/Typography';
import { useTexts } from '@/hooks/useTexts';

export const ColumnHints: React.FC = () => {
  const texts = useTexts();

  return (
    <ColumnHintsContainer>
      <StyledHint>
        <sup>1</sup>
        {texts.widgets.assetAllocation.table.hints.percent.monthToDate}
      </StyledHint>
      <StyledHint>
        <sup>2</sup>
        {texts.widgets.assetAllocation.table.hints.percent.yearToDate}
      </StyledHint>
    </ColumnHintsContainer>
  );
};

const ColumnHintsContainer = styled.div`
  display: flex;
  padding: 8px 16px;
  gap: 16px;
`;

const StyledHint = styled(Hint)`
  color: var(--color-disabled);

  & sup {
    font-weight: 700;
    margin-right: 4px;
  }
`;
