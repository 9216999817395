import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { useFetchFundHoldingsQuery } from '@/api/fundHoldings/fundHoldingsApi';
import { useFundListQuery } from '@/api/funds/fundsApi';
import { OrderType } from '@/api/order/models/OrderType';
import { OrderValueType } from '@/api/order/models/OrderValueType';
import { FormValidationSummary } from '@/components/Form/FormValidationSummary';
import { formHasErrors } from '@/components/Form/utils/formHasErrors';
import { Column, Row } from '@/components/Grid';
import { useHasPermission } from '@/components/Permissions/useHasPermission';
import { Show } from '@/components/Show';
import { useTexts } from '@/hooks/useTexts';

import { useFundPortfolios } from '../../hooks/useFundPortfolios';
import { SubscriptionOrderFormValues } from '../../types/SubscriptionOrderFormValues';
import { getDefaultSignatoryOption } from '../../utils/getDefaultSignatoryOption';
import { mapHoldingsToSubscriptionFundState } from '../../utils/mapHoldingsToSubscriptionFundState';
import { FundPortfolioSelector } from '../FundPortfolioSelector';
import { SubscriptionFundsTable } from '../FundsTable';
import { NavigationButtons } from '../NavigationButtons';
import { SubscriptionPaymentPlacement } from '../PaymentPlacement';
import { SignatoryOptions } from '../SignatoryOptions';
import { createInitialSubscriptionValues } from './utils/createInitialSubscriptionValues';

export const SubscriptionFormContent: React.FC = () => {
  const texts = useTexts();

  const { data: fundList } = useFundListQuery();
  const { data: fundHoldings } = useFetchFundHoldingsQuery();
  const portfolios = useFundPortfolios(OrderType.Subscription);

  const hasPermission = useHasPermission();

  const { values, errors, submitCount, resetForm } =
    useFormikContext<SubscriptionOrderFormValues>();

  const handlePortfolioOnChange = (portfolioShortName: string): void => {
    const fundStates = mapHoldingsToSubscriptionFundState(
      fundList.byIsin,
      fundHoldings.byPortfolioId[portfolioShortName]?.holdings ?? [],
      OrderValueType.Amount,
    );

    const initialValues = createInitialSubscriptionValues(
      fundStates,
      portfolioShortName,
      values.toValueType,
      getDefaultSignatoryOption(hasPermission),
    );

    resetForm({
      values: initialValues,
    });
  };

  return (
    <Container>
      {portfolios.length > 1 && (
        <Row>
          <Column $span={5}>
            <FundPortfolioSelector
              orderType={OrderType.Subscription}
              initialPortfolioShortName={values.toPortfolioShortName}
              onChange={handlePortfolioOnChange}
            />
          </Column>
        </Row>
      )}

      {/* Only render when the user has selected a portfolio. */}
      <Show when={!!values.toPortfolioShortName} animate>
        <Container>
          <TableContainer>
            <SubscriptionFundsTable />
          </TableContainer>

          <SubscriptionPaymentPlacement />

          <SignatoryOptions />

          <Show when={submitCount > 0 && formHasErrors(errors)} animate>
            <FormValidationSummary
              title={texts.orders.form.errors.validationSummary.title}
              errors={errors}
            />
          </Show>

          <NavigationButtons />
        </Container>
      </Show>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
