import Button from '@storeblocks/button';
import React, { useState } from 'react';
import styled from 'styled-components';

import { Permission } from '@/api/permissions/models/Permission';
import { useHasPermission } from '@/components/Permissions/useHasPermission';
import { useSearchParams } from '@/components/Toolbar/hooks/useSearchParams';
import { TextSearch } from '@/components/Toolbar/TextSearch';
import { useTexts } from '@/hooks/useTexts';
import { media } from '@/styles/variables';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { AddUserModalProps } from './AddAndEditUserModal/AddUserModalProps';

interface SearchParams {
  query?: string;
}

interface Props {
  modal: React.FC<AddUserModalProps>;
  onSearchParamsChange: (params: SearchParams) => void;
}

/**
 * Do not extend this component if only one of the places this is used needs that
 * functionality. Instead create a new toolbar to avoid unnecessary complexity.
 * The only reason this toolbar is shared instead of two separate toolbars is
 * because of SonarQubes duplicate code check does not want to nearly identical components.
 */
export const Toolbar: React.FC<Props> = ({
  modal: Modal,
  onSearchParamsChange,
}) => {
  const texts = useTexts();
  const getTrackId = useGetTrackId();

  const hasPermission = useHasPermission();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const searchParams = useSearchParams<SearchParams>(onSearchParamsChange);

  const updateTextSearch = (query: string): void => {
    searchParams.update((state) => {
      state.query = query;
    });
  };

  return (
    <Wrapper>
      <TextSearch onChange={updateTextSearch} label={texts.admin.user.search} />
      <Button
        onClick={() => setIsModalOpen(true)}
        disabled={!hasPermission(Permission.UserAdminWrite)}
        data-trackid={getTrackId('open-add-user-modal', TrackingElement.Button)}
      >
        {texts.adminModal.addUserButton}
      </Button>
      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;

  & > div + div {
    margin-left: 25px;
  }

  & > button {
    /* Make the add user button align to the right. */
    margin-left: auto;
  }

  @media ${media.tabletAndBelow} {
    flex-flow: column;
    align-items: flex-start;

    & > div + div {
      margin-left: 0;
      margin-top: 16px;
    }

    & > button {
      margin-left: 0;
      margin-top: 16px;
    }
  }
`;
