import { usePortfoliosQuery } from '@/api/portfolios';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { useCurrentUserInfo } from '@/auth/hooks';
import { UserType } from '@/config/types';
import { useTexts } from '@/hooks/useTexts';
import { FMSTexts } from '@/types/fms';
import { isBetaFeaturesOn } from '@/util/isBetaFeaturesOn';

import { MenuItem } from '../types/MenuItem';

export const useMenuItems = (): Array<MenuItem> => {
  const texts = useTexts();
  const organizationCmId = useOrganizationCmId();
  const { userType } = useCurrentUserInfo();
  const isInternalUser = userType === UserType.Internal;

  const portfoliosQuery = usePortfoliosQuery(undefined, {
    skip: !organizationCmId,
  });

  if (organizationCmId) {
    const hasDiscretionaryAccount =
      (portfoliosQuery.data?.portfolios || []).filter((portfolio) => {
        return portfolio.isDiscretionaryPortfolio;
      }).length > 0;

    return getOrganizationMenuItems(
      organizationCmId,
      hasDiscretionaryAccount,
      texts,
    );
  }

  return [
    ...getGlobalMenuItems(texts),
    isInternalUser && {
      id: 'demo-start-page',
      to: '/demo',
      title: texts.menu.links.demoMode,
    },
  ].filter(Boolean);
};

const getOrganizationMenuItems = (
  organizationCmId: string,
  hasDiscretionaryAccount: boolean,
  texts: FMSTexts,
): Array<MenuItem> => {
  return [
    {
      id: 'dashboard',
      to: `/${organizationCmId}`,
      title: texts.menu.links.home,
    },
    {
      id: 'performance-dashboard',
      to: `/${organizationCmId}/performance`,
      title: texts.menu.links.performance,
    },
    {
      id: 'trading-not-eligible',
      to: `/${organizationCmId}/trading/not-eligible`,
      title: texts.menu.links.trading.trading,
    },
    {
      id: 'trading',
      to: `/${organizationCmId}/trading`,
      title: texts.menu.links.trading.trading,
      children: [
        {
          id: 'trading-subscription',
          to: `/${organizationCmId}/trading/subscription`,
          title: texts.menu.links.trading.subscription,
        },
        {
          id: 'trading-redemption',
          to: `/${organizationCmId}/trading/redemption`,
          title: texts.menu.links.trading.redemption,
        },
        {
          id: 'trading-switch',
          to: `/${organizationCmId}/trading/switch`,
          title: texts.menu.links.trading.switch,
        },
        {
          id: 'trading-order-list',
          to: `/${organizationCmId}/trading/orders`,
          title: texts.menu.links.trading.orderList,
        },
      ],
    },
    hasDiscretionaryAccount &&
      isBetaFeaturesOn() && {
        id: 'discretionary',
        to: `/${organizationCmId}/discretionary`,
        title: texts.menu.links.trading.discretionary,
        children: [
          {
            id: 'discretionary-deposit',
            to: `/${organizationCmId}/discretionary/deposit`,
            title: texts.menu.links.trading.deposit,
          },
          {
            id: 'discretionary-withdrawal',
            to: `/${organizationCmId}/discretionary/withdrawal`,
            title: texts.menu.links.trading.withdrawal,
          },
          {
            id: 'discretionary-transfer',
            to: `/${organizationCmId}/discretionary/transfer`,
            title: texts.menu.links.trading.transfer,
          },
        ],
      },
    {
      id: 'reports',
      to: `/${organizationCmId}/reports`,
      title: texts.menu.links.reports.reports,
      children: [
        {
          id: 'reports-portfolio',
          to: `/${organizationCmId}/reports/portfolio`,
          title: texts.menu.links.reports.portfolio,
        },
        {
          id: 'reports-funds',
          to: `/${organizationCmId}/reports/funds`,
          title: texts.menu.links.reports.funds,
        },
        {
          id: 'reports-contract-notes',
          to: `/${organizationCmId}/reports/contract-notes`,
          title: texts.menu.links.contractNotes,
        },
        {
          id: 'presentation',
          to: `/${organizationCmId}/reports/presentation`,
          title: texts.menu.links.presentation,
        },
      ],
    },
    {
      id: 'sustainability',
      to: `/${organizationCmId}/sustainability`,
      title: texts.menu.links.sustainability,
      children: [
        {
          id: 'sustainability-esg',
          to: `/${organizationCmId}/sustainability/esg`,
          title: texts.menu.links.reports.esg,
        },
        {
          id: 'sustainability-engagement',
          to: `/${organizationCmId}/sustainability/engagement`,
          title: texts.menu.links.engagement,
        },
        {
          id: 'sustainability-voting',
          to: `/${organizationCmId}/sustainability/voting`,
          title: texts.menu.links.voting,
        },
      ],
    },
    {
      id: 'fund-list',
      to: `/${organizationCmId}/fund-list`,
      title: texts.menu.links.fundList,
    },
    {
      id: 'documents',
      to: `/${organizationCmId}/documents`,
      title: texts.menu.links.documents,
    },
    {
      id: 'admin',
      to: `/${organizationCmId}/admin/orgusers`,
      title: texts.menu.links.administration,
    },
  ].filter(Boolean);
};

const getGlobalMenuItems = (texts: FMSTexts): Array<MenuItem> => {
  return [
    {
      id: 'home',
      to: '/',
      title: texts.menu.links.home,
    },
  ];
};
