import Checkboxes, { Option } from '@storeblocks/checkbox';
import { useField } from 'formik';
import isString from 'lodash/isString';
import React from 'react';

import { OrderType } from '@/api/order/models/OrderType';
import { useTexts } from '@/hooks/useTexts';
import { nameof } from '@/util/nameof';

import { ConfirmFormValues } from '../types/ConfirmFormValues';

interface Props {
  orderType: OrderType;
}

export const ConfirmInformationIsCorrectCheckbox: React.FC<Props> = ({
  orderType,
}) => {
  const { orders } = useTexts();
  const [field, meta] = useField<boolean>(
    nameof<ConfirmFormValues>('confirmInformationIsCorrect'),
  );

  const titles = {
    [OrderType.Subscription]:
      orders.form.information.subscription.confirm.title,
    [OrderType.Redemption]: orders.form.information.redemption.confirm.title,
    [OrderType.Switch]: orders.form.information.switch.confirm.title,
    [OrderType.Deposit]: orders.form.information.deposit.confirm.title,
    [OrderType.Withdrawal]: orders.form.information.withdrawal.confirm.title,
    [OrderType.Transfer]: orders.form.information.transfer.confirm.title,
  };

  const messages = {
    [OrderType.Subscription]:
      orders.form.information.subscription.confirm.message,
    [OrderType.Redemption]: orders.form.information.redemption.confirm.message,
    [OrderType.Switch]: orders.form.information.switch.confirm.message,
    [OrderType.Deposit]: orders.form.information.deposit.confirm.message,
    [OrderType.Withdrawal]: orders.form.information.withdrawal.confirm.message,
    [OrderType.Transfer]: orders.form.information.transfer.confirm.message,
  };

  const title = titles[orderType];
  const message = messages[orderType];

  // Verify that the order type has a title/message combination.
  // Note that the value from FMS can be an empty string.
  // However, it will never be undefined or null.
  if (!isString(title) || !isString(message)) {
    throw new Error(
      `Missing texts for the order confirmation checkbox for order type [${orderType}].`,
    );
  }

  const options: Option[] = [
    {
      label: title,
      hint: message,
      value: 'confirm',
      id: 'confirm',
    },
  ];

  return (
    <Checkboxes
      id="confirmInformationIsCorrect"
      options={options}
      error={meta.error}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...field}
    />
  );
};
