import Alert from '@storeblocks/alert';
import React, { FC } from 'react';

import { appConfig } from '@/constants/config';
import { useTexts } from '@/hooks/useTexts';
import { FileValidationError } from '@/util/FileHelpers';

interface Props {
  error: FileValidationError;
}

export const FileValidationErrorAlert: FC<Props> = ({ error }: Props) => {
  let errorMessage;
  const texts = useTexts();

  switch (error) {
    case FileValidationError.FILE_NAME_ERROR:
      errorMessage = texts.upload.field.invalidFileNameError;
      break;
    case FileValidationError.FILE_SIZE_ERROR:
      errorMessage = `Maximum file size is ${appConfig.fileUpload.maxFileSizeInMb} MB.`;
      break;
    case FileValidationError.FILE_TYPE_ERROR:
      errorMessage = texts.upload.field.illegalFiletypeError;
      break;
    default:
      errorMessage = `${texts.upload.field.unknownFileError}: ${error}`;
  }

  return <Alert variant="error" title={errorMessage} />;
};
