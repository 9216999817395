import InlineAlert from '@storeblocks/inline-alert';
import React from 'react';

import { useFundListQuery } from '@/api/funds/fundsApi';
import { FundClosedReason } from '@/api/funds/models';
import { OrderType } from '@/api/order/models/OrderType';
import { useTexts } from '@/hooks/useTexts';

import { FundInfo } from './FundInfo';

interface Props {
  isin: string;
  orderType: OrderType;
}

export const ClosedForTradeInfo: React.FC<Props> = ({ isin, orderType }) => {
  const texts = useTexts();

  const { data: fundList } = useFundListQuery();

  const textMap = {
    [OrderType.Subscription]: {
      [FundClosedReason.FundClosed]:
        texts.orders.form.information.subscription.closedForTrade.fundClosed
          .message,

      [FundClosedReason.UnsupportedCurrency]:
        texts.orders.form.information.subscription.closedForTrade
          .unsupportedCurrency.message,

      [FundClosedReason.MissingMarketingPermission]:
        texts.orders.form.information.subscription.closedForTrade
          .missingMarketingPermission.message,

      [FundClosedReason.UnsupportedFund]:
        texts.orders.form.information.subscription.closedForTrade
          .unsupportedFund.message,
    },

    [OrderType.Redemption]: {
      [FundClosedReason.FundClosed]:
        texts.orders.form.information.redemption.closedForTrade.fundClosed
          .message,

      [FundClosedReason.UnsupportedCurrency]:
        texts.orders.form.information.redemption.closedForTrade
          .unsupportedCurrency.message,

      [FundClosedReason.MissingMarketingPermission]:
        texts.orders.form.information.redemption.closedForTrade
          .missingMarketingPermission.message,
    },
  };

  const closedReason = {
    [OrderType.Subscription]: fundList.byIsin[isin].closedForSubscriptionReason,
    [OrderType.Redemption]: fundList.byIsin[isin].closedForRedemptionReason,
  };

  return (
    <FundInfo>
      <InlineAlert
        data-test={`closed-for-trade-${isin}`}
        title={textMap[orderType][closedReason[orderType]]}
        variant="info"
      />
    </FundInfo>
  );
};
