import { skipToken } from '@reduxjs/toolkit/query';
import React, { PropsWithChildren } from 'react';

import { useFmsTextsQuery } from '@/api/fms/fmsApi';
import { useOrganizationsQuery } from '@/api/organizations/organizationsApi';
import { usePortfoliosQuery } from '@/api/portfolios';
import { useCurrentUserSettings } from '@/api/userSettings/hooks/useCurrentUserSettings';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { isOnboardingUserInProgress } from '@/util/isOnNewUserPage';

import { FullPageLoadingIndicator } from './FullPageLoadingIndicator';

/**
 * Display a loading indicator while waiting for the minimum required
 * data for Connect to render its initial screen.
 */
export const WaitForRequiredAppData: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const organizationsQuery = useOrganizationsQuery(undefined, {
    // New users does not have access to any organizations yet
    // and thus this query will return HTTP 403, an error code.
    // Skip this query for new users to avoid getting the error.
    skip: isOnboardingUserInProgress(),
  });

  const userSettingsQuery = useCurrentUserSettings();

  const fmsTextsQuery = useFmsTextsQuery(
    // Wait for the user settings query to finish before fetching texts
    // since we need to know the user's language to fetch the correct texts.
    userSettingsQuery.isSuccess
      ? userSettingsQuery.userSettings.language
      : skipToken,
  );

  const isAnOrganizationSelected = useOrganizationCmId();
  const portfoliosQuery = usePortfoliosQuery(undefined, {
    skip: !isAnOrganizationSelected,
  });

  // Throw an error if the organizations query fails.
  if (organizationsQuery.isError) {
    throw organizationsQuery.error;
  }

  // Throw an error if the user settings query fails.
  if (userSettingsQuery.isError) {
    throw userSettingsQuery.error;
  }

  // Throw an error if the portfolios query fails.
  if (portfoliosQuery.isError) {
    throw portfoliosQuery.error;
  }

  const isLoading = [
    !fmsTextsQuery.data,
    organizationsQuery.isLoading,
    userSettingsQuery.isLoading,
    portfoliosQuery.isLoading,
  ].some(Boolean);

  if (isLoading) {
    return <FullPageLoadingIndicator />;
  }

  return <>{children}</>;
};
