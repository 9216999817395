import Surface from '@storeblocks/surface';
import { format, getYear, parseISO } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

import { FundPerformanceNumbers } from '@/api/funds/models';
import { TextShortBold } from '@/components/Typography';
import { useTexts } from '@/hooks/useTexts';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { useUserDateFormat } from '@/i18n/hooks';

interface Props {
  fundStartDate: string;
  performanceNumbers: FundPerformanceNumbers;
}

export const FundPerformance: React.FC<Props> = ({
  fundStartDate,
  performanceNumbers,
}) => {
  const texts = useTexts();
  const dateFormat = useUserDateFormat();

  const startYear = getYear(parseISO(fundStartDate));
  const returnsSinceStart = fmsWithTemplate(
    texts.pages.fundList.performance.returnsSinceStart,
    {
      year: startYear.toString(),
    },
  );

  if (!performanceNumbers) {
    return (
      <Surface variant="border-alt">
        <Container data-test="fund-performance-ytd">
          <TextShortBold>
            {texts.pages.fundList.performance.ytdReturns}
          </TextShortBold>
          <TextShortBold>
            {texts.pages.fundList.performance.unavailable}
          </TextShortBold>
        </Container>

        <Container data-test="fund-performance-since-start">
          <TextShortBold>{returnsSinceStart}</TextShortBold>
          <TextShortBold>
            {texts.pages.fundList.performance.unavailable}
          </TextShortBold>
        </Container>
      </Surface>
    );
  }

  const ytdDate = format(parseISO(performanceNumbers.date), dateFormat);

  return (
    <StyledSurface variant="border-alt">
      <Container data-test="fund-performance-ytd">
        <TextShortBold>
          {texts.pages.fundList.performance.ytdReturns}
        </TextShortBold>
        <TextShortBold>
          {performanceNumbers.yearToDate.toFixed(2)}%
        </TextShortBold>
        <div>{ytdDate}</div>
      </Container>

      <Container data-test="fund-performance-since-start">
        <TextShortBold>{returnsSinceStart}</TextShortBold>
        <TextShortBold>
          {performanceNumbers.sinceStart.toFixed(2)}%
        </TextShortBold>
        <div>{texts.pages.fundList.performance.yearlyAverage}</div>
      </Container>
    </StyledSurface>
  );
};

const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  line-height: 24px;
`;

const StyledSurface = styled(Surface)`
  display: flex;
`;
