import { Icon } from '@storeblocks/icon';
import Surface from '@storeblocks/surface';
import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

import { OrderType } from '@/api/order/models/OrderType';
import { Hint, TextShortBold } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';

interface Props {
  orderType: OrderType;
  isPastCutoff: boolean;
  cutOffTime: Date;
}

export const CutoffInformationAlert: React.FC<Props> = ({
  orderType,
  isPastCutoff,
  cutOffTime,
}) => {
  const { orders } = useTexts();

  const pastCutoffTitle = {
    [OrderType.Subscription]:
      orders.form.information.subscription.afterCutoff.title,
    [OrderType.Redemption]:
      orders.form.information.redemption.afterCutoff.title,
    [OrderType.Switch]: orders.form.information.switch.afterCutoff.title,
  };

  const pastCutoffMessage = {
    [OrderType.Subscription]:
      orders.form.information.subscription.afterCutoff.message,
    [OrderType.Redemption]:
      orders.form.information.redemption.afterCutoff.message,
    [OrderType.Switch]: orders.form.information.switch.afterCutoff.message,
  };

  const beforeCutoffTitle = {
    [OrderType.Subscription]:
      orders.form.information.subscription.beforeCutoff.title,
    [OrderType.Redemption]:
      orders.form.information.redemption.beforeCutoff.title,
    [OrderType.Switch]: orders.form.information.switch.beforeCutoff.title,
  };

  const beforeCutoffMessage = {
    [OrderType.Subscription]:
      orders.form.information.subscription.beforeCutoff.message,
    [OrderType.Redemption]:
      orders.form.information.redemption.beforeCutoff.message,
    [OrderType.Switch]: orders.form.information.switch.beforeCutoff.message,
  };

  const title = isPastCutoff
    ? pastCutoffTitle[orderType]
    : beforeCutoffTitle[orderType];

  const message = isPastCutoff
    ? pastCutoffMessage[orderType]
    : beforeCutoffMessage[orderType];

  const titleWithTime = fmsWithTemplate(title, {
    time: format(cutOffTime, 'HH.mm (O)'), // E.g. "15.00 (GMT+2)"
  });

  const messageWithTime = fmsWithTemplate(message, {
    time: format(cutOffTime, 'HH.mm (O)'), // E.g. "15.00 (GMT+2)"
  });

  const icon = isPastCutoff ? 'money-padlock' : 'clock-coins';

  return (
    <StyledSurface variant="border" data-test="cutoff-information-alert">
      <Container>
        <Icon name={icon} width="96px" height="96px" />
      </Container>

      <Container>
        <WithGap gap="16">
          <TextShortBold>{titleWithTime}</TextShortBold>
          <Hint>{messageWithTime}</Hint>
        </WithGap>
      </Container>
    </StyledSurface>
  );
};

const StyledSurface = styled(Surface)`
  display: flex;
`;

const Container = styled.div`
  padding: 16px;
`;
