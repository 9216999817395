import Button from '@storeblocks/button';
import Input from '@storeblocks/input';
import Fuse, { IFuseOptions } from 'fuse.js';
import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';

import { Organization } from '@/api/organizations/models/Organization';
import { useOrganizationsQuery } from '@/api/organizations/organizationsApi';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { useCurrentUserInfo } from '@/auth/hooks';
import { Pending } from '@/components/Pending';
import { Show } from '@/components/Show';
import { useTexts } from '@/hooks/useTexts';
import { media } from '@/styles/variables';
import { removeDiacritics } from '@/util/removeDiacritics';

import { H2 } from '../Typography';
import { OrganizationList } from './OrganizationList';

interface Props {
  excludeCurrentOrganization?: boolean;
  initialCollapseSize?: number;
  smallSearchField?: boolean;
}

export const OrganizationSelector: FC<Props> = ({
  excludeCurrentOrganization = false,
  initialCollapseSize,
  smallSearchField,
}) => {
  const texts = useTexts();
  const [searchValue, setSearchValue] = useState('');
  const [expanded, setExpanded] = useState<boolean>(false);

  const organizationsQuery = useOrganizationsQuery();
  const organizationCmId = useOrganizationCmId();

  const { userType } = useCurrentUserInfo();

  const availableOrganizations = useMemo(() => {
    let organizationsToDisplay = organizationsQuery.data;

    if (excludeCurrentOrganization) {
      organizationsToDisplay = organizationsQuery.data.filter(
        (organization) => organization.organizationId !== organizationCmId,
      );
    }

    return organizationsToDisplay;
  }, [
    organizationCmId,
    organizationsQuery.data,
    excludeCurrentOrganization,
    userType,
  ]);

  const [filteredOrganizations, setFilteredOrganizations] = useState(
    availableOrganizations,
  );

  const fuse = useMemo(() => {
    const normalizedOrganizations = availableOrganizations.map(
      (organization) => ({
        ...organization,
        organizationName: removeDiacritics(organization.organizationName),
      }),
    );

    const searchOptions: IFuseOptions<Organization> = {
      keys: ['organizationName'],
      threshold: 0.2,

      // Make so it won't matter where in the string the pattern appears.
      // We had issues previously where the search would not find the correct organization.
      // E.g. searching for "str" would not find "PRÄSTLÖNETILLGÅNGAR I STRÄNGNÄS STIFT" due to
      // the "STR" being too far from the beginning of the string.
      ignoreLocation: true,
    };

    return new Fuse(normalizedOrganizations, searchOptions);
  }, [availableOrganizations]);

  const collapseSize = initialCollapseSize || filteredOrganizations.length;

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value) {
      const normalizedSearchValue = removeDiacritics(event.target.value);
      const results = fuse.search(normalizedSearchValue);

      const organizationCmIds = results.map((res) => res.item.organizationId);
      const filtered = availableOrganizations.filter((organization) =>
        organizationCmIds.includes(organization.organizationId),
      );

      setFilteredOrganizations(filtered);
    } else {
      setFilteredOrganizations(availableOrganizations);
    }

    setExpanded(!!event.target.value);
    setSearchValue(event.target.value);
  };

  return (
    <>
      <Title>{texts.home.organizations.title}</Title>
      <Show when={availableOrganizations.length > collapseSize}>
        <SearchWrapper smallSearchField={smallSearchField}>
          <Input
            id="organization-search"
            name="organization-search"
            label={texts.home.organizations.search.label}
            icon="search"
            value={searchValue}
            onChange={handleSearch}
            fluid
            inputProps={{
              autoFocus: true,
              autoComplete: 'off',
            }}
          />
        </SearchWrapper>
      </Show>
      <Show when={organizationsQuery.isLoading}>
        <Pending center />
      </Show>
      <Show when={!organizationsQuery.isLoading}>
        <OrganizationList
          organizations={filteredOrganizations}
          collapseSize={collapseSize}
          expanded={expanded}
        />
        <Show when={filteredOrganizations.length > collapseSize}>
          <ExpandButton
            variant="text"
            iconRight={expanded ? 'arrow-up' : 'arrow-down'}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded
              ? texts.home.organizations.collapse
              : texts.home.organizations.expand}
          </ExpandButton>
        </Show>
      </Show>
    </>
  );
};

const Title = styled(H2)`
  margin: 0px 0px 24px 0px;
`;

interface SearchWrapperProps {
  smallSearchField: boolean;
}
const SearchWrapper = styled.div<SearchWrapperProps>`
  padding-bottom: 24px;
  @media ${media.tabletAndAbove} {
    width: ${(props) => (props.smallSearchField ? '50%' : '100%')};
  }
`;

const ExpandButton = styled(Button)`
  margin-top: 24px;
`;
