import format from 'date-fns/format';

import { useTexts } from '@/hooks/useTexts';
import { useUserDateFormat } from '@/i18n/hooks';

import { usePresentationContext } from '../../PresentationContext';

export const useDateFilterText = (): string => {
  const texts = useTexts();
  const {
    globalFilter: { date },
  } = usePresentationContext();
  const dateFormat = useUserDateFormat();

  return `${texts.pages.presentation.filters.date.per} ${format(
    date,
    dateFormat,
  )}`;
};
