import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { useTexts } from '@/hooks/useTexts';
import { FMSTexts } from '@/types/fms';

import { MenuItem } from '../types/MenuItem';

export const useCommonMenuItems = (): Array<MenuItem> => {
  const texts = useTexts();
  const organizationCmId = useOrganizationCmId();

  if (organizationCmId) {
    return getOrganizationMenuItems(organizationCmId, texts);
  }

  return getGlobalMenuItems(texts);
};

const getOrganizationMenuItems = (
  organizationCmId: string,
  texts: FMSTexts,
): Array<MenuItem> => {
  return [
    {
      id: 'profile-and-language',
      to: `/${organizationCmId}/user-settings`,
      title: texts.menu.links.profileAndLanguage,
    },
    {
      id: 'news',
      to: `/${organizationCmId}/news`,
      title: texts.menu.links.news,
    },
    {
      id: 'contact-us',
      to: `/${organizationCmId}/contact-us/norway`,
      title: texts.menu.links.contactUs,
    },
  ];
};

const getGlobalMenuItems = (texts: FMSTexts): Array<MenuItem> => {
  return [
    {
      id: 'news',
      to: '/news',
      title: texts.menu.links.news,
    },
    {
      id: 'contact-us',
      to: '/contact-us/norway',
      title: texts.menu.links.contactUs,
    },
  ];
};
