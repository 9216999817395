import { format } from 'date-fns';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { QueryState } from '@/api/components/QueryState';
import {
  formatDateArgument,
  useFetchAssetPerformanceQuery,
} from '@/api/performanceData/performanceDataApi';
import { isNotReady } from '@/api/utils/isNotReady';
import {
  AssetReturnChart,
  AssetReturnPeriod,
  AssetReturnPeriodChips,
} from '@/components/AssetReturnChart';
import { UnitChips } from '@/components/Charts/components/UnitChips';
import { H2, Hint } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { useUserDateFormat } from '@/i18n/hooks';
import { Unit } from '@/types/Unit';

interface Props {
  portfolioGroup: string;
  date?: Date;
}

const today = new Date();

export const AssetReturnWidget: FC<Props> = ({ portfolioGroup, date }) => {
  const texts = useTexts();
  const dateFormat = useUserDateFormat();

  const [checkedPeriod, setCheckedPeriod] = useState<AssetReturnPeriod>(
    AssetReturnPeriod.YTD,
  );

  const [checkedUnit, setCheckedUnit] = useState<Unit>(Unit.Percent);

  const assetPerformanceQuery = useFetchAssetPerformanceQuery({
    portfolioGroup,
    to: formatDateArgument(date ?? today),
  });

  const updatedDate = assetPerformanceQuery.data?.date;

  return (
    <WithGap data-test={`widget-asset-class-return-${portfolioGroup}`}>
      <H2>{texts.charts.assetReturn.title}</H2>

      {isNotReady(assetPerformanceQuery) ? (
        <QueryState query={assetPerformanceQuery} />
      ) : (
        <WithGap>
          <FilterContainer>
            <AssetReturnPeriodChips
              id="asset-return-widget-period-filter"
              checked={checkedPeriod}
              onChange={setCheckedPeriod}
            />
            <UnitChips
              id="asset-return"
              checked={checkedUnit}
              onChange={setCheckedUnit}
            />
          </FilterContainer>
          <UpdatedText>
            {fmsWithTemplate(texts.charts.assetReturn.updated, {
              date: updatedDate && format(updatedDate, dateFormat),
            })}
          </UpdatedText>
          <AssetReturnChart
            assets={assetPerformanceQuery.data.assets}
            period={checkedPeriod}
            unit={checkedUnit}
          />
        </WithGap>
      )}
    </WithGap>
  );
};

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UpdatedText = styled(Hint)`
  color: var(--color-disabled);
`;
