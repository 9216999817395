import styled from 'styled-components';

import { a4MaxWidthInPixels } from '../utils/a4MaxWidthInPixels';

export const A4WidthForPrint = styled.div`
  @media print {
    // Set the max-width when printing to ensure that the screen fits an A4 page.
    ${a4MaxWidthInPixels}
  }
`;
