import Alert from '@storeblocks/alert';
import React from 'react';

import { Pending } from '@/components/Pending';
import { useTexts } from '@/hooks/useTexts';

import { Query } from '../types/Query';
import { hasData } from '../utils/hasData';
import { toArray } from '../utils/toArray';

interface Props {
  query: Query | Query[];
  customTexts?: {
    error?: string;
    noData?: {
      title?: string;
      description?: string;
    };
  };
}

export const QueryState: React.FC<Props> = ({ query, customTexts }) => {
  const texts = useTexts();

  const queries: Query[] = toArray(query);

  if (queries.some((response) => response.isError)) {
    return (
      <Alert title={customTexts?.error || texts.genericError} variant="error" />
    );
  }

  if (queries.some((request) => request.isLoading || request.isFetching)) {
    return <Pending center />;
  }

  if (queries.some((response) => !hasData(response.data))) {
    return (
      <Alert
        title={customTexts?.noData?.title || texts.noDataLabel}
        description={customTexts?.noData?.description}
        variant="info"
        data-test="no-data"
      />
    );
  }

  throw new Error('Invalid query state.');
};
