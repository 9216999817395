import React from 'react';
import styled from 'styled-components';

import { OrderValueType } from '@/api/order/models/OrderValueType';
import { useTexts } from '@/hooks/useTexts';
import { useUserLocale } from '@/i18n/hooks';
import { formatNumber } from '@/util/formatNumber';

import { FundState } from '../../../types/FundState';
import { CellWrapper } from './CellWrapper';

interface Props {
  fund: FundState;
}

export const HoldingsCell: React.FC<Props> = ({ fund }) => {
  const locale = useUserLocale();

  const texts = useTexts();

  if (fund.units === 0) {
    // Don't render anything if there isn't any holdings.
    // This is to keep the UI clean and tidy as the formatAmount will
    // display "0 NOK".
    return null;
  }

  if (fund.valueType === OrderValueType.Amount) {
    return (
      <Wrapper>{formatNumber(fund.marketValue, locale, fund.currency)}</Wrapper>
    );
  }

  return (
    <Wrapper>
      {formatNumber(
        fund.units,
        locale,
        texts.orders.form.valueType.units.toLowerCase(),
      )}
    </Wrapper>
  );
};

const Wrapper = styled(CellWrapper)`
  justify-content: flex-end;
`;
