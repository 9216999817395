import { MediumCard } from '@storeblocks/card';
import React from 'react';
import styled from 'styled-components';

import { QueryState } from '@/api/components/QueryState';
import { useIHubMonthlyReportsQuery } from '@/api/reports/reportsApi';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { isNotReady } from '@/api/utils/isNotReady';
import { Column, Row } from '@/components/Grid';
import { Helper } from '@/components/Helper';
import { InternalLink } from '@/components/InternalLink';
import { H2, H3 } from '@/components/Typography';
import { SurfaceWidget } from '@/components/Widget';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { ReportSource } from '@/pages/Reports/types/ReportModel';
import { TrackingElement, useGetTrackId } from '@/tracking';
import { downloadFilesFromIhub } from '@/util/downloadFilesFromIHub';

import { ReportListItem } from './ReportListItem';

export const LatestMonthlyReportsWidget: React.FC = () => {
  const fms = useTexts();
  const texts = fms.widgets.reports.latestMonthlyReports;

  const getTrackId = useGetTrackId();
  const cmId = useOrganizationCmId();

  const monthlyReportsQuery = useIHubMonthlyReportsQuery();

  return (
    <WithGap gap="32">
      <H2>{texts.header}</H2>
      <Row>
        <Column $span={8}>
          <SurfaceWidget data-test="latest-monthly-reports-widget">
            <WithGap direction="row" gap="8">
              <H3>{texts.title}</H3>
              <Helper
                id="latest-monthly-reports"
                title={texts.help.title}
                description={texts.help.description}
              ></Helper>
            </WithGap>
            {isNotReady(monthlyReportsQuery) ? (
              <QueryState
                query={monthlyReportsQuery}
                customTexts={{
                  noData: {
                    title: texts.noData,
                  },
                  error: texts.error,
                }}
              />
            ) : (
              <List>
                {monthlyReportsQuery.data?.map((report) => {
                  return (
                    <ReportListItem
                      key={report.id}
                      filename={report.name}
                      fileId={report.id}
                      onDownload={() =>
                        downloadFilesFromIhub(
                          [report.id],
                          ReportSource.IHubOrganization,
                        )
                      }
                    />
                  );
                })}
              </List>
            )}
          </SurfaceWidget>
        </Column>
        <Column $span={4}>
          <WithGap>
            <MediumCard
              as={InternalLink}
              to={`/${cmId}/reports/portfolio`}
              icon="tablet-graph-magnifying-glass"
              title={texts.archiveLink}
              data-trackid={getTrackId(
                'go-to-reports-archive',
                TrackingElement.Link,
              )}
              data-test="reports-page-portfolio-link"
            />
            <MediumCard
              as={InternalLink}
              to={`/${cmId}/reports/funds`}
              icon="tablet-hand-graph"
              title={texts.fundReports}
              data-trackid={getTrackId(
                'go-to-reports-funds',
                TrackingElement.Link,
              )}
              data-test="reports-page-funds-link"
            />
            <MediumCard
              as={InternalLink}
              to={`/${cmId}/reports/presentation`}
              icon="presentation"
              title={texts.presentationMode}
              data-trackid={getTrackId(
                'go-to-reports-presentation',
                TrackingElement.Link,
              )}
              data-test="reports-page-presentation-link"
            />
          </WithGap>
        </Column>
      </Row>
    </WithGap>
  );
};

const List = styled.ul`
  flex: 1;
  list-style: none;
  padding: 0;

  & li:first-child {
    border-top: 1px solid var(--color-border);
  }

  & li:hover {
    color: var(--color-primary-variant);
    background: var(--color-overlay-hover-darken);
  }
`;
