import Checkbox from '@storeblocks/checkbox';
import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { OrderType } from '@/api/order/models/OrderType';
import { OrderValueType } from '@/api/order/models/OrderValueType';
import { useTexts } from '@/hooks/useTexts';
import { useOrderPageContext } from '@/pages/Order/OrderPageContext';
import { RedemptionFundState } from '@/pages/Order/steps/OrderDetails/types/RedemptionFundState';

import { RedemptionOrderFormValues } from '../../../types/RedemptionOrderFormValues';
import { useArrayFieldByIsin } from '../hooks/useArrayFieldByIsin';
import { CellWrapper } from './CellWrapper';

interface Props {
  isin: string;
}

export const SellAllCheckbox: React.FC<Props> = ({ isin }) => {
  const { orders } = useTexts();
  const { orderType } = useOrderPageContext();

  const { values } = useFormikContext<RedemptionOrderFormValues>();

  const textMap = {
    [OrderType.Redemption]: orders.form.table.sellAll,
    [OrderType.Switch]: orders.form.table.switchAll,
  };

  const [field, , helpers] = useArrayFieldByIsin<RedemptionFundState>(
    'fromFunds',
    isin,
  );

  const handleSellAllChange = (checked: boolean): void => {
    if (checked) {
      helpers.setValue({
        ...field.value,
        sellAll: checked,
        value: field.value.units,
        valueType: OrderValueType.Units,
      });
    } else {
      helpers.setValue({
        ...field.value,
        sellAll: checked,
        value: 0,
        valueType: values.fromValueType,
      });
    }
  };

  return (
    <CellWrapper>
      <StyledLabel htmlFor={`sellAll-fund-${isin}`}>
        {textMap[orderType]}
      </StyledLabel>
      <Checkbox
        id={`sellAll-fund-${isin}`}
        name={`sellAll-fund-${isin}`}
        options={[
          {
            id: `sellAll-fund-${isin}`,
            label: '',
            value: 'true',
          },
        ]}
        value={[`${field.value.sellAll}`]}
        onChange={(event) => handleSellAllChange(event.target.checked)}
        disabled={field.value.isClosed}
      />
    </CellWrapper>
  );
};

const StyledLabel = styled.label`
  margin-right: 8px;
`;
