import InlineAlert from '@storeblocks/inline-alert';
import LoadingIndicator from '@storeblocks/loading-indicator';
import { IColumn, IRow, Table } from '@storeblocks/table';
import Fuse from 'fuse.js';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { Organization } from '@/api/organizations/models/Organization';
import { useOrganizationsQuery } from '@/api/organizations/organizationsApi';
import { EditAndDeleteButtons } from '@/components/EditAndDeleteButtons';
import { Show } from '@/components/Show';
import { useTexts } from '@/hooks/useTexts';
import { FMSTexts } from '@/types/fms';
import { prettifyOrganizationName } from '@/util/prettifyOrganizationName';

import { AddOrganizationModal } from './AddOrganizationModal';
import { DeleteOrganizationModal } from './DeleteOrganizationModal';
import { EditOrganizationModal } from './EditOrganizationModal';
import { Toolbar } from './Toolbar';

const getColumns = (texts: FMSTexts): IColumn[] => [
  {
    key: 'orgName',
    name: texts.adminModal.orgName,
    dataType: 'text',
  },
  {
    key: 'actions',
    name: '',
    dataType: 'other',
    renderType: 'jsx',
    style: { textAlign: 'right' },
  },
];

export const OrganizationAdmin: FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const [showAll, setShowAll] = useState(false);
  const [newOrgModalIsOpen, setNewOrgModalIsOpen] = useState(false);

  const [editOrgId, setEditOrgId] = useState('');
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const texts = useTexts();

  const organizationsQuery = useOrganizationsQuery();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
    setShowAll(false);
  };

  const handleEdit = (organizationId): void => {
    setEditOrgId(organizationId);
    toggleEditModal();
  };

  const handleDelete = (organizationId): void => {
    setEditOrgId(organizationId);
    toggleDeleteModal();
  };

  const toggleShowAll = (): void => {
    setShowAll((prevState) => !prevState);
    setSearchValue('');
  };

  const toggleAddModal = (): void => {
    setNewOrgModalIsOpen((prevState) => !prevState);
  };

  const toggleEditModal = (): void => {
    setEditModalIsOpen((prevState) => !prevState);
  };

  const toggleDeleteModal = (): void => {
    setDeleteModalIsOpen((prevState) => !prevState);
  };

  const generateTableData = (): IRow[] =>
    filterOrganizations().map((organization) => {
      const { organizationId, organizationName } = organization;
      return {
        orgName: prettifyOrganizationName(organizationName) || '-',
        actions: {
          displayContent: (
            <EditAndDeleteButtons
              onEdit={() => handleEdit(organizationId)}
              onDelete={() => handleDelete(organizationId)}
              alignRight
              actionContext="organization"
              id={organizationId}
            />
          ),
          sortContent: organizationName,
        },
      };
    });

  const filterOrganizations = (): Organization[] => {
    if (searchValue) {
      const searchOptions = {
        keys: ['organizationName', 'organizationId', 'organizationNumber'],
        threshold: 0.2,
      };

      const fuse = new Fuse(organizationsQuery.data, searchOptions);
      const res = fuse.search(searchValue);
      return res.map((hit) => hit.item);
    }
    return organizationsQuery.data;
  };

  const tableData = generateTableData();

  return (
    <>
      <StyledToolbar
        onSearch={handleSearch}
        searchValue={searchValue}
        showAll={showAll}
        onShowAllClick={toggleShowAll}
        onAddClick={toggleAddModal}
      />
      <Show when={organizationsQuery.isSuccess}>
        <Show when={(showAll || !!searchValue) && tableData.length > 0}>
          <Table
            columns={getColumns(texts)}
            data={tableData}
            sortable
            data-test="organizations-table"
          />
        </Show>
        <Show when={tableData.length === 0}>
          <InlineAlert title={texts.admin.organization.nonFound} />
        </Show>
        <AddOrganizationModal
          show={newOrgModalIsOpen}
          onClose={toggleAddModal}
        />
        <Show when={editModalIsOpen}>
          <EditOrganizationModal
            organizationId={editOrgId}
            closeModal={toggleEditModal}
          />
        </Show>
        <Show when={deleteModalIsOpen}>
          <DeleteOrganizationModal
            organizationId={editOrgId}
            onClose={toggleDeleteModal}
          />
        </Show>
      </Show>
      <Show when={organizationsQuery.isLoading}>
        <LoadingIndicator />
      </Show>
      <Show when={organizationsQuery.isError}>
        <InlineAlert variant="error" title={texts.admin.organization.error} />
      </Show>
    </>
  );
};

const StyledToolbar = styled(Toolbar)`
  margin-bottom: 32px;
`;
