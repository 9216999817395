import { useTexts } from '@/hooks/useTexts';
import { TableColumn } from '@/types/tables';

import { HoldingsRow } from '../types/HoldingsRow';

export const usePortfolioHoldingsColumns = (): Array<
  TableColumn<HoldingsRow>
> => {
  const texts = useTexts();

  return [
    {
      name: texts.components.portfolioHoldingsTable.columns.name,
      key: 'name',
      renderType: 'jsx',
      dataType: 'other',
    },
    {
      name: texts.components.portfolioHoldingsTable.columns.shortName,
      key: 'shortName',
      renderType: 'jsx',
      dataType: 'other',
    },
    {
      name: texts.components.portfolioHoldingsTable.columns.instrumentType,
      key: 'instrumentType',
      renderType: 'jsx',
      dataType: 'other',
    },
    {
      name: texts.components.portfolioHoldingsTable.columns.nominal,
      key: 'nominal',
      dataType: 'text',
    },
    {
      name: texts.components.portfolioHoldingsTable.columns.cleanPrice,
      key: 'cleanPrice',
      renderType: 'jsx',
      dataType: 'other',
    },
    {
      name: texts.components.portfolioHoldingsTable.columns.bookValue,
      key: 'bookValue',
      renderType: 'jsx',
      dataType: 'other',
    },
    {
      name: texts.components.portfolioHoldingsTable.columns.cleanValue,
      key: 'cleanValue',
      renderType: 'jsx',
      dataType: 'other',
    },
    {
      name: texts.components.portfolioHoldingsTable.columns.accruedInterest,
      key: 'accruedInterest',
      renderType: 'jsx',
      dataType: 'other',
    },
    {
      name: texts.components.portfolioHoldingsTable.columns.unrealizedPLValue,
      key: 'unrealizedPLValue',
      renderType: 'jsx',
      dataType: 'other',
    },
  ];
};
