import { css } from 'styled-components';

/**
 * A CSS rule that sets the maximum width to
 * the width of an A4 paper in pixels.
 *
 * Useful for ensuring that the content fits an A4 page
 * when printing.
 */
export const a4MaxWidthInPixels = css`
  max-width: 1524px;
`;
