import React, { FC } from 'react';
import styled from 'styled-components';

import { appConfig } from '@/constants/config';
import { useTexts } from '@/hooks/useTexts';

export const Disclaimers: FC = () => {
  const texts = useTexts();

  return (
    <Container>
      <Disclaimer>{texts.footer.disclaimer}</Disclaimer>
      <Disclaimer>{texts.disclaimer.notMarketing}</Disclaimer>
    </Container>
  );
};

const Container = styled.div`
  max-width: ${appConfig.maxPageWidth};
  margin: 96px auto 40px;
  border-top: 1px solid var(--color-border);
  flex-shrink: 0;
  align-self: center;
`;

const Disclaimer = styled.p`
  margin-top: 32px;
  font-family: Open Sans;
`;
