import { useContext } from 'react';

import { TextsContext } from '@/components/TextsProvider';
import { FMSTexts } from '@/types/fms';

/**
 * Get texts to be used in the application.
 */
export function useTexts(): FMSTexts {
  const context = useContext(TextsContext);

  if (context === undefined) {
    throw new Error('useTexts must be used within a TextsProvider');
  }

  return context;
}
