import Button from '@storeblocks/button';
import React from 'react';
import styled from 'styled-components';

import { useTexts } from '@/hooks/useTexts';

import { h2Style } from './Typography';

export const GoToMainContent: React.FC = () => {
  const texts = useTexts();

  const handleOnClick = (): void => {
    const mainContent = document.getElementById('main-content');
    if (mainContent) {
      mainContent.focus();
    }
  };

  return (
    <Wrapper>
      <Link variant="text" onClick={handleOnClick}>
        {texts.application.goToMainContent}
      </Link>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 64px;
  background-color: #454545;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;

  &:focus-within {
    position: relative;
  }

  @media print {
    display: none;
  }
`;

const Link = styled(Button)`
  // The link is not semantically a H2, but should be styled as one.
  ${h2Style}
  color: var(--color-on-primary);
  text-decoration: none;

  &:hover,
  &:focus {
    color: var(--color-on-primary);
  }
`;
