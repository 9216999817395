import { format, parseISO } from 'date-fns';
import React from 'react';

import { H1, H2, Hint } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { useUserDateFormat } from '@/i18n/hooks';
import { EsgOverview } from '@/pages/Esg/widgets/EsgOverview';
import { WithPortfolioGroupEsgData } from '@/pages/Esg/WithPortfolioGroupEsgData';

import { A4WidthForPrint } from '../components/A4WidthForPrint';
import { AvoidPageBreaksInside } from '../components/AvoidPageBreakInside';
import { HeaderContainer } from '../components/HeaderContainer';
import { StyledStorebrandLogo } from '../components/StyledStorebrandLogo';
import { usePresentationContext } from '../PresentationContext';

export const EsgOverviewScreen: React.FC = () => {
  const texts = useTexts();
  const dateFormat = useUserDateFormat();

  const { globalFilter } = usePresentationContext();

  return (
    <A4WidthForPrint>
      <WithGap data-test="esg-overview-screen">
        <HeaderContainer>
          <H1>{texts.pages.presentation.screens.esg.overview}</H1>
          <StyledStorebrandLogo />
        </HeaderContainer>

        <WithGap gap="48">
          {globalFilter.portfolioGroups.map((portfolioGroup) => (
            <AvoidPageBreaksInside key={portfolioGroup.id}>
              <WithGap gap="16">
                <H2>{`${portfolioGroup.id} - ${portfolioGroup.name}`}</H2>

                <WithPortfolioGroupEsgData
                  portfolioGroupId={portfolioGroup.id}
                  render={(esgData) => (
                    <>
                      <Hint>
                        {fmsWithTemplate(texts.common.lastUpdated, {
                          date: format(
                            parseISO(esgData.updatedDate),
                            dateFormat,
                          ),
                        })}
                      </Hint>

                      <EsgOverview
                        esgScore={esgData.esgScore}
                        solutionCompanies={esgData.solutionCompanies}
                        euTaxonomyAlignment={esgData.euTaxonomyAlignment}
                        euTaxonomyEligibility={esgData.euTaxonomyEligibility}
                        carbonIntensities={esgData.carbonIntensities}
                        pai11GhgEmissionsSum={esgData.pai11GhgEmissionsSum}
                      />
                    </>
                  )}
                ></WithPortfolioGroupEsgData>
              </WithGap>
            </AvoidPageBreaksInside>
          ))}
        </WithGap>
      </WithGap>
    </A4WidthForPrint>
  );
};
