import React, { FC } from 'react';
import Markdown, { Components } from 'react-markdown';

import { Column, Row } from '@/components/Grid';
import { Paragraph } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';

import { EsgExclusionsList } from './EsgExclusionsList';

export const EsgExclusions: FC = () => {
  const texts = useTexts();

  return (
    <Row>
      <Column $span={6}>
        <WithGap>
          <Markdown components={components}>
            {texts.pages.esg.exclusions.info}
          </Markdown>
        </WithGap>
      </Column>

      <Column $span={6}>
        <EsgExclusionsList />
      </Column>
    </Row>
  );
};

/**
 * Override the mapping of markdown elements to HTML components.
 *
 * We want to use our own React components instead of the
 * default HTML elements.
 *
 * I.e. the markdown library maps the `###` markdown level 3 heading element to
 * the HTML `h3` element. However, if we want to use our
 * own components we map it here.
 */
const components: Partial<Components> = {
  p: ({ children }) => <Paragraph>{children}</Paragraph>,
  a: ({ children, href }) => (
    <a href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  ),
};
