import { SystemIcon } from '@storeblocks/icon';
import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';

import { Organization } from '@/api/organizations/models/Organization';
import { Show } from '@/components/Show';
import { TextShort } from '@/components/Typography';
import { useTexts } from '@/hooks/useTexts';
import { prettifyOrganizationName } from '@/util/prettifyOrganizationName';

interface Props {
  organizations: Array<Organization>;
  collapseSize: number;
  expanded: boolean;
}

export const OrganizationList: FC<Props> = ({
  organizations,
  collapseSize,
  expanded,
}) => {
  const texts = useTexts();

  const visibleOrganizations = useMemo(() => {
    if (expanded) {
      return organizations;
    }
    return organizations.slice(0, collapseSize);
  }, [organizations, collapseSize, expanded]);

  return (
    <>
      <Show when={organizations.length === 0}>
        <TextShort>{texts.home.organizations.empty}</TextShort>
      </Show>
      <AnimatePresence initial={false}>
        {visibleOrganizations.map((organization) => (
          <AnimatedLink
            transition={{ ease: 'easeInOut' }}
            initial={{ height: 0, padding: 0, opacity: 0 }}
            animate={{
              height: '25px',
              padding: '16px 0',
              opacity: 1,
            }}
            exit={{ height: 0, padding: 0, opacity: 0 }}
            key={organization.organizationId}
            href={`/${organization.organizationId}`}
          >
            <span>
              {prettifyOrganizationName(organization.organizationName)}
            </span>
            <SystemIcon name="arrow-right" aria-hidden="true" />
          </AnimatedLink>
        ))}
      </AnimatePresence>
    </>
  );
};

const AnimatedLink = styled(motion.a)`
  color: var(--color-on-background);
  border-top: 1px solid var(--color-border);
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
  padding: 16px 0;

  &:hover {
    background: var(--color-overlay-hover-darken);
  }

  &:last-of-type {
    border-bottom: 1px solid var(--color-border);
  }
`;
