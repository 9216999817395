import StoreblocksStepper, { Step } from '@storeblocks/stepper';
import React from 'react';

import { useTexts } from '@/hooks/useTexts';

interface Props {
  /**
   * Steps begin with 1.
   */
  activeStep: number;
}

export const Stepper: React.FC<Props> = ({ activeStep }) => {
  const texts = useTexts();

  return (
    <StoreblocksStepper
      // Allow us to begin with step 1 instead of 0.
      // This is more intuitive.
      active={activeStep - 1}
    >
      <Step
        title={texts.orders.signatureRight.stepper.step1.title}
        caption={texts.orders.signatureRight.stepper.step1.caption}
      />
      <Step
        title={texts.orders.signatureRight.stepper.step2.title}
        caption={texts.orders.signatureRight.stepper.step2.caption}
      />
      <Step
        title={texts.orders.signatureRight.stepper.step3.title}
        caption={texts.orders.signatureRight.stepper.step3.caption}
      />
    </StoreblocksStepper>
  );
};
