import Alert from '@storeblocks/alert';
import { FormikProvider, useFormik } from 'formik';
import React, { FC, useEffect } from 'react';

import { OrganizationUser } from '@/api/organizationUsers/models/OrganizationUser';
import {
  useEditOrganizationUserMutation,
  useOrganizationUserRolesQuery,
} from '@/api/organizationUsers/organizationUsersApi';
import { FormRow } from '@/components/Form/FormRow';
import { useTexts } from '@/hooks/useTexts';

import { RolesSelection } from '../RolesSelection';
import { UserAdminForm } from './UserAdminForm';
import {
  getValidationSchemaEditOrganizationUsers,
  OrganizationUserFormValues,
} from './UserFormSchema';

interface Props {
  show: boolean;
  onClose: () => void;
  userToEdit: OrganizationUser;
}

export const EditOrganizationUser: FC<Props> = ({
  show,
  onClose,
  userToEdit,
}) => {
  const texts = useTexts();

  const [editOrganizationUser, editOrganizationUserRequest] =
    useEditOrganizationUserMutation();

  const organizationUserRolesQuery = useOrganizationUserRolesQuery(
    userToEdit.userId,
  );

  const handleSubmit = async (
    formUser: OrganizationUserFormValues,
  ): Promise<void> => {
    await editOrganizationUser({
      userId: userToEdit.userId,
      givenName: formUser.givenName,
      familyName: formUser.familyName,
      roles: formUser.roles,
    });

    await organizationUserRolesQuery.refetch();
  };

  const mapInitialValues = (
    user: OrganizationUser,
    roles?: string[],
  ): OrganizationUserFormValues => ({
    email: user.email || '',
    givenName: user.givenName || '',
    familyName: user.familyName || '',
    roles: roles || [],
  });

  const formik = useFormik({
    initialValues: mapInitialValues(
      userToEdit,
      organizationUserRolesQuery.data,
    ),
    onSubmit: handleSubmit,
    validationSchema: getValidationSchemaEditOrganizationUsers(texts),
    enableReinitialize: true,
  });

  const handleOnClose = (): void => {
    // Reset state as this component is a singleton triggered by the `show` boolean.
    formik.resetForm();
    onClose();
  };

  useEffect(() => {
    if (editOrganizationUserRequest.isSuccess) {
      handleOnClose();
    }
  }, [editOrganizationUserRequest.isSuccess]);

  return (
    <FormikProvider value={formik}>
      <UserAdminForm
        show={show}
        onClose={handleOnClose}
        header={texts.adminModal.editUser}
        isSubmitting={formik.isSubmitting}
        submitFailed={editOrganizationUserRequest.isError}
        currentMode="editOrganization"
        touched={formik.touched}
        errors={formik.errors}
        dirty={formik.dirty}
        trackingModal="edit-organization-user"
      >
        <EditRolesList
          fetchUserRolesFailed={organizationUserRolesQuery.isError}
          rolesValidationError={
            formik.submitCount > 0 && (formik.errors.roles as string)
          }
        />
      </UserAdminForm>
    </FormikProvider>
  );
};

interface EditRolesListProps {
  fetchUserRolesFailed: boolean;
  rolesValidationError: string;
}
const EditRolesList: React.FC<EditRolesListProps> = ({
  fetchUserRolesFailed,
  rolesValidationError,
}: EditRolesListProps) => {
  const texts = useTexts();

  if (fetchUserRolesFailed) {
    return (
      <FormRow>
        <Alert variant="error" title={texts.admin.user.details.error} />
      </FormRow>
    );
  }

  return <RolesSelection error={rolesValidationError} />;
};
