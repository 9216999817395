import Alert from '@storeblocks/alert';
import React from 'react';

import { OrderType } from '@/api/order/models/OrderType';
import { useTexts } from '@/hooks/useTexts';

interface Props {
  orderType: OrderType.Subscription | OrderType.Redemption | OrderType.Switch;
}

export const UnitsInformationAlert: React.FC<Props> = ({ orderType }) => {
  const { orders } = useTexts();

  const textMap = {
    [OrderType.Subscription]: {
      title: orders.form.information.subscription.units.title,
      description: orders.form.information.subscription.units.message,
    },
    [OrderType.Redemption]: {
      title: orders.form.information.redemption.units.title,
      description: orders.form.information.redemption.units.message,
    },
    [OrderType.Switch]: {
      title: orders.form.information.switch.units.title,
      description: orders.form.information.switch.units.message,
    },
  };

  return (
    <Alert
      title={textMap[orderType].title}
      description={textMap[orderType].description}
      variant="info"
      fluid
      data-test="units-information-alert"
    />
  );
};
