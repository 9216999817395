import ChipGroup from '@storeblocks/chip/lib/group';
import React from 'react';

import { useTexts } from '@/hooks/useTexts';
import { AllocationType } from '@/pages/Presentation/types/AllocationType';
import { TrackingElement, useGetTrackId } from '@/tracking';

interface Props {
  checked: AllocationType;
  onChange(selectedAllocationType: AllocationType): void;
}

export const AllocationTypeChips: React.FC<Props> = ({ checked, onChange }) => {
  const texts = useTexts();

  const getTrackId = useGetTrackId();
  const trackId = getTrackId(
    'select-allocation-type',
    TrackingElement.FilterChip,
  );

  return (
    <ChipGroup
      id="allocation-type-chips"
      type="choice"
      options={[
        {
          id: 'main-distribution',
          label: texts.charts.filters.allocationTypes.mainDistribution,
          value: 'main-distribution',
          inputProps: {
            'data-trackid': trackId,
            'data-trackid-selected-filter': 'main-distribution',
            'data-trackid-source': 'asset-allocation-donut',
            'data-trackid-type': 'filter-chips',
          },
        },
        {
          id: 'product-group',
          label: texts.charts.filters.allocationTypes.productGroups,
          value: 'product-group',
          inputProps: {
            'data-trackid': trackId,
            'data-trackid-selected-filter': 'product-groups',
            'data-trackid-source': 'asset-allocation-donut',
            'data-trackid-type': 'filter-chips',
          },
        },
        {
          id: 'product',
          label: texts.charts.filters.allocationTypes.products,
          value: 'product',
          inputProps: {
            'data-trackid': trackId,
            'data-trackid-selected-filter': 'products',
            'data-trackid-source': 'asset-allocation-donut',
            'data-trackid-type': 'filter-chips',
          },
        },
      ]}
      checked={[checked]}
      onChange={(value) => onChange(value[0] as AllocationType)}
    />
  );
};
