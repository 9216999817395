import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { QueryState } from '@/api/components/QueryState';
import { useMultipleAssetPerformanceQuery } from '@/api/performanceData/multiPortfolioReturnsApi';
import { formatDateArgument } from '@/api/performanceData/performanceDataApi';
import { usePortfoliosQuery } from '@/api/portfolios';
import { isNotReady } from '@/api/utils/isNotReady';
import { DonutChart, DonutChartData } from '@/components/Charts';
import { H1, H2 } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { AllocationTypeChips } from '@/pages/Presentation/filters/AllocationTypeChips';

import { A4WidthForPrint } from '../components/A4WidthForPrint';
import { AvoidPageBreaksInside } from '../components/AvoidPageBreakInside';
import { HeaderContainer } from '../components/HeaderContainer';
import { StyledStorebrandLogo } from '../components/StyledStorebrandLogo';
import { CollapsibleFilterContainer } from '../filters/CollapsibleFilterContainer';
import { DateFilter } from '../filters/DateFilter';
import { Filter } from '../filters/Filter';
import { useDateFilterText } from '../filters/hooks/useDateFilterText';
import { useUnitFilterText } from '../filters/hooks/useUnitFilterText';
import { UnitFilter } from '../filters/UnitFilter';
import { getAssetAllocationTypeText } from '../filters/utils/getAssetAllocationTypeText';
import {
  useFilterForScreen,
  usePresentationContext,
} from '../PresentationContext';
import { AllocationType } from '../types/AllocationType';
import { aggregateDonutChartData } from '../utils/aggregateDonutChartData';
import { getAssetsByAllocationType } from '../utils/getAssetsByAllocationType';
import { getDonutChartDataByUnit } from '../utils/getDonutChartDataByUnit';

const screenKey = 'asset-allocation-donut';

interface PortfolioDonutData {
  portfolioGroup: string;
  data: DonutChartData;
}

interface Filters {
  selectedAllocationType: AllocationType;
}

const maxLengthDonutChartData = 12;

export const AssetAllocationDonutScreen: React.FC = () => {
  const texts = useTexts();

  const { globalFilter, setFilter } = usePresentationContext();

  const filters = useFilterForScreen<Filters>(screenKey, {
    selectedAllocationType: AllocationType.ProductGroup,
  });

  const [selectedAllocationType, setSelectedAllocationType] = useState(
    filters.selectedAllocationType,
  );

  useEffect(() => {
    return () => {
      setFilter<Filters>(screenKey, {
        selectedAllocationType,
      });
    };
  }, [selectedAllocationType]);

  const assetPerformanceQuery = useMultipleAssetPerformanceQuery({
    portfolioGroups: globalFilter.portfolioGroups.map(
      (portfolioGroup) => portfolioGroup.id,
    ),
    to: formatDateArgument(globalFilter.date),
  });

  const portfoliosQuery = usePortfoliosQuery();

  const donutData = useMemo<Array<PortfolioDonutData>>(() => {
    if (!assetPerformanceQuery.data?.assetPerformances) {
      return [];
    }

    return assetPerformanceQuery.data.assetPerformances.reduce(
      (donutChartsData, assetPerformance) => {
        const root = assetPerformance.assets[0];
        const assets = getAssetsByAllocationType(root, selectedAllocationType);

        let donutChartData = getDonutChartDataByUnit(assets, globalFilter.unit);

        if (donutChartData.length > maxLengthDonutChartData) {
          donutChartData = aggregateDonutChartData(
            donutChartData,
            maxLengthDonutChartData,
            texts.assetAllocation.chart.otherLegend,
          );
        }

        return [
          ...donutChartsData,
          {
            portfolioGroup: assetPerformance.portfolioGroup,
            data: donutChartData,
          },
        ];
      },
      [],
    );
  }, [
    assetPerformanceQuery.data?.assetPerformances,
    selectedAllocationType,
    globalFilter?.unit,
  ]);

  const dateFilterText = useDateFilterText();
  const unitFilterText = useUnitFilterText();

  const selectedFilterTexts = [
    dateFilterText,
    unitFilterText,
    getAssetAllocationTypeText(selectedAllocationType, texts),
  ];

  const getPortfolioHeader = (portfolioId: string): string => {
    const portfolio = portfoliosQuery.data.portfolioGroupById.get(portfolioId);

    return `${portfolio.id} - ${portfolio.name}`;
  };

  return (
    <A4WidthForPrint>
      <WithGap data-test={`${screenKey}-screen`}>
        <HeaderContainer>
          <H1>{texts.pages.presentation.screens.assetAllocationDonut.title}</H1>
          <StyledStorebrandLogo />
        </HeaderContainer>
        <CollapsibleFilterContainer selectedFilterTexts={selectedFilterTexts}>
          <WithGap>
            <WithGap direction="row">
              <DateFilter />
              <UnitFilter />
              <Filter
                label={texts.pages.presentation.filters.allocationType.label}
              >
                <AllocationTypeChips
                  checked={selectedAllocationType}
                  onChange={setSelectedAllocationType}
                />
              </Filter>
            </WithGap>
          </WithGap>
        </CollapsibleFilterContainer>

        {isNotReady(assetPerformanceQuery) ? (
          <QueryState
            query={assetPerformanceQuery}
            customTexts={{
              noData: {
                title: texts.pages.presentation.filters.noData.title,
                description: texts.pages.presentation.filters.noData.message,
              },
            }}
          />
        ) : (
          <WithGap gap="48">
            {donutData.map((donut) => (
              <AvoidPageBreaksInside key={donut.portfolioGroup}>
                <H2>{getPortfolioHeader(donut.portfolioGroup)}</H2>
                <Wrapper>
                  <DonutChart
                    unit={globalFilter.unit}
                    data={donut.data}
                    size={200}
                  />
                </Wrapper>
              </AvoidPageBreaksInside>
            ))}
          </WithGap>
        )}
      </WithGap>
    </A4WidthForPrint>
  );
};

const Wrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;
