import Button from '@storeblocks/button';
import React, { FC, useRef } from 'react';

import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';

interface Props {
  onFileSelect: (file: File) => void;
}

export const FileSelector: FC<Props> = ({ onFileSelect }: Props) => {
  const fileInput = useRef(null);
  const texts = useTexts();
  const getTrackId = useGetTrackId();

  return (
    <>
      <Button
        type="button"
        disabled={!fileInput}
        onClick={() => fileInput?.current?.click()}
        mr="8px"
        data-trackid={getTrackId(
          'choose-file:upload-document-modal',
          TrackingElement.Button,
        )}
      >
        {texts.uploadFileModal.chooseFile}
      </Button>

      <input
        onChange={(event) => onFileSelect(event.target.files[0])}
        type="file"
        style={{ display: 'none' }}
        ref={fileInput}
      />
    </>
  );
};
