import DatePicker from '@storeblocks/datepicker';
import { useField, useFormikContext } from 'formik';
import React from 'react';

import { useTexts } from '@/hooks/useTexts';
import { useUserDateFnsLocale, useUserLocale } from '@/i18n/hooks';
import { nameof } from '@/util/nameof';

import { WithdrawalOrderFormValues } from '../../types/WithdrawalOrderFormValues';
import { expectedTransferDate } from '../../utils/expectedTransferDate';

export const ExpectedTransferDateSelector: React.FC = () => {
  const texts = useTexts();
  const locale = useUserLocale();
  const dateFnsLocale = useUserDateFnsLocale();

  const [field, meta, helpers] = useField<Date>(
    nameof<WithdrawalOrderFormValues>('expectedTransferDate'),
  );

  const { submitCount } = useFormikContext<WithdrawalOrderFormValues>();

  return (
    <DatePicker
      {...field}
      id="expected-transfer-date"
      locale={locale}
      format={expectedTransferDate.dateFormat(dateFnsLocale)}
      min={expectedTransferDate.minimumDate()}
      label={texts.orders.form.withdrawal.expectedTransferDate.title}
      hint={texts.orders.form.withdrawal.expectedTransferDate.description}
      inputProps={{
        autoComplete: 'off',
        placeholder: expectedTransferDate.dateFormat(dateFnsLocale),
      }}
      // Must also use submit count otherwise the validation error
      // is displayed when the user clicks on the calendar icon.
      error={submitCount > 0 && meta.touched && meta.error}
      // Override the 'onChange' and 'onBlur' as per
      // the Storeblocks documentation.
      onChange={(_, selectedDate) => {
        helpers.setValue(selectedDate);
      }}
      onBlur={() => {
        helpers.setTouched(true);
      }}
      fluid
    />
  );
};
