import React from 'react';
import styled from 'styled-components';

import { useFundEsgQuery } from '@/api/esg/esgApi';
import { Center } from '@/components/Center';
import { Column, Row } from '@/components/Grid';
import { Helper } from '@/components/Helper';
import { H2, Paragraph } from '@/components/Typography';
import { KeyStatistics3 } from '@/components/Typography/KeyStatistic3';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';

import { LargeText } from '../components/LargeText';
import { ScienceBasedTargetsChart } from '../components/ScienceBasedTargetsChart';

interface Props {
  isin: string;
}

export const EsgScienceBasedTargets: React.FC<Props> = ({ isin }) => {
  const texts = useTexts();
  const esgFundQuery = useFundEsgQuery({ isin });

  return (
    <>
      <Center vertical>
        <H2>{texts.pages.fundEsg.scienceBasedTargets.title}</H2>

        {texts.pages.fundEsg.scienceBasedTargets.helpDescription && (
          <Helper
            id="fund-esg-science-based-targets"
            description={
              texts.pages.fundEsg.scienceBasedTargets.helpDescription
            }
          />
        )}
      </Center>

      <Row>
        <Column $span={5}>
          <WithGap gap="16">
            <LargeText>
              {esgFundQuery.data.scienceBasedTargetSet.kpi.value} %
            </LargeText>

            <KeyStatistics3>
              {texts.pages.fundEsg.scienceBasedTargets.subtitle}
            </KeyStatistics3>

            <Paragraph>
              {texts.pages.fundEsg.scienceBasedTargets.description}
            </Paragraph>
          </WithGap>
        </Column>

        <Column $span={1}>{/* Empty column used for spacing. */}</Column>

        <Column $span={6}>
          <CenterStyled vertical>
            <ScienceBasedTargetsChart
              fundPercent={esgFundQuery.data.scienceBasedTargetSet.kpi.value}
              benchmarkPercent={
                esgFundQuery.data.scienceBasedTargetSet.benchmark?.value
              }
            />
          </CenterStyled>
        </Column>
      </Row>
    </>
  );
};

const CenterStyled = styled(Center)`
  height: 100%;
`;
