import { Illustration } from '@storeblocks/icon';
import React from 'react';
import styled from 'styled-components';

import { useFmsTextsQuery } from '@/api/fms/fmsApi';
import { useUserSettingsQuery } from '@/api/userSettings/userSettingsApi';
import { appConfig } from '@/constants/config';
import { Language } from '@/i18n/Language';
import { getSessionId } from '@/logging/sessionId';

import { Divider } from './Divider';
import { Column, Row } from './Grid';
import { PageHeading } from './PageHeading';
import { Ingress } from './Typography';
import { WithGap } from './WithGap';

export const UnexpectedError: React.FC = () => {
  const userSettingsQuery = useUserSettingsQuery();

  const fmsTextsQuery = useFmsTextsQuery(
    userSettingsQuery.data?.language ?? Language.English,
  );

  const texts = fmsTextsQuery.data;

  // Use hardcoded texts as fallback in case there is issues with FMS.
  const header =
    (texts && texts.error.header.general) || 'Something went wrong';

  const descriptionPart1 =
    (texts && texts.error.description.general.part1) ||
    'Please try again later, or contact customer service on email';

  const descriptionPart2 =
    (texts && texts.error.description.general.part2) ||
    'if the issue persists.';

  const siosEmail = (texts && texts.error.siosEmail) || 'sios@storebrand.no';

  const errorCode = (texts && texts.error.errorCode.title) || 'Error code:';

  let sessionId = '';
  try {
    sessionId = getSessionId();
  } catch (e) {
    // Swallow the error since we don't want to throw
    // errors on the error page.
  }

  return (
    <Container data-test="error-page">
      <Center>
        <Page>
          <PageHeading header={header} />
          <Divider margin="8" />
          <StyledRow>
            <Column $span={6}>
              <WithGap>
                <Ingress>
                  {descriptionPart1}{' '}
                  <a href={`mailto:${siosEmail}`}>{siosEmail}</a>{' '}
                  {descriptionPart2}
                </Ingress>
                <Monospace>{`${errorCode} ${sessionId}`}</Monospace>
              </WithGap>
            </Column>
            <StyledColumn $span={6}>
              <Illustration name="something-went-wrong" height="500" />
            </StyledColumn>
          </StyledRow>
        </Page>
      </Center>
    </Container>
  );
};

const Container = styled.div`
  border-top: 3px solid var(--color-brand);
`;

const Center = styled.div`
  max-width: ${appConfig.maxPageWidth};
  margin: 0 auto;
`;

const Page = styled.div`
  margin-top: 72px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 24px;
`;

const StyledRow = styled(Row)`
  margin-top: 8px;
`;

const StyledColumn = styled(Column)`
  display: flex;
  justify-content: center;
`;

const Monospace = styled.span`
  // Make the text easier to read in regards to lowercase 'L', 'I' and '1'.
  font-family: 'monospace';
`;
