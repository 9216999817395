import Button from '@storeblocks/button';
import React, { FC } from 'react';

import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { useOrderPageContext } from '@/pages/Order/OrderPageContext';
import { TrackingElement, useGetTrackId } from '@/tracking';

export const NavigationButtons: FC = () => {
  const texts = useTexts();
  const getTrackId = useGetTrackId();

  const { cancel } = useOrderPageContext();

  return (
    <WithGap gap="8" direction="row">
      <Button
        type="submit"
        data-trackid={getTrackId('go-to-next-step', TrackingElement.Button)}
      >
        {texts.orders.form.steps.buttons.next}
      </Button>
      <Button
        type="button"
        iconLeft="close"
        variant="text"
        onClick={cancel}
        data-trackid={getTrackId('cancel', TrackingElement.Button)}
      >
        {texts.orders.form.steps.buttons.cancel}
      </Button>
    </WithGap>
  );
};
