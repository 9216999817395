import Button from '@storeblocks/button';
import React from 'react';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { InternalLink } from '@/components/InternalLink';
import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { WithGap } from './WithGap';

export const OrderPagesButtons: React.FC = () => {
  const texts = useTexts();
  const organizationCmId = useOrganizationCmId();

  const getTrackId = useGetTrackId();

  return (
    <WithGap gap="8" direction="row">
      <Button
        variant="outlined"
        type="button"
        to={`/${organizationCmId}/trading/subscription`}
        as={InternalLink}
        data-test="order-page-buttons"
        data-trackid={getTrackId('go-to-subscription', TrackingElement.Link)}
      >
        {texts.orders.form.buttons.buy}
      </Button>

      <Button
        variant="outlined"
        type="button"
        to={`/${organizationCmId}/trading/redemption`}
        as={InternalLink}
        data-test="order-page-buttons"
        data-trackid={getTrackId('go-to-redemption', TrackingElement.Link)}
      >
        {texts.orders.form.buttons.sell}
      </Button>

      <Button
        variant="outlined"
        type="button"
        to={`/${organizationCmId}/trading/switch`}
        as={InternalLink}
        data-test="order-page-buttons"
        data-trackid={getTrackId('go-to-switch', TrackingElement.Link)}
      >
        {texts.orders.form.buttons.switch}
      </Button>
    </WithGap>
  );
};
