import Button from '@storeblocks/button';
import React, { FC } from 'react';
import styled from 'styled-components';

import { EsgCategory } from '@/api/esg/models/EsgCategory';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { Center } from '@/components/Center';
import { Gauge } from '@/components/Charts';
import { Column, Row } from '@/components/Grid';
import { InternalLink } from '@/components/InternalLink';
import { Paragraph } from '@/components/Typography';
import { Widget, WidgetActions, WidgetHeader } from '@/components/Widget';
import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { EsgCoverageText } from '../../components/EsgCoverageText';
import { EsgInsufficientDataAlert } from '../../components/EsgInsufficientDataAlert';

interface Props {
  euTaxonomyAlignment: EsgCategory | undefined;
  euTaxonomyEligibility: EsgCategory | undefined;
}

export const EsgTaxonomyWidget: FC<Props> = ({
  euTaxonomyAlignment,
  euTaxonomyEligibility,
}) => {
  const texts = useTexts();
  const organizationCmId = useOrganizationCmId();
  const getTrackId = useGetTrackId();

  if (!euTaxonomyAlignment || !euTaxonomyEligibility) {
    return <EsgInsufficientDataAlert />;
  }

  return (
    <Widget data-test="esg-taxonomy-widget">
      <WidgetHeader
        title={texts.pages.esg.taxonomy.title}
        helperProps={{
          id: 'esg-taxonomy',
          title: texts.pages.esg.taxonomy.helpTitle,
          description: texts.pages.esg.taxonomy.helpDescription,
        }}
      />
      <Row>
        <Column $span={6}>
          <TaxonomyGaugeContainer>
            <Center horizontal vertical>
              <Gauge
                description={texts.pages.esg.taxonomy.eligibilityTitle}
                percent={euTaxonomyEligibility.kpi.value}
              />
            </Center>
            <div>
              <DescriptionText>
                {texts.pages.esg.taxonomy.eligibilityDescription}
              </DescriptionText>
              <EsgCoverageText
                percentage={euTaxonomyEligibility.kpi.coverageInPercent}
              />
            </div>
          </TaxonomyGaugeContainer>
        </Column>

        <Column $span={6}>
          <TaxonomyGaugeContainer>
            <Center horizontal vertical>
              <Gauge
                description={texts.pages.esg.taxonomy.alignmentTitle}
                percent={euTaxonomyAlignment.kpi?.value}
              />
            </Center>

            <div>
              <DescriptionText>
                {texts.pages.esg.taxonomy.alignmentDescription}
              </DescriptionText>
              <EsgCoverageText
                percentage={euTaxonomyAlignment.kpi.coverageInPercent}
              />
            </div>
          </TaxonomyGaugeContainer>
        </Column>
      </Row>
      <WidgetActions>
        <Button
          variant="text"
          as={InternalLink}
          to={`/${organizationCmId}/sustainability/esg/taxonomy`}
          iconRight="arrow-right"
          data-trackid={getTrackId('esg-taxonomy', TrackingElement.Button)}
        >
          {texts.pages.esg.taxonomy.moreLink}
        </Button>
      </WidgetActions>
    </Widget>
  );
};

const TaxonomyGaugeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DescriptionText = styled(Paragraph)`
  margin-bottom: 0;
`;
