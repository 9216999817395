import Button from '@storeblocks/button';
import InlineAlert from '@storeblocks/inline-alert';
import React from 'react';
import styled from 'styled-components';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { Column, Row } from '@/components/Grid';
import { InternalLink } from '@/components/InternalLink';
import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';

/**
 * Display an announcement.
 *
 * The announcement is only displayed if the FMS key "pages.dashboard.announcementInfo.enabled"
 * is set to `true`. Otherwise nothing is rendered.
 *
 * FMS keys associated with this component:
 *
 * - pages.dashboard.announcementInfo.enabled
 *    This key is a JSON object with a single property "isEnabled".
 *    Set this to `true` to display the announcement.
 *
 * - pages.dashboard.announcementInfo.title
 *
 * - pages.dashboard.announcementInfo.description
 *
 * - pages.dashboard.announcementInfo.link.text
 *
 * - pages.dashboard.announcementInfo.link.url
 *    The link will only be displayed if this key is set.
 *    The component will add the current organization CMID to the URL for it to be routed correctly.
 *    In FMS set the URL as e.g. "/reports/funds", this will be converted to "/{organizationCmId}/reports/funds".
 *    If the URL starts with "http" it will be treated as an external link.
 */
export const AnnouncementInfo: React.FC = () => {
  const texts = useTexts();
  const getTrackId = useGetTrackId();
  const organizationCmId = useOrganizationCmId();

  const isEnabled =
    // The actual value in FMS is a JSON object, but our typings in Connect
    // only handles values from FMS as strings. So we need to cast it to
    // unknown and then to the correct type.
    (
      texts.pages.dashboard.announcementInfo.enabled as unknown as {
        isEnabled: boolean;
      }
    ).isEnabled;

  if (!isEnabled) {
    // Don't display anything if the announcement is disabled.
    return null;
  }

  let linkUrl = undefined;

  const hasLink = texts.pages.dashboard.announcementInfo.link.url;

  const isExternalLink =
    hasLink &&
    texts.pages.dashboard.announcementInfo.link.url.startsWith('http');

  const isInternalLink = hasLink && !isExternalLink;

  if (isExternalLink) {
    linkUrl = texts.pages.dashboard.announcementInfo.link.url;
  }

  if (isInternalLink) {
    // :: Construct the link URL.
    // Add the organization CMID to the link URL for it to be routed correctly.
    linkUrl = `/${organizationCmId}`;

    // Determine if the link URL has a leading slash.
    // If not, we need to add it to the URL.
    // We can then be less strict with what the user enters in FMS.
    const hasLeadingSlash =
      texts.pages.dashboard.announcementInfo.link.url.startsWith('/');
    linkUrl = linkUrl + (hasLeadingSlash ? '' : '/');

    // Add the actual link URL from FMS.
    linkUrl = linkUrl + texts.pages.dashboard.announcementInfo.link.url;
  }

  return (
    <Row>
      <Column $span={8}>
        <Container data-test="dashboard-announcement">
          <InlineAlert
            title={texts.pages.dashboard.announcementInfo.title}
            variant="info"
          />

          <Description>
            {texts.pages.dashboard.announcementInfo.description}
          </Description>

          {hasLink && (
            <ButtonContainer>
              <Button
                as={isInternalLink ? InternalLink : 'a'}
                to={isInternalLink ? linkUrl : undefined}
                href={isExternalLink ? linkUrl : undefined}
                target={isExternalLink ? '_blank' : undefined}
                variant="text"
                iconRight="arrow-right"
                data-test="announcement-info-link"
                data-trackid={getTrackId(
                  'announcement-info',
                  TrackingElement.Link,
                )}
                data-trackid-source="announcement-info"
              >
                {texts.pages.dashboard.announcementInfo.link.text}
              </Button>
            </ButtonContainer>
          )}
        </Container>
      </Column>
    </Row>
  );
};

const Container = styled.div`
  border: 3px solid var(--color-info-accent);
  padding: 12px;
  padding-bottom: 26px;
`;

const Description = styled.div`
  padding-left: 64px;
  line-height: 24px;
`;

const ButtonContainer = styled.div`
  padding-top: 12px;
  padding-left: 40px;
`;
