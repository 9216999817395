import Button from '@storeblocks/button';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { QueryState } from '@/api/components/QueryState';
import { useMultiplePortfolioPerformanceQuery } from '@/api/performanceData/multiPortfolioReturnsApi';
import { formatDateArgument } from '@/api/performanceData/performanceDataApi';
import { usePortfoliosQuery } from '@/api/portfolios';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { isNotReady } from '@/api/utils/isNotReady';
import { UnitChips } from '@/components/Charts/components/UnitChips';
import { DateFilterType } from '@/components/DateFilter/types/DateFilterType';
import { getDateFilter } from '@/components/DateFilter/utils/getDateFilter';
import { FixedHeight } from '@/components/FixedHeight';
import { Helper } from '@/components/Helper';
import { InternalLink } from '@/components/InternalLink';
import { PortfolioReturnsGraph } from '@/components/PortfolioReturnsGraph/PortfolioReturnsGraph';
import { H2 } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';
import { Unit } from '@/types/Unit';

import { DateFilterChips } from './DateFilterChips';
import { DateIntervalText } from './DateIntervalText';

export const PortfolioReturnsWidget: FC = () => {
  const texts = useTexts();
  const getTrackId = useGetTrackId();
  const organizationCmId = useOrganizationCmId();

  const [selectedUnit, setSelectedUnit] = useState(Unit.Percent);
  const [selectedDate, setSelectedDate] = useState(DateFilterType.ThisYear);

  const dateFilter = getDateFilter(selectedDate);

  const portfoliosQuery = usePortfoliosQuery();

  const portfolioGroups = portfoliosQuery.data?.portfolioGroups.map(
    (portfolioGroup) => portfolioGroup.id,
  );

  const multiplePortfolioReturnsQuery = useMultiplePortfolioPerformanceQuery(
    {
      portfolioGroups,
      from: formatDateArgument(dateFilter.from),
      to: formatDateArgument(dateFilter.to),
    },
    { skip: !portfoliosQuery.isSuccess },
  );

  return (
    <WithGap data-test="portfolio-returns-all-portfolios">
      <WithGap gap="8" direction="row">
        <H2>{texts.widgets.portfolioReturns.title}</H2>
        <Helper
          id={'portfolio-returns'}
          title={texts.widgets.portfolioReturns.help.title}
          description={texts.widgets.portfolioReturns.help.description}
        />
      </WithGap>

      <>
        <Chips>
          <DateFilterChips
            id="portfolio-returns"
            checked={selectedDate}
            onChange={setSelectedDate}
          />

          <UnitChips
            id="portfolio-returns"
            checked={selectedUnit}
            onChange={setSelectedUnit}
          />
        </Chips>

        <FixedHeight
          height={
            /* Graph */ 360 + /* <DateIntervalText /> */ 24 + /* Gap */ 24
          }
        >
          {isNotReady([portfoliosQuery, multiplePortfolioReturnsQuery]) ? (
            <QueryState
              query={[portfoliosQuery, multiplePortfolioReturnsQuery]}
            />
          ) : (
            <WithGap>
              <DateIntervalText
                fromDate={multiplePortfolioReturnsQuery.data.firstPointDate}
                toDate={multiplePortfolioReturnsQuery.data.lastPointDate}
              />
              <PortfolioReturnsGraph
                data={multiplePortfolioReturnsQuery.data.returns}
                unit={selectedUnit}
                height={360}
              />
            </WithGap>
          )}
        </FixedHeight>
        <div>
          <Button
            variant="text"
            as={InternalLink}
            to={`/${organizationCmId}/performance`}
            iconRight="arrow-right"
            data-trackid={getTrackId(
              'go-to-performance-dashboard',
              TrackingElement.Link,
            )}
          >
            {texts.widgets.portfolioReturns.goToPortfolioDashboard}
          </Button>
        </div>
      </>
    </WithGap>
  );
};

const Chips = styled.div`
  display: flex;
  justify-content: space-between;
`;
