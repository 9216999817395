import { OrderTypeDto } from '../dtos/OrderTypeDto';
import { OrderType } from '../models/OrderType';

export const mapOrderTypeDtoToEnum = (orderType: OrderTypeDto): OrderType => {
  switch (orderType) {
    case OrderTypeDto.BuyFund: {
      return OrderType.Subscription;
    }

    case OrderTypeDto.SellFund: {
      return OrderType.Redemption;
    }

    case OrderTypeDto.Switch: {
      return OrderType.Switch;
    }

    case OrderTypeDto.DepositDiscretionary: {
      return OrderType.Deposit;
    }

    case OrderTypeDto.WithdrawDiscretionary: {
      return OrderType.Withdrawal;
    }

    case OrderTypeDto.TransferDiscretionary: {
      return OrderType.Transfer;
    }

    default:
      throw new Error(`Could not map order type [${orderType}].`);
  }
};

export const mapFundOrderTypeDtoToEnum = (
  orderType: OrderTypeDto,
): OrderType.Subscription | OrderType.Redemption | OrderType.Switch => {
  switch (orderType) {
    case OrderTypeDto.BuyFund: {
      return OrderType.Subscription;
    }

    case OrderTypeDto.SellFund: {
      return OrderType.Redemption;
    }

    case OrderTypeDto.Switch: {
      return OrderType.Switch;
    }

    default:
      throw new Error(
        `Could not map to fund order type from order type [${orderType}].`,
      );
  }
};
