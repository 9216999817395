import Alert from '@storeblocks/alert';
import { useField } from 'formik';
import React, { FC, useMemo } from 'react';

import {
  useIHubDirectoriesForOrganizationQuery,
  useIHubDirectoriesQuery,
} from '@/api/reports/reportsApi';
import { AddSingleItemTable, OnChange } from '@/components/AddSingleItemTable';
import { Item } from '@/components/AddSingleItemTable/Item';
import { FormRow } from '@/components/Form/FormRow';
import { Show } from '@/components/Show';
import { useTexts } from '@/hooks/useTexts';

interface Props {
  cmId: string;
}

export const DirectorySelector: FC<Props> = ({ cmId }) => {
  const texts = useTexts();
  const helpers = useField<string[]>('directories')[2];

  const iHubDirectoriesQuery = useIHubDirectoriesQuery();

  const iHubDirectoriesForOrganizationQuery =
    useIHubDirectoriesForOrganizationQuery(cmId);

  const selectListItems = useMemo<Array<Item>>(() => {
    return iHubDirectoriesQuery.data?.map((dir) => {
      return { id: dir.id, label: dir.name };
    });
  }, [iHubDirectoriesQuery.data]);

  const existingItems = useMemo<Array<Item>>(() => {
    return (
      iHubDirectoriesForOrganizationQuery.data?.map((dir) => {
        return { id: dir.id, label: dir.name };
      }) ?? []
    );
  }, [iHubDirectoriesForOrganizationQuery.data]);

  const handleChange = (items: OnChange): void => {
    helpers.setValue(items.all.map((item) => item.id));
  };

  return (
    <>
      <FormRow>
        <AddSingleItemTable
          title="Mapper fra iHub"
          placeholderText="Velg mappe"
          addNewItemButtonText="Legg til mappe"
          existingItems={existingItems}
          selectListItems={selectListItems}
          onChange={handleChange}
        />
      </FormRow>
      <Show
        when={
          iHubDirectoriesQuery.isError ||
          iHubDirectoriesForOrganizationQuery.isError
        }
      >
        <Alert
          variant="error"
          title={texts.admin.organizations.folders.loadError}
        />
      </Show>
    </>
  );
};
