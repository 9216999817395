import Button from '@storeblocks/button';
import React, { FC } from 'react';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { InternalLink } from '@/components/InternalLink';
import { NewsArticles } from '@/components/NewsArticles';
import { H2 } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';

export const NewsArticlesWidget: FC = () => {
  const texts = useTexts();
  const getTrackId = useGetTrackId();
  const organizationCmId = useOrganizationCmId();

  return (
    <WithGap data-test="news-articles-widget">
      <H2>{texts.news.title}</H2>
      <NewsArticles limit={3} />
      <div>
        <Button
          variant="text"
          as={InternalLink}
          to={`/${organizationCmId}/news`}
          iconRight="arrow-right"
          data-trackid={getTrackId('go-to-news-page', TrackingElement.Link)}
          data-test="more-news-link"
        >
          {texts.dashboard.widgets.news.moreNews}
        </Button>
      </div>
    </WithGap>
  );
};
