import ChipGroup from '@storeblocks/chip/lib/group';
import React from 'react';

import { HoldingsCalculationType } from '@/api/portfolioGroupHoldings/models/HoldingsCalculationType';
import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';

interface Props {
  checked: HoldingsCalculationType;
  onChange(calculationType: HoldingsCalculationType): void;
}

export const HoldingsCalculationTypeChips: React.FC<Props> = ({
  checked,
  onChange,
}) => {
  const texts = useTexts();

  const getTrackId = useGetTrackId();
  const trackId = getTrackId(
    'select-holdings-calculation-type',
    TrackingElement.FilterChip,
  );

  return (
    <ChipGroup
      id="holdings-calculation-type-chips"
      type="choice"
      options={[
        {
          id: HoldingsCalculationType.EOD,
          label: texts.pages.presentation.filters.holdingsCalculationType.eod,
          value: HoldingsCalculationType.EOD,
          inputProps: {
            'data-trackid': trackId,
            'data-trackid-selected-filter': HoldingsCalculationType.EOD,
            'data-trackid-source': 'portfolio-holdings-screen',
            'data-trackid-type': 'filter-chips',
          },
        },
        {
          id: HoldingsCalculationType.EOM,
          label: texts.pages.presentation.filters.holdingsCalculationType.eom,
          value: HoldingsCalculationType.EOM,
          inputProps: {
            'data-trackid': trackId,
            'data-trackid-selected-filter': HoldingsCalculationType.EOM,
            'data-trackid-source': 'portfolio-holdings-screen',
            'data-trackid-type': 'filter-chips',
          },
        },
      ]}
      checked={[checked]}
      onChange={(value) => onChange(value[0] as HoldingsCalculationType)}
    />
  );
};
