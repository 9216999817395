import Button from '@storeblocks/button';
import React from 'react';
import styled from 'styled-components';

import { appConfig } from '@/constants/config';
import { useTexts } from '@/hooks/useTexts';

export const FooterBanner: React.FC = () => {
  const texts = useTexts();
  return (
    <Banner>
      <Center>
        <Button
          variant="text"
          iconRight="arrow-up"
          onClick={() => window.scrollTo(0, 0)}
        >
          {texts.footer.scrollToTop}
        </Button>
      </Center>
    </Banner>
  );
};

const Banner = styled.div`
  background-color: var(--color-surface);
  padding: 4px 0;
`;

const Center = styled.div`
  max-width: ${appConfig.maxPageWidth};
  margin: 0 auto;
`;
