import React, { FC } from 'react';
import Markdown, { Components } from 'react-markdown';
import styled from 'styled-components';

import { H3, H4, TextShort } from '@/components/Typography';
import { useTexts } from '@/hooks/useTexts';

export const EsgExclusionsList: FC = () => {
  const texts = useTexts();

  return (
    <Markdown components={components}>
      {texts.pages.esg.exclusions.exclusionPolicy.description}
    </Markdown>
  );
};

/**
 * Override the mapping of markdown elements to HTML components.
 *
 * We want to use our own React components instead of the
 * default HTML elements.
 *
 * I.e. the markdown library maps the `###` markdown level 3 heading element to
 * the HTML `h3` element. However, if we want to use our
 * own components we map it here.
 */
const components: Partial<Components> = {
  h3: ({ children }) => <StyledH3>{children}</StyledH3>,
  h4: ({ children }) => <StyledH4>{children}</StyledH4>,
  p: ({ children }) => <StyledTextShort>{children}</StyledTextShort>,
  ul: ({ children }) => <List>{children}</List>,
  li: ({ children }) => <ListItem>{children}</ListItem>,
};

const StyledH3 = styled(H3)`
  margin-bottom: 8px;
`;

const StyledH4 = styled(H4)`
  margin-top: 24px;
  margin-bottom: 16px;
`;

const StyledTextShort = styled(TextShort)`
  display: block;
  margin-bottom: 8px;
`;

const List = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 16px;
  margin-bottom: 24px;
`;

const ListItem = styled.li`
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color-border);
`;
