import React, { useMemo } from 'react';

import { PortfolioReturns } from '@/api/performanceData/models/PortfolioReturns';
import {
  LineChart,
  LineChartData,
  LineChartDataSeries,
} from '@/components/Charts/LineChart';
import { useTexts } from '@/hooks/useTexts';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { Unit } from '@/types/Unit';

interface Props {
  data: PortfolioReturns[];
  unit: Unit;
  height?: number;
  trackId?: string;
}

export const PortfolioReturnsGraph: React.FC<Props> = ({
  data,
  unit,
  height,
  trackId,
}) => {
  const texts = useTexts();

  const lineChartData = useMemo<LineChartData>(() => {
    return data.reduce((series, portfolioReturns) => {
      if (!portfolioReturns.forUnit) {
        return series;
      }

      const chartData: LineChartDataSeries = {
        name: fmsWithTemplate(texts.widgets.portfolio.graph.legend.portfolio, {
          portfolio: portfolioReturns.portfolioGroupShortName,
        }),
        data: portfolioReturns.forUnit[unit],
      };

      return [...series, chartData];
    }, [] as LineChartDataSeries[]);
  }, [data, unit]);

  return (
    <LineChart
      data={lineChartData}
      unit={unit}
      height={height}
      trackId={trackId}
    />
  );
};
