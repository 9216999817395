import Accordion, { Row } from '@storeblocks/accordion';
import React from 'react';
import styled from 'styled-components';

import { useFundListQuery } from '@/api/funds/fundsApi';
import { Order } from '@/api/orderList/models/Order';
import { H3, Paragraph } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { useUserLocale } from '@/i18n/hooks';
import { formatNumber } from '@/util/formatNumber';

import { getPaymentInfoForOrderLines } from '../utils/getPaymentInfoForOrderLines';
import { ForeignPayment } from './ForeignPayment';

interface Props {
  order: Order;
}

export const PaymentInformation: React.FC<Props> = ({ order }) => {
  const texts = useTexts();
  const locale = useUserLocale();
  const { data: fundList } = useFundListQuery();

  const payment = getPaymentInfoForOrderLines(order.orderLines, fundList);

  return (
    <Surface>
      <WithGap gap="8">
        <H3>{texts.pages.orderList.settlement.title}</H3>

        <Paragraph>
          {texts.pages.orderList.settlement.settlementAccount}
        </Paragraph>

        <ul>
          {payment.map((payment) => (
            <li
              key={`${payment.paymentInfo.bankAccountNumber}-${payment.paymentInfo.currency}`}
            >
              <Paragraph>
                {fmsWithTemplate(
                  texts.pages.orderList.settlement.transferAmount,
                  {
                    amount: formatNumber(
                      payment.totalAmount,
                      locale,
                      payment.paymentInfo.currency,
                    ),
                    accountNumber: payment.paymentInfo.bankAccountNumber,
                  },
                )}
              </Paragraph>
            </li>
          ))}
        </ul>

        <Paragraph>
          {texts.pages.orderList.settlement.markWithOrganizationName}
        </Paragraph>
      </WithGap>

      <StyledAccordion headingsAriaLevel={4}>
        <Row
          id="foreign-payment"
          header={texts.pages.orderList.settlement.foreignPayment.title}
        >
          <WithGap>
            {payment.map((payment) => (
              <ForeignPayment
                key={`${payment.paymentInfo.bankAccountNumber}-${payment.paymentInfo.currency}`}
                amount={payment.totalAmount}
                paymentInfo={payment.paymentInfo}
              />
            ))}
          </WithGap>
        </Row>
      </StyledAccordion>
    </Surface>
  );
};

const Surface = styled.div`
  padding: 16px;
  background-color: var(--color-surface);
`;

const StyledAccordion = styled(Accordion)`
  margin-top: 24px;

  button {
    background-color: transparent;
  }
`;
