import React from 'react';

import { OrderPagesButtons } from '@/components/OrderPagesButtons';
import { H2, TextShort, TextShortBold } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';

import { Line } from './Line';

export const NoActiveOrders: React.FC = () => {
  const texts = useTexts();

  return (
    <WithGap gap="24" data-test="no-active-orders">
      <H2>{texts.pages.orderList.noActiveOrders.title}</H2>

      <TextShort>{texts.pages.orderList.noActiveOrders.description}</TextShort>

      <WithGap gap="8">
        <TextShortBold>
          {texts.pages.orderList.noActiveOrders.addNew}
        </TextShortBold>
        <OrderPagesButtons />
      </WithGap>

      <Line />
    </WithGap>
  );
};
