import Breadcrumbs from '@storeblocks/breadcrumbs';
import React, { FC } from 'react';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { FundAvailabilityAlert } from '@/components/Alert/FundAvailabilityAlert';
import { AnnouncementWarning } from '@/components/AnnouncementWarning';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { Column, Row } from '@/components/Grid';
import { InternalLink } from '@/components/InternalLink';
import { MainContent } from '@/components/MainContent';
import { NewsArticles } from '@/components/NewsArticles';
import { NewsLinks } from '@/components/NewsLinks';
import { PageHeading } from '@/components/PageHeading';
import { RenderHtml } from '@/components/RenderHtml';
import { AssetAllocationBannerWidget } from '@/components/Widgets/AssetAllocation/AssetAllocationBanner';
import { FundsSustainabilityBannerWidget } from '@/components/Widgets/FundsSustainability/FundsSustainabilityBanner';
import { useTexts } from '@/hooks/useTexts';

export const NewsPage: FC = () => {
  const organizationCmId = useOrganizationCmId();
  const texts = useTexts();

  const rootPath = organizationCmId ? `/${organizationCmId}` : '/';

  return (
    <CenteredPageContent data-test="news-page">
      <AnnouncementWarning />

      <Breadcrumbs>
        <InternalLink to={rootPath}>{texts.menu.links.home}</InternalLink>
        <InternalLink to={`${rootPath}/news`}>
          {texts.menu.links.news}
        </InternalLink>
      </Breadcrumbs>

      <MainContent>
        <PageHeading
          header={texts.menu.links.news}
          ingress={<RenderHtml html={texts.tile.News.description} />}
        />

        <FundAvailabilityAlert contentType="content" fluid />

        <NewsArticles />

        <NewsLinks />

        <Row>
          <Column $span={4}>
            <AssetAllocationBannerWidget />
          </Column>
          <Column $span={8}>
            <FundsSustainabilityBannerWidget />
          </Column>
        </Row>
      </MainContent>
    </CenteredPageContent>
  );
};
