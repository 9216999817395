import LoadingIndicator from '@storeblocks/loading-indicator';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useCurrentUserInfo } from '@/auth/hooks';
import { H2 } from '@/components/Typography/H2';
import { useTexts } from '@/hooks/useTexts';

import { useOrderSteps } from '../../components/OrderStepsProvider';
import { useOrderPageContext } from '../../OrderPageContext';

export const Signing: React.FC = () => {
  const { orders } = useTexts();
  const { completeCurrentStep } = useOrderSteps();
  const { userCmId } = useCurrentUserInfo();
  const { currentOrder, reset } = useOrderPageContext();

  // Complete this step if the current user is not a signatory.
  useEffect(() => {
    if (!currentOrder.signatories.includes(userCmId)) {
      // Reset the current order state to allow the user to create a new order.
      reset();

      completeCurrentStep();
    }
  }, [currentOrder.signatories]);

  return (
    <>
      <H2>{orders.form.steps.signing.title}</H2>

      <StyledLoading />
    </>
  );
};

const StyledLoading = styled(LoadingIndicator)`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 42px;
`;
