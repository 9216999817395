import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';

import { useIHubOrganizationReportsQuery } from '@/api/reports/reportsApi';

import { Report, ReportSource } from '../types/ReportModel';
import { ReportsFilter } from '../types/ReportsFilter';
import { filterByReportSource } from '../utils/filterByReportSource';
import { mapIHubReportToReport } from '../utils/mapIHubReportToReport';
import { useWolfReports } from './useWolfReports';

interface Reports {
  reports: Report[];
  reportsIsPending?: boolean;
  reportsIsError?: boolean;
}

const defaultData = [];

export const useReports = (filter: ReportsFilter): Reports => {
  const { wolfReports, wolfReportsIsSuccess, wolfReportsIsError } =
    useWolfReports(filter);
  const organizationReportsQuery = useIHubOrganizationReportsQuery(filter);

  const isError = wolfReportsIsError || organizationReportsQuery.isError;
  const isFetching =
    !wolfReportsIsSuccess || !organizationReportsQuery.isSuccess;

  // Map reports to view models.
  return useMemo<Reports>(() => {
    if (isError) {
      return {
        reports: defaultData,
        reportsIsError: true,
      };
    }

    // Wait for both data sources to finish fetching before processing them.
    // Otherwise one data set will be visible and then after some time the
    // other data set will pop in.
    if (isFetching) {
      return {
        reports: defaultData,
        reportsIsPending: true,
      };
    }

    const ihubReports = organizationReportsQuery.data?.map((report) =>
      mapIHubReportToReport(report, ReportSource.IHubOrganization),
    );

    const filteredReports = filterByReportSource(
      [...wolfReports, ...ihubReports],
      filter.reportSource,
    );

    const reports = orderBy(filteredReports, (report) => report.reportDateISO, [
      'desc',
    ]);

    return {
      reports,
    };
  }, [wolfReports, organizationReportsQuery.data, isFetching, isError]);
};
