import Table from '@storeblocks/table';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Permission } from '@/api/permissions/models/Permission';
import { User } from '@/api/users/models/User';
import { EditAndDeleteButtons } from '@/components/EditAndDeleteButtons';
import { useHasPermission } from '@/components/Permissions/useHasPermission';
import { useTexts } from '@/hooks/useTexts';

import { EditAllUsers } from './AddAndEditUserModal/EditAllUsers';
import { DeleteIndividualUserModal } from './DeleteUserModal';
import { OrganizationCell } from './OrganizationCell';
import { IndividualUserColumn, IndividualUserRow } from './rowAndColumnTypes';

interface Props {
  individualUsers: User[];
}

export const IndividualUsersTable: FC<Props> = ({ individualUsers }) => {
  const texts = useTexts();

  const hasPermission = useHasPermission();
  const hasAdminWriteRole = hasPermission(Permission.UserAdminWrite);

  const [tableRows, setTableRows] = useState<IndividualUserRow[]>([]);
  const [userToEdit, setUserToEdit] = useState<User | undefined>();
  const [userToDelete, setUserToDelete] = useState<User | undefined>();

  useEffect(() => {
    const rows = createRows(individualUsers);
    setTableRows(rows);
  }, [individualUsers, hasAdminWriteRole]);

  const createRows = (users: User[]): IndividualUserRow[] =>
    users.map((user) => ({
      name: `${user.givenName} ${user.familyName}`,
      email: user.email || '-',
      organizations: {
        displayContent: (
          <OrganizationCell organizations={user.allowedOrganizationsByName} />
        ),
        sortContent: user.allowedOrganizationsByName[0],
      },
      actions: {
        displayContent: (
          <EditAndDeleteButtons
            disabled={!hasAdminWriteRole || !user.userId}
            onEdit={() => setUserToEdit(user)}
            onDelete={() => setUserToDelete(user)}
            alignRight
            actionContext="all-users-user"
          />
        ),
        sortContent: '',
      },
    }));

  const tableColumns: IndividualUserColumn[] = [
    {
      key: 'name',
      name: texts.contact.name,
      dataType: 'text',
    },
    {
      key: 'email',
      name: texts.email,
      dataType: 'text',
    },
    {
      key: 'organizations',
      name: texts.adminModal.organizations,
      dataType: 'other',
      renderType: 'jsx',
    },
    {
      key: 'actions',
      name: '',
      dataType: 'other',
      renderType: 'jsx',
      style: { textAlign: 'right' },
    },
  ];

  return (
    <>
      <StyledTable data={tableRows} columns={tableColumns} sortable />

      {userToEdit && (
        <EditAllUsers
          onClose={() => setUserToEdit(undefined)}
          userToEdit={userToEdit}
        />
      )}
      {userToDelete && (
        <DeleteIndividualUserModal
          show={!!userToDelete}
          onClose={() => setUserToDelete(undefined)}
          user={userToDelete}
        />
      )}
    </>
  );
};

const StyledTable = styled(Table)`
  margin-top: 32px;

  // Name.
  & th:nth-of-type(1) {
    width: 33%;
    max-width: 33%;
  }

  // Email.
  & th:nth-of-type(2) {
    width: 20%;
    max-width: 20%;
  }

  // Organizations.
  & th:nth-of-type(3) {
    width: 30%;
    max-width: 30%;
  }

  // Action buttons.
  & th:nth-of-type(4) {
    width: 17%;
    max-width: 17%;
  }
`;
