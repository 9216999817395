import { Illustration } from '@storeblocks/icon';
import React from 'react';
import { styled } from 'styled-components';

import { useFmsTextsQuery } from '@/api/fms/fmsApi';
import StorebrandLogo from '@/assets/logo/storebrand.svg';
import { FullPageLoadingIndicator } from '@/components/FullPageLoadingIndicator';
import { Column, Row } from '@/components/Grid';
import { MainContent } from '@/components/MainContent';
import { PageHeading } from '@/components/PageHeading';
import { appConfig } from '@/constants/config';
import { Language } from '@/i18n/Language';

/**
 * Page to display when internal user does not have access.
 *
 * This is most likely due to the internal user not having the
 * correct roles/access packages.
 */
export const InternalUserDoesNotHaveAccessToConnectPage: React.FC = () => {
  // We might be unable to fetch the language for the user as they do
  // not have access to the application.
  // We therefore fetch the texts for the page in the default language.
  const { data: texts } = useFmsTextsQuery(Language.Norwegian);

  if (!texts) {
    return <FullPageLoadingIndicator />;
  }

  return (
    <Container data-test="internal-user-does-not-have-access-to-connect-page">
      <Center>
        <LogoContainer>
          <StorebrandLogo height={20} />
        </LogoContainer>

        <MainContent>
          <PageHeading header={texts.pages.internalUserMissingAccess.title} />

          <ContentRow>
            <Column $span={7}>
              <Ingress>
                {texts.pages.internalUserMissingAccess.description}
              </Ingress>
            </Column>

            <IllustrationColumn $span={5}>
              <Illustration name="something-went-wrong" height="500" />
            </IllustrationColumn>
          </ContentRow>
        </MainContent>
      </Center>
    </Container>
  );
};

const Container = styled.div`
  background-color: var(--color-background);
  border-top: 3px solid var(--color-brand);
`;

const Center = styled.div`
  max-width: ${appConfig.maxPageWidth};
  margin: 0 auto;
`;

const LogoContainer = styled.div`
  padding: 26px 0;
`;

const Ingress = styled.div`
  font-size: 18px;
  line-height: 24px;
`;

const ContentRow = styled(Row)`
  margin-top: 8px;
`;

const IllustrationColumn = styled(Column)`
  display: flex;
  justify-content: center;
`;
