import { useField } from 'formik';
import React from 'react';
import { styled } from 'styled-components';

import { OrderValueType } from '@/api/order/models/OrderValueType';
import { RenderHtml } from '@/components/RenderHtml';
import { useTexts } from '@/hooks/useTexts';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { useUserLocale } from '@/i18n/hooks';
import { SubscriptionPayment } from '@/pages/Order/steps/OrderDetails/types/Payment';
import { SubscriptionFundState } from '@/pages/Order/steps/OrderDetails/types/SubscriptionFundState';
import { SubscriptionOrderFormValues } from '@/pages/Order/steps/OrderDetails/types/SubscriptionOrderFormValues';
import { getPaymentTitleFromAccountHolder } from '@/pages/Order/utils/getPaymentTitleFromAccountHolder';
import { formatNumber } from '@/util/formatNumber';
import { nameof } from '@/util/nameof';

interface Props {
  payment: SubscriptionPayment;
}

export const SubscriptionPaymentInformation: React.FC<Props> = ({
  payment,
}) => {
  const texts = useTexts();
  const locale = useUserLocale();

  const [{ value: funds }] = useField<SubscriptionFundState[]>(
    nameof<SubscriptionOrderFormValues>('toFunds'),
  );

  const totalAmount = funds
    .filter((fund) => payment.forIsins.includes(fund.isin))
    .filter((fund) => fund.valueType === OrderValueType.Amount)
    .reduce((sum, fund) => sum + fund.value, 0);

  const formattedAmount = formatNumber(totalAmount, locale);

  const paymentStepsText =
    texts.orders.form.payment.subscription.settlementAccount.info.steps;

  const html = fmsWithTemplate(paymentStepsText, {
    amount: formattedAmount,
    currency: payment.fundPaymentInformation.currency,
    name: getPaymentTitleFromAccountHolder(
      payment.fundPaymentInformation.accountHolder,
      texts,
    ),
    bankAccountNumber: payment.fundPaymentInformation.bankAccountNumber,
    iban: payment.fundPaymentInformation.iban,
    swift: payment.fundPaymentInformation.swift,
  });

  return (
    <Container>
      <RenderHtml html={html} />
    </Container>
  );
};

const Container = styled.div`
  line-height: 24px;
`;
