import Alert from '@storeblocks/alert';
import Button from '@storeblocks/button';
import Input from '@storeblocks/input';
import Modal, { Content, Footer, Heading } from '@storeblocks/modal';
import { Form as FormikForm, Formik, FormikHelpers } from 'formik';
import React, { FC } from 'react';
import styled from 'styled-components';

import { useLazyOrganizationQuery } from '@/api/admin/adminApi';
import {
  useAddOrganizationMutation,
  useOrganizationsQuery,
} from '@/api/organizations/organizationsApi';
import { Ingress } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';

import {
  AddOrganizationFormValues,
  getInitialValues,
  getValidationSchema,
} from './AddOrganizationFormSchema';

interface Props {
  show: boolean;
  onClose: () => void;
}

export const AddOrganizationModal: FC<Props> = ({ show, onClose }) => {
  const organizationsQuery = useOrganizationsQuery();

  const [fetchOrganization, fetchOrganizationQuery] =
    useLazyOrganizationQuery();

  const [addOrganization, addOrganizationQuery] = useAddOrganizationMutation();

  const getTrackId = useGetTrackId();

  const texts = useTexts();

  const handleOnSubmit = async (
    values: AddOrganizationFormValues,
    formikHelpers: FormikHelpers<AddOrganizationFormValues>,
  ): Promise<void> => {
    await addOrganization({
      organizationId: values.organizationCmId,
      organizationName: fetchOrganizationQuery.data.organizationName,
    });
    formikHelpers.resetForm();
  };

  const handleClosed = (resetForm: () => void): void => {
    organizationsQuery.refetch();
    addOrganizationQuery.reset();
    resetForm();
    onClose();
  };

  const handleClicked = (organizationCmId: string): void => {
    fetchOrganization({ organizationCmId });
  };
  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={handleOnSubmit}
      validationSchema={getValidationSchema(texts, organizationsQuery.data)}
    >
      {({ isSubmitting, errors, resetForm, values, getFieldProps }) => (
        <Modal
          trigger={undefined}
          open={show}
          onClose={() => {
            handleClosed(resetForm);
          }}
        >
          <Heading>{texts.adminModal.newOrgLabel}</Heading>
          {addOrganizationQuery.isSuccess ? (
            <Content>
              <Alert
                fluid
                variant="success"
                title={texts.adminModal.addOrganization.success}
                description={fetchOrganizationQuery.data?.organizationName}
                data-test="add-organization-success-alert"
              />
            </Content>
          ) : (
            <Content data-test="add-organization-modal">
              <FormikForm id="add-organization-form">
                <WithGap>
                  <WithGap direction="row">
                    <Input
                      id="organizationCmId"
                      type="text"
                      fluid
                      label={texts.adminModal.orgID}
                      data-test="organization-cmid-input"
                      error={errors.organizationCmId}
                      {...getFieldProps('organizationCmId')}
                    />
                    <StyledButton
                      onClick={() => {
                        handleClicked(values.organizationCmId);
                      }}
                      type="button"
                      variant="outlined"
                      loading={fetchOrganizationQuery.isFetching}
                      data-trackid={getTrackId(
                        'search-organization:add-organization-modal',
                        TrackingElement.Button,
                      )}
                      data-test="add-organization-search-button"
                    >
                      {texts.adminModal.searchButton}
                    </StyledButton>
                  </WithGap>

                  {fetchOrganizationQuery.isSuccess && (
                    <div>
                      {fetchOrganizationQuery.data
                        ?.isMissingOrganizationNumber && (
                        <Alert
                          fluid
                          variant="warning"
                          title={
                            texts.adminModal.addOrganization
                              .missingOrganizationNumber.title
                          }
                          data-test="add-organization-no-organization-number-alert"
                        >
                          <p>
                            {
                              texts.adminModal.addOrganization
                                .missingOrganizationNumber.part1
                            }{' '}
                            <a
                              type="email"
                              href="mailto:team-connect@storebrand.no"
                            >
                              team-connect@storebrand.no
                            </a>
                          </p>
                          <p>
                            {
                              texts.adminModal.addOrganization
                                .missingOrganizationNumber.part2
                            }
                          </p>
                        </Alert>
                      )}

                      <SpaceBetweenWithGap direction="row">
                        <MarginTopIngress>
                          {fetchOrganizationQuery.data?.organizationName}
                        </MarginTopIngress>
                        <Button
                          type="submit"
                          loading={addOrganizationQuery.isLoading}
                          data-trackid={getTrackId(
                            'add-organization:add-organization-modal',
                            TrackingElement.Button,
                          )}
                          data-test="add-organization-submit-button"
                        >
                          {texts.adminModal.addButton}
                        </Button>
                      </SpaceBetweenWithGap>
                    </div>
                  )}

                  {fetchOrganizationQuery.isError && (
                    <Alert
                      fluid
                      variant="error"
                      title={texts.adminModal.addOrganization.notFound}
                      description={
                        texts.adminModal.addOrganization.notFoundDescription
                      }
                      data-test="add-organization-notfound-alert"
                    />
                  )}

                  {addOrganizationQuery.isError && (
                    <Alert
                      fluid
                      variant="error"
                      title={texts.adminModal.addOrganization.addError}
                      data-test="add-organization-error-alert"
                    />
                  )}
                </WithGap>
              </FormikForm>
            </Content>
          )}

          <Footer>
            <Button
              type="button"
              disabled={isSubmitting}
              variant="outlined"
              onClick={() => handleClosed(resetForm)}
              data-trackid={getTrackId(
                'close:add-organization-modal',
                TrackingElement.Button,
              )}
              data-test="add-organization-close-button"
            >
              {texts.adminModal.closeButton}
            </Button>
          </Footer>
        </Modal>
      )}
    </Formik>
  );
};

const StyledButton = styled(Button)`
  /**
    This margin compensates for the margin of the label on top of the input.
    It should be 42px to align it perfectly with the bottom of the input field,
    but Input field is not 40px in height. It is actually 42px where as the button is 40px.
    Therefore aligning them makes the button and input field look uneven.
    Solution is to align the text inside the button with the middle of the input field.
    */
  margin-top: 40px;
`;

const SpaceBetweenWithGap = styled(WithGap)`
  border-top: 1px solid var(--color-border);
  padding-top: 24px;
  justify-content: space-between;
`;

const MarginTopIngress = styled(Ingress)`
  margin-top: 8px;
`;
