import { format } from 'date-fns';
import React from 'react';

import { Order, OrderStatus } from '@/api/orderList/models/Order';
import { H2, Ingress } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { defaultDateFormat } from '@/i18n/hooks/useUserDateFormat';

import { orderStatusToFmsText } from '../utils/orderStatusToFmsText';
import { orderTypeToFmsText } from '../utils/orderTypeToFmsText';
import { DownloadOrderDocumentButton } from './DownloadOrderDocumentButton';

interface Props {
  order: Order;
}

export const Heading: React.FC<Props> = ({ order }) => {
  const texts = useTexts();

  const createdDate = format(order.createdDate, defaultDateFormat);
  const createdDateTime = format(
    order.createdDate,
    `${defaultDateFormat} HH:mm`,
  );

  const orderTypeText = orderTypeToFmsText(order.type, texts);
  const orderStatusText = orderStatusToFmsText(order.status, texts);

  const ingress = order.portfolioName
    ? `${order.portfolioId} - ${order.portfolioName}`
    : `${order.portfolioId}`;

  return (
    <WithGap gap="16">
      <H2>{`${orderTypeText} - ${createdDate} - ${orderStatusText}`}</H2>

      <WithGap gap="8">
        <Ingress>{ingress}</Ingress>
        <Ingress>
          {fmsWithTemplate(texts.pages.orderList.orderCreated, {
            dateTime: createdDateTime,
            author: order.createdBy,
          })}
        </Ingress>
      </WithGap>

      <DownloadOrderDocumentButton
        orderId={order.orderId}
        // Disable download button if order is pending signing as a temporary workaround
        // as downloading the document is not yet possible if all signatories has not yet signed and PAdES is not received.
        disabled={order.status === OrderStatus.PendingSigning}
      />
    </WithGap>
  );
};
