import round from 'lodash/round';

import { AssetPerformanceData } from '@/api/performanceData/models';
import { Locale } from '@/i18n/locale';
import { formatNumber } from '@/util/formatNumber';
import { roundHalfEven } from '@/util/roundHalfEven';

import { AssetAllocationViewMode } from '../../types/AssetAllocationViewMode';
import { AssetAllocationRow } from '../types/AssetAllocationRow';

/**
 * Traverse the asset performance data and map all asset allocation holding rows.
 *
 * @param assetPerformance The performance data to traverse.
 * @param locale The current locale used for number formatting.
 * @param fraction The weight fraction the total value of this row represents.
 * This is used for correctly calculating the total weight when data from multiple portfolios are displayed.
 * E.x. if there are 4 portfolios in total the fraction of this portfolio row would be '1/4' or '0.25'.
 * @returns The mapped row with all traversed and mapped sub rows.
 */
export const mapToAssetAllocationRow = (
  assetPerformance: AssetPerformanceData,
  locale: Locale,
  viewMode: AssetAllocationViewMode,
  fraction = 1,
): AssetAllocationRow => {
  return {
    name: assetPerformance.name,

    ...((viewMode === AssetAllocationViewMode.Holdings ||
      viewMode === AssetAllocationViewMode.All) && {
      marketValue: formatNumber(
        roundHalfEven(assetPerformance.marketValue, 0),
        locale,
      ),
      yearToDate: formatNumber(
        roundHalfEven(assetPerformance.yearToDateAmount, 0),
        locale,
      ),
      weight: formatNumber(
        round(assetPerformance.assetRatioOfTotalPercent * fraction, 1),
        locale,
        '%',
        { minimumFractionDigits: 1 },
      ),
    }),

    ...((viewMode === AssetAllocationViewMode.Performance ||
      viewMode === AssetAllocationViewMode.All) && {
      monthToDatePercent: formatNumber(
        round(assetPerformance.monthToDatePercent, 2),
        locale,
        '%',
        { minimumFractionDigits: 2 },
      ),

      monthToDateBenchmark: formatNumber(
        round(assetPerformance.benchmarkMonthToDatePercent, 2),
        locale,
        '%',
        { minimumFractionDigits: 2 },
      ),

      yearToDatePercent: formatNumber(
        round(assetPerformance.yearToDatePercent, 2),
        locale,
        '%',
        { minimumFractionDigits: 2 },
      ),

      yearToDateBenchmark: formatNumber(
        round(assetPerformance.benchmarkYearToDatePercent, 2),
        locale,
        '%',
        { minimumFractionDigits: 2 },
      ),
    }),

    subRows: assetPerformance.assets.map((asset) =>
      mapToAssetAllocationRow(asset, locale, viewMode, fraction),
    ),
  };
};
