import React from 'react';
import { useDispatch } from 'react-redux';
import { LinkProps, useHref, useLinkClickHandler } from 'react-router';

import { createLogEvent } from '@/logging/createLogEvent';
import { addLogEvent } from '@/logging/logSlice';
import { findLogDataInClickEvent } from '@/logging/utils/findLogDataInClickEvent';
import { AppDispatch } from '@/store';

/**
 * Implementation of the React Router <Link /> component as per their
 * documentation: https://reactrouter.com/en/main/hooks/use-link-click-handler
 *
 * Used for internal navigation with event tracking.
 */
export const InternalLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ onClick, replace = false, state, target, to, children, ...rest }, ref) => {
    const href = useHref(to);
    const handleClick = useLinkClickHandler(to, {
      replace,
      state,
      target,
    });

    const dispatch = useDispatch<AppDispatch>();

    return (
      <a
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        href={href}
        onClick={(event) => {
          const logData = findLogDataInClickEvent(event.nativeEvent);

          dispatch(
            addLogEvent(
              createLogEvent({
                level: 'info',
                type: 'click',
                description: 'Internal navigation',
                data: logData
                  ? {
                      trackId: logData.trackId,
                      ...logData.extraData,
                    }
                  : undefined,
              }),
            ),
          );

          onClick?.(event);
          if (!event.defaultPrevented) {
            handleClick(event);
          }

          // Stop this event from being picked up by the logging event listener.
          event.stopPropagation();
        }}
        ref={ref}
        target={target}
      >
        {children}
      </a>
    );
  },
);

InternalLink.displayName = InternalLink.name;
