import { OrderPlacementDto } from './OrderPlacementDto';
import { OrderTypeDto } from './OrderTypeDto';

interface BaseOrderDto {
  id: string;
  created: string;
  orderType: OrderTypeDto;
  userId: string;
  userName: string;
  signatories: Array<string>;
}

export interface FundOrderDto extends BaseOrderDto {
  orderPlacements: Array<OrderPlacementDto>;
  /**
   * Portfolio short name.
   * @example 'P1227F'
   */
  portfolioNumber: string;
}

export interface DepositOrderDto extends BaseOrderDto {
  amount: number;
  specifications?: string;
  currencyCode: string;

  /**
   * Portfolio short name.
   * @example 'P1227F'
   */
  portfolioNumber: string;
  toPortfolioBankAccountNumber: string;
  expectedDepositDate: string;
}

export interface WithdrawalOrderDto extends BaseOrderDto {
  amount: number;
  specifications?: string;
  currencyCode: string;

  /**
   * Portfolio short name.
   * @example 'P1227F'
   */
  portfolioNumber: string;
  fromPortfolioBankAccountNumber: string;
  toExternalBankAccountNumber: string;
  expectedTransferDate: string;
}

export interface TransferOrderDto extends BaseOrderDto {
  amount: number;
  specifications?: string;
  currencyCode: string;

  fromPortfolioId: string;
  fromPortfolioBankAccountNumber: string;
  expectedTransferDate: string;

  toPortfolioId: string;
  toPortfolioBankAccountNumber: string;
  toExternalBankAccountNumber: string;
}

function hasOrderTypeProperty(
  value: unknown,
): value is { orderType: OrderTypeDto } {
  return (
    typeof value === 'object' &&
    value !== null &&
    'orderType' in value &&
    typeof value.orderType === 'string'
  );
}

export function isFundOrderDto(value: unknown): value is FundOrderDto {
  return (
    hasOrderTypeProperty(value) &&
    (value.orderType === OrderTypeDto.BuyFund ||
      value.orderType === OrderTypeDto.SellFund ||
      value.orderType === OrderTypeDto.Switch)
  );
}

export function isDepositOrderDto(value: unknown): value is DepositOrderDto {
  return (
    hasOrderTypeProperty(value) &&
    value.orderType === OrderTypeDto.DepositDiscretionary
  );
}

export function isWithdrawOrderDto(
  value: unknown,
): value is WithdrawalOrderDto {
  return (
    hasOrderTypeProperty(value) &&
    value.orderType === OrderTypeDto.WithdrawDiscretionary
  );
}

export function isTransferOrderDto(value: unknown): value is TransferOrderDto {
  return (
    hasOrderTypeProperty(value) &&
    value.orderType === OrderTypeDto.TransferDiscretionary
  );
}
