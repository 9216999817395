import Alert from '@storeblocks/alert';
import React, { useEffect } from 'react';

import { useSigningRightSignedMutation } from '@/api/signing-right/signingRightApi';
import { MainContent } from '@/components/MainContent';
import { Pending } from '@/components/Pending';
import { useTexts } from '@/hooks/useTexts';

import { SigningRightBreadcrumb } from './components/SigningRightBreadcrumb';
import { Stepper } from './components/Stepper';
import { WaitForSigningConfirmation } from './components/WaitForSigningConfirmation';

export const SigningRightSignedPage: React.FC = () => {
  const texts = useTexts();

  const [postSigningRightSigned, signingRightSignedMutation] =
    useSigningRightSignedMutation();

  useEffect(() => {
    // Do a HTTP POST to the backend to mark the signing right as signed
    // when the user lands on this page. This page should only be reached
    // from the success URL given to ESS/Signicat.
    postSigningRightSigned();
  }, [postSigningRightSigned]);

  return (
    <>
      <SigningRightBreadcrumb />

      <MainContent data-test="signing-right-signed">
        <Stepper activeStep={2} />
        {/*
         * Wait for the POST to be successful as it changes the signed
         * status in the backend. Without this, the user would end up
         * with an "in progress" status.
         */}
        {signingRightSignedMutation.isLoading && <Pending />}
        {/* Wait for signing right signature confirmation after successfully setting the signed status. */}
        {signingRightSignedMutation.isSuccess && <WaitForSigningConfirmation />}
        {/* Show an error message if the signed status post fails. */}
        {signingRightSignedMutation.isError && (
          <Alert
            variant="error"
            title={texts.orders.signatureRight.signed.error.title}
            description={texts.orders.signatureRight.signed.error.description}
            data-test="signing-right-signed-error"
          />
        )}
      </MainContent>
    </>
  );
};
