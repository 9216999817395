import Alert from '@storeblocks/alert';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { useOrganizationName } from '@/api/organizations/hooks/useOrganizationName';
import { SigningRightStatus } from '@/api/signing-right/models/SigningRight';
import { useSigningRightForUserQuery } from '@/api/signing-right/signingRightApi';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { Column, Row } from '@/components/Grid';
import { MainContent } from '@/components/MainContent';
import { OrderToSignAlert } from '@/components/OrderToSignAlert/OrderToSignAlert';
import { Pending } from '@/components/Pending';
import { H1 } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { prettifyOrganizationName } from '@/util/prettifyOrganizationName';

import { OrderPagesButtons } from '../../components/OrderPagesButtons';
import { GoToDashboardButton } from './components/GoToDashboardButton';
import { SigningRightBreadcrumb } from './components/SigningRightBreadcrumb';
import { Stepper } from './components/Stepper';

export const SigningRightCompletedPage: React.FC = () => {
  const texts = useTexts();
  const navigate = useNavigate();
  const organizationCmId = useOrganizationCmId();
  const { organizationName } = useOrganizationName(organizationCmId);

  const signingRightQuery = useSigningRightForUserQuery();

  // Redirect to the correct page if the signing right is not completed.
  useEffect(() => {
    if (signingRightQuery.data?.status === SigningRightStatus.NotStarted) {
      navigate(`/${organizationCmId}/trading/signing-right`, {
        replace: true,
      });
    }

    if (signingRightQuery.data?.status === SigningRightStatus.InProgress) {
      navigate(`/${organizationCmId}/trading/signing-right/in-progress`, {
        replace: true,
      });
    }

    if (signingRightQuery.data?.status === SigningRightStatus.Unconfirmed) {
      navigate(`/${organizationCmId}/trading/signing-right/signed`, {
        replace: true,
      });
    }
  }, [signingRightQuery.data?.status]);

  if (signingRightQuery.isLoading) {
    return <Pending />;
  }

  if (signingRightQuery.isError) {
    return (
      <Alert
        variant="error"
        title={texts.orders.signatureRight.success.error.title}
        description={texts.orders.signatureRight.success.error.description}
      />
    );
  }

  // Do not render anything if the signing status is not completed
  // as we redirect to the correct page depending on the state instead.
  if (signingRightQuery.data?.status !== SigningRightStatus.Completed) {
    return null;
  }

  return (
    <>
      <SigningRightBreadcrumb />
      <MainContent data-test="signing-right-completed">
        <Stepper activeStep={3} />

        <H1>{texts.orders.signatureRight.success.mainTitle}</H1>
        <Alert
          variant="success"
          title={texts.orders.signatureRight.success.title}
          description={fmsWithTemplate(
            texts.orders.signatureRight.success.description,
            {
              organization: prettifyOrganizationName(organizationName),
            },
          )}
        />
        <Row>
          <Column $span={8}>
            <OrderToSignAlert />
          </Column>
        </Row>
        <WithGap gap="24" direction="row">
          <OrderPagesButtons />

          <GoToDashboardButton />
        </WithGap>
      </MainContent>
    </>
  );
};
