import React, { useMemo } from 'react';

import { MultiAssetPerformance } from '@/api/performanceData/models/MultiAssetPerformance';
import { Show } from '@/components/Show';
import { useTexts } from '@/hooks/useTexts';
import { useUserLocale } from '@/i18n/hooks';

import { AssetAllocationViewMode } from '..';
import { AssetTable } from '../components/AssetTable';
import { ColumnHints } from '../components/ColumnHints';
import { useAssetAllocationColumns } from './hooks/useAssetAllocationColumns';
import { AssetAllocationRow } from './types/AssetAllocationRow';
import { mapToMultiAssetAllocationRow } from './utils/mapToMultiAssetAllocationRow';
import { mapToMultiAssetAllocationSummaryRow } from './utils/mapToMultiAssetAllocationSummaryRow';

interface Props {
  multiAssetPerformance: MultiAssetPerformance;
  expanded?: boolean;
  viewMode: AssetAllocationViewMode;
  displayVerticalScrollbar?: boolean;
}

export const MultiAssetAllocationTable: React.FC<Props> = ({
  multiAssetPerformance,
  expanded,
  viewMode,
  displayVerticalScrollbar,
}) => {
  const texts = useTexts();

  const locale = useUserLocale();

  const columns = useAssetAllocationColumns(
    multiAssetPerformance.date,
    viewMode,
  );

  const rows = useMemo<AssetAllocationRow[]>(() => {
    if (multiAssetPerformance.assetPerformances.length === 0) {
      return [];
    }

    // Set a fraction for summing percentages based on the total number of asset performances.
    const fraction = 1 / multiAssetPerformance.assetPerformances.length;

    const portfolioRows = multiAssetPerformance.assetPerformances.reduce(
      (rows, assetPerformance) => {
        const portfolioRow = mapToMultiAssetAllocationRow(
          assetPerformance,
          locale,
          texts,
          viewMode,
          fraction,
        );

        rows.push(portfolioRow);

        return rows;
      },
      [],
    );

    const summaryRow = mapToMultiAssetAllocationSummaryRow(
      multiAssetPerformance.assetPerformances,
      locale,
      texts,
      viewMode,
      fraction,
    );

    portfolioRows.push(summaryRow);

    return portfolioRows;
  }, [locale, texts, multiAssetPerformance.assetPerformances, viewMode]);

  return (
    <>
      <AssetTable
        columns={columns}
        data={rows}
        expandable
        expanded={expanded}
        displayVerticalScrollbar={displayVerticalScrollbar}
      />
      <Show
        when={
          viewMode === AssetAllocationViewMode.All ||
          viewMode === AssetAllocationViewMode.Performance
        }
      >
        <ColumnHints />
      </Show>
    </>
  );
};
