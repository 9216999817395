import React from 'react';

import { EsgCategory } from '@/api/esg/models/EsgCategory';
import { LinearGauge } from '@/components/Charts';
import { TextShort, TextShortBold } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { useUserLocale } from '@/i18n/hooks';

import { EsgCoverageText } from '../../components/EsgCoverageText';

interface Props {
  esgScore: EsgCategory;
}

export const EsgScoreGauge: React.FC<Props> = ({ esgScore }) => {
  const texts = useTexts();
  const locale = useUserLocale();

  return (
    <WithGap>
      <div>
        <div>
          <TextShort>
            {texts.pages.esg.esgScore.score}{' '}
            <TextShortBold>{`${esgScore.kpi.value.toLocaleString(
              locale,
            )}/10`}</TextShortBold>
          </TextShort>
        </div>

        <EsgCoverageText percentage={esgScore.kpi.coverageInPercent} />
      </div>

      <LinearGauge
        value={esgScore.kpi.value <= 10 ? esgScore.kpi.value : 10}
        max={10}
        color={{
          color: 'var(--color-green-50)',
          background: 'var(--color-green-10)',
        }}
        decimals={1}
      />
    </WithGap>
  );
};
