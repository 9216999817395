import { useMemo } from 'react';
import * as Yup from 'yup';

import { useCurrentUserInfo } from '@/auth/hooks';
import { useTexts } from '@/hooks/useTexts';
import { useUserLocale } from '@/i18n/hooks';
import { SubscriptionOrderFormValues } from '@/pages/Order/steps/OrderDetails/types/SubscriptionOrderFormValues';

import { createSubscriptionValidationSchema } from '../createSubscriptionValidationSchema';

export const useCreateSubscriptionValidationSchema =
  (): Yup.SchemaOf<SubscriptionOrderFormValues> => {
    const texts = useTexts();
    const errorTexts = texts.orders.form.errors;

    const locale = useUserLocale();

    const { userType } = useCurrentUserInfo();

    return useMemo(
      () =>
        createSubscriptionValidationSchema({
          locale,
          userType,
          errorMessages: {
            portfolioRequired: errorTexts.portfolioRequired,
            mustHaveAtLeastOneFund:
              errorTexts.subscription.atLeastOneFundRequired,
            paymentPlacementRequired: errorTexts.subscription.paymentRequired,
            signatoryOptionRequired: errorTexts.signatoryOptionRequired,
            signerRequired: errorTexts.signerRequired,
            cosignerRequired: errorTexts.cosignerRequired,
            signingProviderRequired: errorTexts.signingProviderRequired,
            minimumSubscriptionAmount: errorTexts.subscription.minimumAmount,
            portfolioBankAccountNumberRequired:
              texts.orders.form.payment.options.portfolioSelect.error,
          },
        }),
      [errorTexts, locale],
    );
  };
