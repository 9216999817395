import { useTexts } from '@/hooks/useTexts';
import { Unit } from '@/types/Unit';

import { usePresentationContext } from '../../PresentationContext';

export const useUnitFilterText = (): string => {
  const texts = useTexts();
  const {
    globalFilter: { unit },
  } = usePresentationContext();

  switch (unit) {
    case Unit.Amount:
      return texts.pages.presentation.filters.unit.filterTexts.amount;
    case Unit.Percent:
      return texts.pages.presentation.filters.unit.filterTexts.percent;
    default:
      throw new Error(`Invalid unit [${unit}]`);
  }
};
