import InlineAlert from '@storeblocks/inline-alert';
import LoadingIndicator from '@storeblocks/loading-indicator';
import React from 'react';
import styled from 'styled-components';

import { LinkButton } from '@/components/shared/LinkButton';
import { useHandleAsyncOperation } from '@/hooks/useHandleAsyncError';
import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';
import { stripFilename } from '@/util/stripFilename';

import { FileTypeIcon } from '../FileTypeIcon/FileTypeIcon';

interface Props {
  filename: string;
  fileId: string;
  fileExtension: string;
  onDownload: (fileId: string) => Promise<unknown>;
}

export const FileNameColumn: React.FC<Props> = ({
  filename,
  fileId,
  fileExtension,
  onDownload,
}: Props) => {
  const texts = useTexts();
  const getTrackId = useGetTrackId();

  const startDownload = async (): Promise<void> => {
    await onDownload(fileId);
  };

  const [handleDownloadClick, isDownloading, isError] =
    useHandleAsyncOperation(startDownload);

  return (
    <Wrapper>
      <FileIconWrapper key="icon">
        <FileTypeIcon fileExtension={fileExtension} />
      </FileIconWrapper>

      <NameWrapper>
        {!isDownloading && (
          <StyledLinkButton
            key="anchor"
            onClick={handleDownloadClick}
            data-trackid={getTrackId(
              'download-clicked-file',
              TrackingElement.Link,
            )}
            data-trackid-downloaded-file={stripFilename(filename)}
          >
            {filename}
          </StyledLinkButton>
        )}

        {isError && (
          <InlineAlert variant="error" title={texts.download.singleFileError} />
        )}
      </NameWrapper>

      {isDownloading && (
        <SpinnerWrapper>
          <LoadingIndicator />
        </SpinnerWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const StyledLinkButton = styled(LinkButton)`
  margin-left: 0;
  word-break: break-all;
`;

const NameWrapper = styled.div`
  padding: 16px 0;
  line-height: 24px;
`;

const SpinnerWrapper = styled.div`
  justify-content: flex-start;
  align-self: center;
  height: 24px;
  padding: 16px 0;
`;

const FileIconWrapper = styled.span`
  padding: 16px 16px 0 16px;
  color: var(--color-primary);
`;
