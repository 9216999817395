import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';

import { useFetchFundHoldingsQuery } from '@/api/fundHoldings/fundHoldingsApi';
import { useFundListQuery } from '@/api/funds/fundsApi';
import { OrderType } from '@/api/order/models/OrderType';
import { OrderValueType } from '@/api/order/models/OrderValueType';
import { FormValidationSummary } from '@/components/Form/FormValidationSummary';
import { formHasErrors } from '@/components/Form/utils/formHasErrors';
import { Column, Row } from '@/components/Grid';
import { useHasPermission } from '@/components/Permissions/useHasPermission';
import { Show } from '@/components/Show';
import { H3 } from '@/components/Typography';
import { useTexts } from '@/hooks/useTexts';
import { CurrencyCode } from '@/types/CurrencyCode';
import { convertStringToEnum } from '@/util/convertStringToEnum';

import { useFundPortfolios } from '../../hooks/useFundPortfolios';
import { SwitchOrderFormValues } from '../../types/SwitchOrderFormValues';
import { getDefaultSignatoryOption } from '../../utils/getDefaultSignatoryOption';
import { getValidToValueTypes } from '../../utils/getValidToValueTypes';
import { mapHoldingsToRedemptionFundState } from '../../utils/mapHoldingsToRedemptionFundState';
import { mapHoldingsToSubscriptionFundState } from '../../utils/mapHoldingsToSubscriptionFundState';
import { CurrencySelector } from '../CurrencySelector';
import { FundPortfolioSelector } from '../FundPortfolioSelector';
import { RedemptionFundsTable, SubscriptionFundsTable } from '../FundsTable';
import { NavigationButtons } from '../NavigationButtons';
import { SignatoryOptions } from '../SignatoryOptions';
import { ValueTypeSelector } from '../ValueTypeSelector';
import { createInitialSwitchValues } from './utils/createInitialSwitchValues';

export const SwitchFormContent: React.FC = () => {
  const texts = useTexts();

  const { data: fundList } = useFundListQuery();
  const { data: fundHoldings } = useFetchFundHoldingsQuery();
  const portfolios = useFundPortfolios(OrderType.Switch);

  const hasPermission = useHasPermission();

  const { values, errors, submitCount, resetForm } =
    useFormikContext<SwitchOrderFormValues>();

  const hasSellAll = values.fromFunds.some((fund) => fund.sellAll);

  const validToValueTypes = hasSellAll
    ? [OrderValueType.Percent]
    : getValidToValueTypes(values.fromValueType);

  const { hasMultipleCurrencies } =
    values.fromPortfolioShortName &&
    fundHoldings.byPortfolioId[values.fromPortfolioShortName];

  const handleFromPortfolioOnChange = (portfolioShortName: string): void => {
    const currencies = portfolioShortName
      ? Object.keys(fundHoldings.byPortfolioId[portfolioShortName].byCurrency)
      : [];

    const currency =
      currencies.length === 1 &&
      convertStringToEnum(currencies[0], CurrencyCode);

    const redemptionFundStates = currency
      ? mapHoldingsToRedemptionFundState(
          fundList.byIsin,
          fundHoldings.byPortfolioId[portfolioShortName].holdings,
          values.fromValueType,
        )
      : [];

    const subscriptionFundStates = currency
      ? mapHoldingsToSubscriptionFundState(
          fundList.byIsin,
          fundHoldings.byPortfolioId[portfolioShortName].holdings,
          values.toValueType,
        )
      : [];

    const initialValues = createInitialSwitchValues(
      currency,
      portfolioShortName,
      portfolioShortName,
      values.fromValueType,
      values.toValueType,
      redemptionFundStates,
      subscriptionFundStates,
      getDefaultSignatoryOption(hasPermission),
    );

    resetForm({
      values: initialValues,
    });
  };

  const handleCurrencyOnChange = (value: string): void => {
    const currency = convertStringToEnum(value, CurrencyCode);

    const redemptionFundStates = mapHoldingsToRedemptionFundState(
      fundList.byIsin,
      fundHoldings.byPortfolioId[values.fromPortfolioShortName].byCurrency[
        currency
      ],
      values.fromValueType,
    );

    const subscriptionFundStates = mapHoldingsToSubscriptionFundState(
      fundList.byIsin,
      fundHoldings.byPortfolioId[values.fromPortfolioShortName].byCurrency[
        currency
      ],
      values.toValueType,
    );

    const initialValues = createInitialSwitchValues(
      currency,
      values.fromPortfolioShortName,
      values.toPortfolioShortName,
      values.fromValueType,
      values.toValueType,
      redemptionFundStates,
      subscriptionFundStates,
      getDefaultSignatoryOption(hasPermission),
    );

    resetForm({
      values: {
        ...initialValues,
        signer: values.signer,
        cosigner: values.cosigner,
      },
    });
  };

  const handleFromValueTypeOnChange = (value: string): void => {
    const valueType = convertStringToEnum(value, OrderValueType);

    const redemptionFundStates = mapHoldingsToRedemptionFundState(
      fundList.byIsin,
      fundHoldings.byPortfolioId[values.fromPortfolioShortName].byCurrency[
        values.currency
      ],
      valueType,
    );

    const subscriptionFundStates = mapHoldingsToSubscriptionFundState(
      fundList.byIsin,
      fundHoldings.byPortfolioId[values.fromPortfolioShortName].byCurrency[
        values.currency
      ],
      OrderValueType.Percent,
    );

    const initialValues = createInitialSwitchValues(
      values.currency,
      values.fromPortfolioShortName,
      values.toPortfolioShortName,
      valueType,
      OrderValueType.Percent,
      redemptionFundStates,
      subscriptionFundStates,
      getDefaultSignatoryOption(hasPermission),
    );

    resetForm({
      values: {
        ...initialValues,
        signer: values.signer,
        cosigner: values.cosigner,
      },
    });
  };

  const handleToValueTypeOnChange = (value: string): void => {
    const valueType = convertStringToEnum(value, OrderValueType);

    const subscriptionFundStates = mapHoldingsToSubscriptionFundState(
      fundList.byIsin,
      fundHoldings.byPortfolioId[values.fromPortfolioShortName].byCurrency[
        values.currency
      ],
      valueType,
    );

    const initialValues = createInitialSwitchValues(
      values.currency,
      values.fromPortfolioShortName,
      values.toPortfolioShortName,
      values.fromValueType,
      valueType,
      values.fromFunds,
      subscriptionFundStates,
      getDefaultSignatoryOption(hasPermission),
    );

    resetForm({
      values: {
        ...initialValues,
        signer: values.signer,
        cosigner: values.cosigner,
      },
    });
  };

  return (
    <Container>
      {portfolios.length > 1 && (
        <Row>
          <Column $span={5}>
            <FundPortfolioSelector
              orderType={OrderType.Redemption}
              initialPortfolioShortName={values.fromPortfolioShortName}
              onChange={handleFromPortfolioOnChange}
            />
          </Column>
        </Row>
      )}

      {/* Only render when the user has selected a portfolio. */}
      <Show when={!!values.toPortfolioShortName} animate>
        <Container>
          <Show when={hasMultipleCurrencies} animate>
            <CurrencySelector
              initialCurrency={values.currency}
              portfolioShortName={values.toPortfolioShortName}
              onChange={handleCurrencyOnChange}
            />
          </Show>

          <Show when={!!values.currency} animate>
            <Container>
              <TableContainer>
                <TitleContainer>
                  <H3>{texts.orders.form.switch.from.title}</H3>
                  <ValueTypeSelector
                    id="from-value-type"
                    dataSource="switch-from"
                    valueType={values.fromValueType}
                    validValueTypes={[
                      OrderValueType.Amount,
                      OrderValueType.Percent,
                      OrderValueType.Units,
                    ]}
                    onChange={handleFromValueTypeOnChange}
                  />
                </TitleContainer>
                <RedemptionFundsTable />
              </TableContainer>

              <TableContainer>
                <TitleContainer>
                  <H3>{texts.orders.form.switch.to.title}</H3>

                  <ValueTypeSelector
                    id="to-value-type"
                    dataSource="switch-to"
                    valueType={values.toValueType}
                    validValueTypes={validToValueTypes}
                    onChange={handleToValueTypeOnChange}
                  />
                </TitleContainer>
                <SubscriptionFundsTable hideHoldings />
              </TableContainer>

              <SignatoryOptions />

              <Show when={submitCount > 0 && formHasErrors(errors)} animate>
                <FormValidationSummary
                  title={texts.orders.form.errors.validationSummary.title}
                  errors={errors}
                />
              </Show>

              <NavigationButtons />
            </Container>
          </Show>
        </Container>
      </Show>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
