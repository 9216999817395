import Input from '@storeblocks/input/lib';
import { useField } from 'formik';
import React, { useEffect } from 'react';

import { OrderPaymentType } from '@/api/order/models/OrderPaymentType';
import { useTexts } from '@/hooks/useTexts';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { nameof } from '@/util/nameof';

import { RedemptionPayment } from '../../../types/Payment';
import { RedemptionOrderFormValues } from '../../../types/RedemptionOrderFormValues';

interface Props {
  paymentIndex: number;
}

export const RedemptionBankAccountNumber: React.FC<Props> = ({
  paymentIndex,
}) => {
  const texts = useTexts();

  const [field, meta, helpers] = useField<string>(
    `payments[${paymentIndex}].bankAccountNumber`,
  );

  const [payments] = useField<RedemptionPayment[]>(
    nameof<RedemptionOrderFormValues>('payments'),
  );
  const payment = payments.value[paymentIndex];

  // To be more explicit towards the user we remove any existing
  // text from the input field if the user choses not to use the
  // account number from the input field.
  useEffect(() => {
    if (payment && payment.paymentPlacement !== OrderPaymentType.BankAccount) {
      helpers.setValue('');
    }
  }, [payment]);

  const title =
    payments.value.length > 1
      ? fmsWithTemplate(
          texts.orders.form.payment.redemption.titleWithCurrency,
          {
            currency: payment.currency,
          },
        )
      : texts.orders.form.payment.bankAccountNumber;

  return (
    <Input
      id={`payment-bankAccountNumber-${payment.currency}`}
      label={title}
      disabled={payment.paymentPlacement !== OrderPaymentType.BankAccount}
      inputProps={{
        autoComplete: 'off',
      }}
      error={meta.touched && meta.error}
      large
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...field}
    />
  );
};
