import { Icon } from '@storeblocks/icon';
import Surface from '@storeblocks/surface';
import React from 'react';
import styled from 'styled-components';

import { H2, Paragraph } from '@/components/Typography';
import { useTexts } from '@/hooks/useTexts';

export const InfoBanner: React.FC = () => {
  const texts = useTexts();

  return (
    <StyledSurface variant="border">
      <StyledIcon name="shield-check" />
      <Content>
        <H2>{texts.orders.signatureRight.infoBanner.title}</H2>
        <Paragraph>
          {texts.orders.signatureRight.infoBanner.description}
        </Paragraph>
      </Content>
    </StyledSurface>
  );
};

const StyledSurface = styled(Surface)`
  display: flex;
`;

const StyledIcon = styled(Icon)`
  align-items: center;

  & > svg {
    padding: 16px;
    width: 96px;
    height: 96px;
  }
`;

const Content = styled.div`
  padding: 16px;
`;
