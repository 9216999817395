import { format, parseISO } from 'date-fns';
import React, { FC } from 'react';
import { styled } from 'styled-components';

import { Esg } from '@/api/esg/models/Esg';
import { Center } from '@/components/Center';
import { Gauge } from '@/components/Charts';
import { Column, Row } from '@/components/Grid';
import { Helper } from '@/components/Helper';
import { Show } from '@/components/Show';
import { H2, H3, Hint, Paragraph } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { useUserDateFormat } from '@/i18n/hooks';
import { CurrencyCode } from '@/types/CurrencyCode';

import { Line } from './components/Line';
import { EsgCarbonFootprint } from './widgets/EsgCarbonFootprint/EsgCarbonFootprint';
import { EsgExclusions } from './widgets/EsgExclusions/EsgExclusions';
import { EsgFundList } from './widgets/EsgFundList/EsgFundList';
import { EsgOverviewValueList } from './widgets/EsgOverview/EsgOverviewValueList';
import { EsgScore } from './widgets/ESGScore/EsgScore';
import { EsgSolutionsChart } from './widgets/EsgSolutions/EsgSolutionsChart';
import { EsgTaxonomyWidget } from './widgets/EsgTaxonomy';

interface Props {
  data: Esg;
}

export const EsgDashboard: FC<Props> = ({ data }) => {
  const texts = useTexts();
  const dateFormat = useUserDateFormat();

  return (
    <WithGap data-test="esg-dashboard">
      <Hint>
        {fmsWithTemplate(texts.common.lastUpdated, {
          date: format(parseISO(data.updatedDate), dateFormat),
        })}
      </Hint>

      <H2>{texts.pages.esg.title}</H2>

      <Row>
        <Column $span={6}>
          <WithGap data-test="esg-overview-widget">
            <H3>{texts.pages.esg.overview.title}</H3>

            <Center horizontal vertical>
              <Gauge
                percent={data.solutionCompanies.kpi.value}
                description={texts.pages.esg.overview.sustainableInvestments}
                size={300}
              />
            </Center>

            <EsgOverviewValueList
              esgScore={data.esgScore}
              solutionCompanies={data.solutionCompanies}
              euTaxonomyAlignment={data.euTaxonomyAlignment}
              euTaxonomyEligibility={data.euTaxonomyEligibility}
              carbonIntensities={data.carbonIntensities}
              pai11GhgEmissionsSum={data.pai11GhgEmissionsSum}
            />
          </WithGap>
        </Column>

        <Column $span={6}>
          <EsgFundList portfolioGroupId={data.portfolioGroupId} />
        </Column>
      </Row>

      <Line />

      <Row>
        <Column $span={6}>
          <WithGap>
            <H2>{texts.pages.esg.esgScore.title}</H2>

            <EsgScore esgScore={data.esgScore} />
          </WithGap>
        </Column>

        <Column $span={6}>
          {data.carbonIntensities.find(
            (score) => score.currency === CurrencyCode.NOK,
          ).benchmark && (
            <WithGap>
              <H2>{texts.pages.esg.carbonFootprint.title}</H2>

              <EsgCarbonFootprint
                esgCarbonIntensities={data.carbonIntensities}
                pai11GhgEmissionsSum={data.pai11GhgEmissionsSum}
              />
            </WithGap>
          )}
        </Column>
      </Row>

      {/** @todo: Temporary taxonomy data need to be hidden, see SAMIK-1799 */}
      <Show when={false}>
        <Line />

        <EsgTaxonomyWidget
          euTaxonomyAlignment={data.euTaxonomyAlignment}
          euTaxonomyEligibility={data.euTaxonomyEligibility}
        />
      </Show>

      <Line />

      <Row>
        <Column $span={12}>
          <WithGap direction="row" gap="16">
            <H2>{texts.pages.esg.solutions.title}</H2>

            <Helper
              id="esg-solutions"
              title={texts.pages.esg.solutions.helpTitle}
              description={texts.pages.esg.solutions.helpDescription}
            />
          </WithGap>
        </Column>
      </Row>

      <Row>
        <Column $span={6}>
          <WithGap gap="64">
            <div>
              <Paragraph>{texts.pages.esg.solutions.description}</Paragraph>
              <Paragraph>
                {fmsWithTemplate(texts.pages.esg.solutions.overview, {
                  sustainablePercentage:
                    data.solutionCompanies.kpi.value.toString(),
                  greenPercentage: data.greenBond.kpi.value.toString(),
                })}
              </Paragraph>
            </div>

            <Center horizontal>
              <Gauge
                percent={data.solutionCompanies.kpi.value}
                description={texts.pages.esg.overview.sustainableInvestments}
                size={300}
              />
            </Center>
          </WithGap>
        </Column>

        <Column $span={6}>
          <WithGap>
            <Paragraph>
              {fmsWithTemplate(texts.pages.esg.solutions.dividedInto, {
                sustainablePercentage:
                  data.solutionCompanies.kpi.value.toString(),
              })}
            </Paragraph>

            <EsgSolutionsChart
              greenBond={data.greenBond}
              solutionCompanies={data.solutionCompanies}
              solutionCompaniesEqualOpportunities={
                data.solutionCompaniesEqualOpportunities
              }
              solutionCompaniesRenewableEnergy={
                data.solutionCompaniesRenewableEnergy
              }
              solutionCompaniesCircularEconomy={
                data.solutionCompaniesCircularEconomy
              }
              solutionCompaniesSmartCities={data.solutionCompaniesSmartCities}
              positionLegendBottom
              hideDescription
            />
          </WithGap>
        </Column>
      </Row>

      <Row>
        <Column $span={12}>
          <WithGap>
            <div>
              <StyledHintAsHeader>
                {texts.pages.esg.solutions.companyRenewableEnergy}
              </StyledHintAsHeader>
              <StyledHint>
                {
                  texts.pages.esg.solutions.infoText.companyRenewableEnergy
                    .description
                }
              </StyledHint>
            </div>

            <div>
              <StyledHintAsHeader>
                {texts.pages.esg.solutions.companySmartCities}
              </StyledHintAsHeader>
              <StyledHint>
                {
                  texts.pages.esg.solutions.infoText.companySmartCities
                    .description
                }
              </StyledHint>
            </div>

            <div>
              <StyledHintAsHeader>
                {texts.pages.esg.solutions.companyCircularEconomy}
              </StyledHintAsHeader>
              <StyledHint>
                {
                  texts.pages.esg.solutions.infoText.companyCircularEconomy
                    .description
                }
              </StyledHint>
            </div>

            <div>
              <StyledHintAsHeader>
                {texts.pages.esg.solutions.companyEqualOpportunities}
              </StyledHintAsHeader>
              <StyledHint>
                {
                  texts.pages.esg.solutions.infoText.companyEqualOpportunities
                    .description
                }
              </StyledHint>
            </div>
          </WithGap>
        </Column>
      </Row>

      <Line />

      <WithGap>
        <H2>{texts.pages.esg.exclusions.title}</H2>

        <EsgExclusions />
      </WithGap>
    </WithGap>
  );
};

const StyledHint = styled(Hint)`
  display: block;
`;

const StyledHintAsHeader = styled(Hint)`
  display: block;
  font-weight: bold;
`;
