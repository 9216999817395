import { convertStringToEnum } from './convertStringToEnum';

export const unsafeConvertStringToEnumWithWarning = <
  T extends string,
  TEnum extends string,
>(
  stringValue: string | undefined,
  enumVariable: { [key in T]: TEnum },
): TEnum | undefined => {
  try {
    if (!stringValue) {
      return undefined;
    }

    return convertStringToEnum(stringValue, enumVariable);
  } catch (error) {
    console.warn(error);

    return undefined;
  }
};
