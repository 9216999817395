import React from 'react';
import styled from 'styled-components';

import { useFundEsgQuery } from '@/api/esg/esgApi';
import { LinearGauge } from '@/components/Charts';
import { Documents } from '@/components/FundDocuments/Documents';
import { Column, Row } from '@/components/Grid';
import { PushRight } from '@/components/PushRight';
import {
  H2,
  Hint,
  Paragraph,
  TextShort,
  TextShortBold,
} from '@/components/Typography';
import { SurfaceWidget } from '@/components/Widget';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { useUserLocale } from '@/i18n/hooks';

import { sfdrToFms } from '../utils/sfdrToFms';

interface Props {
  isin: string;
}

export const EsgScore: React.FC<Props> = ({ isin }) => {
  const texts = useTexts();
  const locale = useUserLocale();
  const esgFundQuery = useFundEsgQuery({ isin });

  const sfdrArticleTexts = sfdrToFms(esgFundQuery.data.sfdr, texts);

  return (
    <>
      <H2>{texts.pages.fundEsg.esgScore.title}</H2>

      <Row>
        <Column $span={6}>
          <WithGap gap="48">
            <Paragraph>{texts.pages.esg.esgScore.description}</Paragraph>

            <WithGap gap="16">
              <TextShortBold>{texts.pages.fundEsg.fund.title}</TextShortBold>
              <LinearGauge
                value={
                  esgFundQuery.data.esgScore.kpi.value <= 10
                    ? esgFundQuery.data.esgScore.kpi.value
                    : 10
                }
                max={10}
                color={{
                  color: 'var(--color-green-50)',
                  background: 'var(--color-green-10)',
                }}
                decimals={1}
              />
            </WithGap>

            {esgFundQuery.data.esgScore.benchmark && (
              <WithGap gap="16">
                <TextShortBold>
                  {texts.pages.fundEsg.benchmark.title}
                </TextShortBold>

                <LinearGauge
                  value={
                    esgFundQuery.data.esgScore.benchmark.value <= 10
                      ? esgFundQuery.data.esgScore.benchmark.value
                      : 10
                  }
                  max={10}
                  color={{
                    color: 'var(--color-blue-50)',
                    background: 'var(--color-blue-20)',
                  }}
                  decimals={1}
                />
              </WithGap>
            )}
          </WithGap>
        </Column>

        <Column $span={1}>{/* Empty column used for spacing. */}</Column>

        <Column $span={5}>
          <WithGap>
            <Background>
              <Documents isin={esgFundQuery.data.isin} />
            </Background>

            <SurfaceWidget variant="border-alt">
              <TextShortBold>
                {texts.pages.fundEsg.sfdr.title}{' '}
                <Capitalize>{sfdrArticleTexts.title}</Capitalize>
              </TextShortBold>

              <TextShort>{sfdrArticleTexts.description}</TextShort>
            </SurfaceWidget>
          </WithGap>
        </Column>
      </Row>

      <PushRight>
        <WithGap gap="16" direction="row">
          <Hint>
            {texts.pages.fundEsg.fund.coverage}{' '}
            {esgFundQuery.data.esgScore.kpi.coverageInPercent.toLocaleString(
              locale,
            )}
            %
          </Hint>

          {esgFundQuery.data.esgScore.benchmark && (
            <Hint>
              {texts.pages.fundEsg.benchmark.coverage}{' '}
              {esgFundQuery.data.esgScore.benchmark.coverageInPercent.toLocaleString(
                locale,
              )}
              %
            </Hint>
          )}
        </WithGap>
      </PushRight>
    </>
  );
};

const Background = styled.div`
  padding: 16px;
  background-color: var(--color-surface-variant);
`;

const Capitalize = styled.span`
  text-transform: capitalize;
`;
