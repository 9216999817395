/**
 * Converts a string to the matching enum value for the provided enum variable.
 *
 * @param stringValue String value to convert to enum.
 * @param enumVariable The enum type variable to return.
 *
 * @throws Throws exception if the value does not have a corresponding enum value.
 *
 * @returns Returns the matching enum value.
 */
export const convertStringToEnum = <T extends string, TEnum extends string>(
  stringValue: string,
  enumVariable: { [key in T]: TEnum },
): TEnum => {
  /** Try to get the key index of the TEnum value matching the provided string */
  const enumIndex = Object.values(enumVariable).indexOf(stringValue as TEnum);

  /** If the enum is not found, throw an error */
  if (enumIndex < 0) {
    throw new Error(`${stringValue} is not a valid enum value`);
  }

  /** Get the enum key for the TEnum based on the enum key index */
  const enumKey = Object.keys(enumVariable)[enumIndex];

  /** Return the correct TEnum based on the enum key */
  return enumVariable[enumKey as T];
};
