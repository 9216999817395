import React, { FC } from 'react';

import { Column, Row } from '@/components/Grid';
import { Widget, WidgetHeader } from '@/components/Widget';
import { useTexts } from '@/hooks/useTexts';
import { GeneralContact } from '@/types/contactUs';
import { Country } from '@/types/country';

import { GeneralContactCard } from './GeneralContactCard';

interface Props {
  country: Country;
}

export const GeneralContactsWidget: FC<Props> = ({ country }) => {
  const texts = useTexts();

  const { titleLookup, list } = texts.contacts.general;

  const shouldBeDisplayed = (contact: GeneralContact): boolean =>
    contact.country.toLowerCase() === country.toLowerCase() ||
    contact.country.toLowerCase() === 'all';

  const contactList = list.filter(shouldBeDisplayed);

  const title = texts.contacts.title[titleLookup];

  return (
    <Widget>
      <WidgetHeader title={title} />
      <Row>
        {contactList.map((contact) => {
          const name = contact.nameLookup
            ? texts.contacts.name[contact.nameLookup]
            : contact.name;

          return (
            <Column $span={6} key={name}>
              <GeneralContactCard title={name} email={contact.mail} />
            </Column>
          );
        })}
      </Row>
    </Widget>
  );
};
