import Highcharts, { Options } from 'highcharts';

import { useTexts } from '@/hooks/useTexts';

import { useLocaleShortMonths } from './useLocaleShortMonths';
import { useLocaleWeekdays } from './useLocaleWeekdays';

/**
 * Initialize a Highcharts instance with the specified locale and add all additional modules.
 */
export const useGlobalOptions = (instance: typeof Highcharts): void => {
  const shortMonths = useLocaleShortMonths();
  const weekdays = useLocaleWeekdays();

  const texts = useTexts();

  const globalOptions: Options = {
    lang: {
      shortMonths,
      weekdays,
      noData: texts?.charts?.noData,
      loading: texts?.charts?.loading,
    },
    noData: {
      style: {
        fontFamily: 'Open Sans',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '16px',
      },
    },
    chart: {
      style: {
        fontFamily: 'Open Sans',
      },
      events: {
        load: () => {
          /** This event is very generic in name, and could cause issues if used to trigger event listening on other components  */
          window.dataLayer.push({
            event: 'hover.activate',
          });
        },
      },
    },
  };

  // Set global options for the standard Highcharts graphs.
  instance.setOptions(globalOptions);
};
