import React from 'react';
import styled from 'styled-components';

import { FundPaymentInformation } from '@/api/funds/models/FundPaymentInformation';
import { RenderHtml } from '@/components/RenderHtml';
import { H5 } from '@/components/Typography';
import { useTexts } from '@/hooks/useTexts';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { useUserLocale } from '@/i18n/hooks';
import { getPaymentTitleFromAccountHolder } from '@/pages/Order/utils/getPaymentTitleFromAccountHolder';
import { formatNumber } from '@/util/formatNumber';

interface Props {
  amount: number;
  paymentInfo: FundPaymentInformation;
}

export const ForeignPayment: React.FC<Props> = ({ amount, paymentInfo }) => {
  const texts = useTexts();
  const locale = useUserLocale();

  const title = fmsWithTemplate(
    texts.orders.form.payment.subscription.settlementAccount.info.title
      .withCurrency,
    {
      name: getPaymentTitleFromAccountHolder(paymentInfo.accountHolder, texts),
      currency: paymentInfo.currency,
    },
  );

  const paymentStepsText =
    texts.orders.form.payment.subscription.settlementAccount.info.steps;

  const formattedAmount = formatNumber(amount, locale);

  const html = fmsWithTemplate(paymentStepsText, {
    name: getPaymentTitleFromAccountHolder(paymentInfo.accountHolder, texts),
    amount: formattedAmount,
    currency: paymentInfo.currency,
    bankAccountNumber: paymentInfo.bankAccountNumber,
    iban: paymentInfo.iban,
    swift: paymentInfo.swift,
  });

  return (
    <Container>
      <Header>{title}</Header>

      <RenderHtml html={html} />
    </Container>
  );
};

const Container = styled.div`
  line-height: 24px;
`;

const Header = styled(H5)`
  margin-bottom: 8px;
  font-weight: 600;
`;
