import { parseISO } from 'date-fns';

import { CurrencyCode } from '@/types/CurrencyCode';
import { convertStringToEnum } from '@/util/convertStringToEnum';

import {
  DepositOrderDto,
  FundOrderDto,
  isDepositOrderDto,
  isFundOrderDto,
  isTransferOrderDto,
  isWithdrawOrderDto,
  TransferOrderDto,
  WithdrawalOrderDto,
} from '../dtos/OrderDto';
import { OrderPlacementDto } from '../dtos/OrderPlacementDto';
import {
  DepositOrder,
  FundOrder,
  TransferOrder,
  WithdrawalOrder,
} from '../models/Order';
import { OrderPaymentType } from '../models/OrderPaymentType';
import { OrderPlacement } from '../models/OrderPlacement';
import { OrderPlacementType } from '../models/OrderPlacementType';
import { OrderType } from '../models/OrderType';
import { OrderValueType } from '../models/OrderValueType';
import {
  mapFundOrderTypeDtoToEnum,
  mapOrderTypeDtoToEnum,
} from './mapOrderTypeDtoToEnum';

export function mapOrderDtoToModel(
  orderDto:
    | FundOrderDto
    | DepositOrderDto
    | WithdrawalOrderDto
    | TransferOrderDto,
): FundOrder | DepositOrder | WithdrawalOrder | TransferOrder {
  const orderType = mapOrderTypeDtoToEnum(orderDto.orderType);

  if (isFundOrderDto(orderDto)) {
    const fundOrder: FundOrder = {
      orderType: mapFundOrderTypeDtoToEnum(orderDto.orderType),
      id: orderDto.id,
      createdDate: parseISO(orderDto.created),
      initiatorId: orderDto.userId,
      initiatorName: orderDto.userName,
      portfolioNumber: orderDto.portfolioNumber,
      orderPlacements: orderDto.orderPlacements.map(
        mapOrderPlacementDtoToModel,
      ),
      signatories: orderDto.signatories,
    };

    return fundOrder;
  }

  if (isDepositOrderDto(orderDto)) {
    const deposit: DepositOrder = {
      orderType: OrderType.Deposit,
      id: orderDto.id,
      createdDate: parseISO(orderDto.created),
      initiatorId: orderDto.userId,
      initiatorName: orderDto.userName,
      portfolioNumber: orderDto.portfolioNumber,
      toPortfolioBankAccountNumber: orderDto.toPortfolioBankAccountNumber,
      amount: orderDto.amount,
      currency: convertStringToEnum(orderDto.currencyCode, CurrencyCode),
      expectedDepositDate: parseISO(orderDto.expectedDepositDate),
      messageToSam: orderDto.specifications ?? null,
      signatories: orderDto.signatories,
    };

    return deposit;
  }

  if (isWithdrawOrderDto(orderDto)) {
    const withdrawal: WithdrawalOrder = {
      orderType: OrderType.Withdrawal,
      id: orderDto.id,
      createdDate: parseISO(orderDto.created),
      initiatorId: orderDto.userId,
      initiatorName: orderDto.userName,
      portfolioNumber: orderDto.portfolioNumber,
      fromPortfolioBankAccountNumber: orderDto.fromPortfolioBankAccountNumber,
      externalBankAccount: orderDto.toExternalBankAccountNumber,
      amount: orderDto.amount,
      currency: convertStringToEnum(orderDto.currencyCode, CurrencyCode),
      expectedTransferDate: parseISO(orderDto.expectedTransferDate),
      messageToSam: orderDto.specifications ?? null,
      signatories: orderDto.signatories,
    };

    return withdrawal;
  }

  if (isTransferOrderDto(orderDto)) {
    const transfer: TransferOrder = {
      orderType: OrderType.Transfer,
      id: orderDto.id,
      createdDate: parseISO(orderDto.created),
      initiatorId: orderDto.userId,
      initiatorName: orderDto.userName,
      fromPortfolioId: orderDto.fromPortfolioId,
      fromPortfolioBankAccountNumber: orderDto.fromPortfolioBankAccountNumber,
      fromPortfolioBankAccountCurrency: convertStringToEnum(
        orderDto.currencyCode,
        CurrencyCode,
      ),
      toPortfolioId: orderDto.toPortfolioId,
      toPortfolioBankAccountNumber: orderDto.toPortfolioBankAccountNumber,
      toPortfolioBankAccountCurrency: convertStringToEnum(
        orderDto.currencyCode,
        CurrencyCode,
      ),
      amount: orderDto.amount,
      messageToSam: orderDto.specifications ?? null,

      expectedTransferDate: parseISO(orderDto.expectedTransferDate),
      signatories: orderDto.signatories,
    };

    return transfer;
  }

  throw new Error(
    `Could not map order DTO to model. Unknown order type [${orderType}].`,
  );
}

const mapOrderPlacementDtoToModel = (
  dto: OrderPlacementDto,
): OrderPlacement => {
  return {
    fundIsin: dto.isin,
    hash: dto.hash,
    valueType: convertStringToEnum(dto.valueType, OrderValueType),
    accountId: dto.accountId,
    // Set default currency value to `NOK` for old orders missing currency.
    currency: convertStringToEnum(dto.currency || 'NOK', CurrencyCode),
    externalAccountNumber: dto.externalAccountNumber,
    fundName: dto.fundName,
    id: dto.id,
    isin: dto.isin,
    payment: dto.payment
      ? convertStringToEnum(dto.payment, OrderPaymentType)
      : undefined,
    placementType: convertStringToEnum(dto.placementType, OrderPlacementType),
    value: dto.value,
  };
};
