import Button from '@storeblocks/button';
import React from 'react';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { Column, Row } from '@/components/Grid';
import { InternalLink } from '@/components/InternalLink';
import { MainContent } from '@/components/MainContent';
import { H1 } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { SigningRightBreadcrumb } from './components/SigningRightBreadcrumb';
import { Stepper } from './components/Stepper';

export const SigningRightExpiredPage: React.FC = () => {
  const texts = useTexts();
  const organizationCmId = useOrganizationCmId();
  const getTrackId = useGetTrackId();

  return (
    <>
      <SigningRightBreadcrumb />

      <MainContent data-test="signing-right-signing-expired">
        <Stepper activeStep={2} />
        <H1>{texts.orders.signatureRight.expired.title}</H1>

        <Row>
          {/*
           * Force the text to wrap to make it easier to read,
           * rather than to have it on one long line.
           */}
          <Column $span={6}>
            <p>{texts.orders.signatureRight.expired.description}</p>
          </Column>
        </Row>

        <WithGap gap="24" direction="row">
          <Button
            variant="text"
            iconLeft="arrow-left"
            type="button"
            to={`/${organizationCmId}`}
            as={InternalLink}
            data-trackid={getTrackId('go-to-dashboard', TrackingElement.Link)}
          >
            {texts.orders.signatureRight.backToDashboard}
          </Button>

          <Button
            type="button"
            to={`/${organizationCmId}/trading/signing-right`}
            as={InternalLink}
            data-trackid={getTrackId(
              'go-to-signing-right',
              TrackingElement.Link,
            )}
          >
            {texts.orders.signatureRight.expired.startAgain}
          </Button>
        </WithGap>
      </MainContent>
    </>
  );
};
