import Alert from '@storeblocks/alert';
import Table from '@storeblocks/table';
import React, { useState } from 'react';
import styled from 'styled-components';

import { QueryState } from '@/api/components/QueryState';
import { useDailyTradeReportsQuery } from '@/api/reports/reportsApi';
import { isNotReady } from '@/api/utils/isNotReady';
import { Show } from '@/components/Show';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { useUserDateFormat } from '@/i18n/hooks';
import { downloadFile } from '@/util/downloadFile';

import { SearchParams, Toolbar } from './components/Toolbar';
import { useCheckedFiles } from './hooks/useCheckedFiles';
import { useContractNotesColumns } from './hooks/useContractNotesColumns';
import { filterBySearchParams } from './utils/filterBySearchParams';
import { getContractNotesRowsWithCheckbox } from './utils/getContractNotesRowsWithCheckbox';

export const ContractNotesTableWithToolbar: React.FC = () => {
  const texts = useTexts();
  const dateFormat = useUserDateFormat();

  const dailyTradeReportsQuery = useDailyTradeReportsQuery();

  const [checkedFiles, addRemoveFile] = useCheckedFiles();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    dateISO: '',
    query: '',
  });

  const downloadSingleFile = (fileId: string): Promise<void> => {
    const url = `${window.config.connectBaseApiUrl}/api/reports/wolf/${fileId}`;
    return downloadFile(url);
  };

  const downloadCheckedFiles = async (): Promise<void> => {
    const downloads = checkedFiles.map((fileId) => {
      const url = `${window.config.connectBaseApiUrl}/api/reports/wolf/${fileId}`;
      return downloadFile(url);
    });

    await Promise.all(downloads);
  };

  const onSearchParamsChanged = (newSearchParams: SearchParams): void => {
    setSearchParams(newSearchParams);
  };

  const columns = useContractNotesColumns();

  if (isNotReady(dailyTradeReportsQuery)) {
    return <QueryState query={dailyTradeReportsQuery} />;
  }

  const displayedReports = filterBySearchParams(
    dailyTradeReportsQuery.data || [],
    searchParams,
  );

  const rows = getContractNotesRowsWithCheckbox(
    displayedReports,
    checkedFiles,
    downloadSingleFile,
    addRemoveFile,
    dateFormat,
  );

  return (
    <WithGap gap="32">
      <Toolbar
        numberOfFilesToDownload={checkedFiles.length}
        onChange={onSearchParamsChanged}
        onDownloadClick={downloadCheckedFiles}
      />

      <Show when={rows.length === 0}>
        <Alert
          variant="info"
          data-test="no-contract-notes-alert"
          title={texts.searchResults.noResultsFound}
        />
      </Show>

      <Show when={rows.length > 0}>
        <StyledTable
          columns={columns}
          data={rows}
          data-test="contract-notes-table"
        />
      </Show>
    </WithGap>
  );
};

const StyledTable = styled(Table)`
  table {
    table-layout: unset;
    white-space: nowrap;
  }

  & tr td:nth-of-type(2) {
    width: 100%;
  }
`;
