import React from 'react';
import { styled } from 'styled-components';

import { usePortfoliosQuery } from '@/api/portfolios';
import { useTexts } from '@/hooks/useTexts';

import { TableRowCell } from './TableRowCell';
import { TableRowHeader } from './TableRowHeader';

interface Props {
  portfolioShortName: string;
}

export const PortfolioRow: React.FC<Props> = ({ portfolioShortName }) => {
  const { orders } = useTexts();
  const portfoliosQuery = usePortfoliosQuery();

  const portfolio = portfoliosQuery.data?.portfolioById.get(portfolioShortName);

  if (!portfolio) {
    throw new Error(
      `Could not find portfolio for portfolio ID [${portfolioShortName}].`,
    );
  }

  return (
    <tr>
      <TableRowHeader>{orders.form.table.portfolio}</TableRowHeader>
      <Cell colSpan={2}>
        {portfolio.id} - {portfolio.name}
      </Cell>
    </tr>
  );
};

const Cell = styled(TableRowCell)`
  text-align: right;
`;
