import { useFormikContext } from 'formik';
import React from 'react';

import { PaymentAccountHolder } from '@/api/funds/models/PaymentAccountHolder';
import { OrderPaymentType } from '@/api/order/models/OrderPaymentType';
import { OrderType } from '@/api/order/models/OrderType';
import { useTexts } from '@/hooks/useTexts';
import { getSubscriptionPaymentTitle } from '@/pages/Order/utils/getSubscriptionPaymentTitle';
import { CurrencyCode } from '@/types/CurrencyCode';

import { useFundPortfolios } from '../../../hooks/useFundPortfolios';
import { SubscriptionOrderFormValues } from '../../../types/SubscriptionOrderFormValues';
import { createCompositeKey } from '../../../utils/createCompositeKey';
import { getPortfolioBankAccounts } from '../../../utils/getPortfolioBankAccounts';
import { PlacementRadio } from '../../PlacementRadio';

interface Props {
  paymentIndex: number;
  accountHolder: PaymentAccountHolder;
  currency: CurrencyCode;
  displayCurrencyInTitle: boolean;
}

export const SubscriptionPaymentRadio: React.FC<Props> = ({
  paymentIndex,
  accountHolder,
  currency,
  displayCurrencyInTitle,
}) => {
  const texts = useTexts();
  const title = getSubscriptionPaymentTitle(accountHolder, currency, texts, {
    displayCurrencyInTitle,
  });

  const formContext = useFormikContext<SubscriptionOrderFormValues>();

  const portfolios = useFundPortfolios(OrderType.Subscription);

  const portfolioBankAccounts = getPortfolioBankAccounts(
    formContext.values.toPortfolioShortName,
    portfolios,
  );

  const hasMultiplePortfolioBankAccounts = portfolioBankAccounts.length > 1;

  const options = [
    {
      id: `${OrderPaymentType.Portfolio}-${createCompositeKey(accountHolder, currency)}`,
      value: OrderPaymentType.Portfolio,
      label: texts.orders.form.payment.options.subscription.portfolioAccount,
    },
    {
      id: `${OrderPaymentType.BankAccount}-${createCompositeKey(accountHolder, currency)}`,
      value: OrderPaymentType.BankAccount,
      label: texts.orders.form.payment.options.subscription.settlementAccount,
    },
  ];

  /**
   * Explicitly handle the change of payment type when the portfolio has
   * only one portfolio bank account.
   */
  const handlePaymentTypeChange = (paymentType: OrderPaymentType): void => {
    if (hasMultiplePortfolioBankAccounts) {
      // Nothing to do when the portfolio has multiple bank accounts.
      return;
    }
    // -> The portfolio has only one bank account.

    if (paymentType === OrderPaymentType.Portfolio) {
      // When the portfolio has only one bank account, we can set the value
      // directly without the user having to choose through a select list.
      formContext.setFieldValue(
        `payments[${paymentIndex}].portfolioBankAccountNumber`,
        portfolioBankAccounts[0].accountNumber,
      );
    } else {
      // Reset the value when the user chooses another payment type.
      formContext.setFieldValue(
        `payments[${paymentIndex}].portfolioBankAccountNumber`,
        '',
      );
    }
  };

  return (
    <PlacementRadio
      title={title}
      options={options}
      paymentIndex={paymentIndex}
      onChange={handlePaymentTypeChange}
    />
  );
};
