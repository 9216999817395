import Alert from '@storeblocks/alert';
import LoadingIndicator from '@storeblocks/loading-indicator';
import React from 'react';

import { useTexts } from '@/hooks/useTexts';

import { EnterSomeSearchCriteriaAlert } from './EnterSomeSearchCriteriaAlert';
import { ReportsTable } from './Table/ReportsTable';
import { Report } from './types/ReportModel';

interface Props {
  reports: Report[];
  isPending?: boolean;
  isError?: boolean;
  isSearchParamsEmpty?: boolean;
}

export const Content: React.FC<Props> = ({
  reports,
  isPending,
  isError,
  isSearchParamsEmpty,
}) => {
  const texts = useTexts();

  if (isError) {
    return (
      <Alert
        variant="error"
        title={texts.reportsArchivePage.serverError}
        data-test="error-alert"
      />
    );
  }

  if (isPending) {
    return <LoadingIndicator />;
  }

  if (reports.length === 0) {
    return (
      <Alert
        variant="info"
        title={texts.searchResults.noResultsFound}
        data-test="no-result-alert"
      />
    );
  }

  return (
    <>
      <ReportsTable reports={reports} />

      {isSearchParamsEmpty && (
        <EnterSomeSearchCriteriaAlert
          variant="info"
          title={texts.reportsArchivePage.enterSomeCriteria}
        />
      )}
    </>
  );
};
