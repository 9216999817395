import Radio, { Option } from '@storeblocks/radio';
import { useField } from 'formik';
import React from 'react';

import { useTexts } from '@/hooks/useTexts';
import { nameof } from '@/util/nameof';

import { FormValues } from '../types/FormValues';
import { PoliticallyExposedPerson } from '../types/PoliticallyExposedPerson';

export const Pep: React.FC = () => {
  const texts = useTexts();

  const [field, meta, helpers] = useField<PoliticallyExposedPerson>(
    nameof<FormValues>('pep'),
  );

  const pepOptions: Array<Option> = [
    {
      id: PoliticallyExposedPerson.No,
      value: PoliticallyExposedPerson.No,
      label: texts.orders.signatureRight.pep.no,
    },
    {
      id: PoliticallyExposedPerson.Yes,
      value: PoliticallyExposedPerson.Yes,
      label: texts.orders.signatureRight.pep.yes,
    },
  ];

  return (
    <Radio
      {...field}
      id="pep-radio"
      data-test="pep-radio"
      onChange={(e) =>
        helpers.setValue(e.target.value as PoliticallyExposedPerson)
      }
      value={field.value}
      label={texts.orders.signatureRight.pep.title}
      hint={texts.orders.signatureRight.pep.description}
      options={pepOptions}
      error={meta.touched && meta.error ? meta.error : null}
    />
  );
};
