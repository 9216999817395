import Button from '@storeblocks/button';
import { Illustration } from '@storeblocks/icon';
import React from 'react';
import styled from 'styled-components';

import { getAuth } from '@/auth/Auth';
import { appConfig } from '@/constants/config';
import { useTexts } from '@/hooks/useTexts';

import { Divider } from './Divider';
import { Column, Row } from './Grid';
import { PageHeading } from './PageHeading';
import { H2, Ingress } from './Typography';
import { WithGap } from './WithGap';

export const NoAccessExternalUser: React.FC = () => {
  const texts = useTexts();

  return (
    <Container>
      <Center>
        <Page>
          <PageHeading header={texts.error.description.orgs.title} />

          <Divider margin="8" />

          <StyledRow>
            <Column $span={6}>
              <StyledWithGap gap="64">
                <Ingress>
                  {texts.error.description.orgs.part1}{' '}
                  <a href={`mailto:${texts.error.siosEmail}`}>
                    {texts.error.siosEmail}
                  </a>{' '}
                  {texts.error.description.orgs.part2}
                </Ingress>

                <WithGap>
                  <WithGap gap="8">
                    <H2>{texts.error.privateCustomer.title}</H2>
                    <div>{texts.error.privateCustomer.description}</div>
                  </WithGap>

                  <WithGap gap="16">
                    <div>
                      <Button
                        variant="outlined"
                        iconRight="arrow-right"
                        as="a"
                        href={texts.error.privateCustomer.kron.url}
                      >
                        {texts.error.privateCustomer.kron.title}
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="outlined"
                        iconRight="arrow-right"
                        as="a"
                        href={texts.error.privateCustomer.storebrand.url}
                      >
                        {texts.error.privateCustomer.storebrand.title}
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="outlined"
                        iconRight="arrow-right"
                        as="a"
                        href={texts.error.privateCustomer.skagen.url}
                      >
                        {texts.error.privateCustomer.skagen.title}
                      </Button>
                    </div>
                  </WithGap>
                </WithGap>
                <WithGap gap="8">
                  <div>
                    <Button
                      variant="text"
                      iconLeft="arrow-left"
                      as="a"
                      href={texts.error.privateCustomer.back.url}
                    >
                      {texts.error.privateCustomer.back.title}
                    </Button>
                  </div>
                  <div>
                    <Button
                      onClick={() => getAuth().logout()}
                      iconLeft="arrow-left"
                      variant="text"
                    >
                      {texts.error.buttons.logOut}
                    </Button>
                  </div>
                </WithGap>
              </StyledWithGap>
            </Column>
            <StyledColumn $span={6}>
              <Illustration name="something-went-wrong" height="500" />
            </StyledColumn>
          </StyledRow>
        </Page>
      </Center>
    </Container>
  );
};

const Container = styled.div`
  border-top: 3px solid var(--color-brand);
`;

const Center = styled.div`
  max-width: ${appConfig.maxPageWidth};
  margin: 0 auto;
`;

const Page = styled.div`
  margin-top: 72px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 24px;
`;

const StyledRow = styled(Row)`
  margin-top: 8px;
`;

const StyledColumn = styled(Column)`
  display: flex;
  justify-content: center;
`;

const StyledWithGap = styled(WithGap)`
  align-items: start;
`;
