import React, { useMemo } from 'react';

import { PortfolioPerformanceData } from '@/api/performanceData/models/PortfolioPerformanceData';
import {
  LineChart,
  LineChartData,
  LineChartDataSeries,
} from '@/components/Charts/LineChart';
import { useTexts } from '@/hooks/useTexts';
import { Unit } from '@/types/Unit';

interface Props {
  data: PortfolioPerformanceData;
  unit: Unit;
  height?: number;
  trackId?: string;
}

export const PortfolioPerformanceGraph: React.FC<Props> = ({
  data,
  unit,
  height,
  trackId,
}) => {
  const texts = useTexts();

  const lineChartData = useMemo<LineChartData>(() => {
    if (!data) {
      return [];
    }

    const series: LineChartDataSeries[] = [];

    const dailyReturn: LineChartDataSeries = {
      name: texts.widgets.portfolioPerformance.graph.title,
      data: data.dailyReturnAccumulated,
      lineWidth: 2,
    };
    series.push(dailyReturn);

    const isBenchmarkZero = data.benchmarkReturnAccumulated.every(
      (point) => point[1] === 0,
    );

    if (!isBenchmarkZero) {
      // There is no point in displaying the benchmark if it is just zero
      // across the timeline. Since the excess is the difference between
      // the benchmark and the daily return, it will not provide any
      // customer value when the benchmark is zero.

      const benchmark: LineChartDataSeries = {
        name: texts.widgets.portfolioPerformance.graph.index,
        data: data.benchmarkReturnAccumulated,
      };
      series.push(benchmark);

      const excess: LineChartDataSeries = {
        name: texts.widgets.portfolioPerformance.graph.excessReturn,
        data: data.excessReturn,
      };
      series.push(excess);
    }

    return series;
  }, [data, unit, texts]);

  return (
    <LineChart
      data={lineChartData}
      unit={unit}
      height={height}
      trackId={trackId}
    />
  );
};
