import Button from '@storeblocks/button';
import React from 'react';
import styled from 'styled-components';

import { PendingOrder } from '@/api/order/models/PendingOrder';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { InternalLink } from '../InternalLink';
import { createLinkToOrder } from './utils/createLinkToOrder';

interface Props {
  pendingOrder: PendingOrder;
}

export const GoToSigningButton: React.FC<Props> = ({ pendingOrder }) => {
  const getTrackId = useGetTrackId();
  const organizationCmId = useOrganizationCmId();
  const texts = useTexts();

  const linkToOrderPage = createLinkToOrder(
    pendingOrder.orderId,
    organizationCmId,
    pendingOrder.orderType,
  );

  return (
    <ButtonContainer>
      <Button
        as={InternalLink}
        to={linkToOrderPage}
        variant="text"
        iconRight="arrow-right"
        data-test="go-to-signing"
        data-trackid={getTrackId('order-to-sign', TrackingElement.Link)}
        data-trackid-source="order-to-sign-alert"
      >
        {texts.pendingOrders.proceedToSigning}
      </Button>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.div`
  padding-top: 12px;
  padding-left: 40px;
`;
