import Surface from '@storeblocks/surface';
import React, { FC } from 'react';
import styled from 'styled-components';

import { Column, Row } from '@/components/Grid';
import { H3, Paragraph } from '@/components/Typography';
import { useTexts } from '@/hooks/useTexts';

export const EsgTaxonomyDescriptionWidget: FC = () => {
  const texts = useTexts();
  const { paragraph1, paragraph2, paragraph3, goals } =
    texts.pages.esg.taxonomy.details;

  return (
    <Row>
      <Column $span={6}>
        <ParagraphContainer>
          {paragraph1 && <Paragraph>{paragraph1}</Paragraph>}
          {paragraph2 && <Paragraph>{paragraph2}</Paragraph>}
          {paragraph3 && <Paragraph>{paragraph3}</Paragraph>}
        </ParagraphContainer>
      </Column>

      <Column $span={6}>
        <Surface variant="alt">
          <H3>{goals.title}</H3>
          <GoalList>
            <GoalListItem>{goals.mitigation}</GoalListItem>
            <GoalListItem>{goals.adaptation}</GoalListItem>
            <GoalListItem>{goals.marine}</GoalListItem>
            <GoalListItem>{goals.circular}</GoalListItem>
            <GoalListItem>{goals.pollution}</GoalListItem>
            <GoalListItem>{goals.protection}</GoalListItem>
          </GoalList>
        </Surface>
      </Column>
    </Row>
  );
};

const ParagraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const GoalList = styled.ol`
  font-family: Open Sans;
  font-size: 16px;
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  & li:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const GoalListItem = styled.li`
  padding-bottom: 8px;
  border-bottom: 1px solid var(--color-border);
`;
