import Surface from '@storeblocks/surface';
import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Divider } from '@/components/Divider';
import { Column, Row } from '@/components/Grid';
import { MainContent } from '@/components/MainContent';
import { H1, Hint, Ingress, textShortBoldStyle } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';

import { OpenPrintDialogButton } from './buttons/OpenPrintDialogButton';
import { CenteredPageContent } from './components/CenteredPageContent';
import { usePresentationContext } from './PresentationContext';
import { a4MaxWidthInPixels } from './utils/a4MaxWidthInPixels';

export const ReportPrintPage: React.FC = () => {
  const texts = useTexts();
  const { selectedScreens, setIsPrintPreviewPage } = usePresentationContext();

  useEffect(() => {
    setIsPrintPreviewPage(true);

    return () => {
      setIsPrintPreviewPage(false);
    };
  }, []);

  return (
    <CenteredPageContent data-test="pdf-view-page">
      <MainContent>
        <HideInPrint>
          <H1>{texts.pages.presentation.reportPreview.title}</H1>

          <WithGap>
            <Ingress>
              {texts.pages.presentation.reportPreview.description}
            </Ingress>

            <Row>
              <Column $span={8}>
                <StyledSurface>
                  <Title>
                    {texts.pages.presentation.reportPreview.hintTitle}
                  </Title>
                  <div>
                    <Hint>{texts.pages.presentation.reportPreview.hint1}</Hint>
                  </div>
                  <div>
                    <Hint>{texts.pages.presentation.reportPreview.hint2}</Hint>
                  </div>
                </StyledSurface>
              </Column>

              <ButtonColumn $span={4}>
                <OpenPrintDialogButton />
              </ButtonColumn>
            </Row>
          </WithGap>
          <Divider />
        </HideInPrint>

        <ScreensContainer>
          {selectedScreens.map((selectedScreen) => (
            <div key={selectedScreen.id}>
              {selectedScreen.element}
              <HideInPrint>
                <Divider />
              </HideInPrint>
            </div>
          ))}
        </ScreensContainer>
      </MainContent>
    </CenteredPageContent>
  );
};

const StyledSurface = styled(Surface)`
  line-height: 24px;
  padding: 24px;
  height: 100%;
`;

const HideInPrint = styled.div`
  @media print {
    display: none;
  }
`;

const ButtonColumn = styled(Column)`
  display: flex;
  justify-content: flex-end;
  align-items: end;
  gap: 16px;
`;

const Title = styled.div`
  ${textShortBoldStyle}
  margin-bottom: 8px;
`;

const ScreensContainer = styled.div`
  // Set the max-width to ensure that the screen fits an A4 page.
  // This will also ensure the Highcharts charts is rendered to
  // the correct width. Otherwise, the chart will be rendered too
  // wide and will be cut off when printed.
  ${a4MaxWidthInPixels}

  > div:not(:last-child) {
    @media print {
      page-break-after: always;
    }

    @media screen {
      margin-bottom: 128px;
    }
  }
`;
