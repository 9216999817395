import Button from '@storeblocks/button';
import React from 'react';

import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';

interface Props {
  onClick: () => void;
}

export const AddDualCitizenshipButton: React.FC<Props> = ({ onClick }) => {
  const texts = useTexts();
  const getTrackId = useGetTrackId();

  return (
    <div>
      <Button
        data-test="add-dual-citizenship"
        variant="text"
        iconLeft="add"
        onClick={onClick}
        data-trackid={getTrackId(
          'add-dual-citizenship',
          TrackingElement.Button,
        )}
      >
        {texts.orders.signatureRight.pep.citizenship.addDual}
      </Button>
    </div>
  );
};
