import Alert from '@storeblocks/alert';
import Button from '@storeblocks/button';
import Modal, { Content, Footer, Heading } from '@storeblocks/modal';
import { Option } from '@storeblocks/select';
import { AxiosError, AxiosProgressEvent, CancelTokenSource } from 'axios';
import { Form, Formik } from 'formik';
import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  useUpdateDocumentFileMutation,
  useUploadDocumentFileMutation,
} from '@/api/documentArchive/documentArchiveApi';
import { DocumentCategory } from '@/api/documentArchive/models/DocumentCategory';
import { FormInput } from '@/components/Form/FormInput';
import { FormSelect } from '@/components/Form/FormSelect';
import { ProgressBar } from '@/components/ProgressBar';
import { Show } from '@/components/Show';
import { useKeyPress } from '@/hooks/useKeyPress';
import { useTexts } from '@/hooks/useTexts';
import { useDocumentsArchiveContext } from '@/pages/Archives/DocumentsArchive/DocumentsArchiveContext';
import { FileSelector } from '@/pages/Archives/DocumentsArchive/FileSelector';
import { FileValidationErrorAlert } from '@/pages/Archives/DocumentsArchive/FileValidationErrorAlert';
import { TrackingElement, useGetTrackId } from '@/tracking';
import {
  FileValidationError,
  getFileExtension,
  getFilenameWithoutPathAndExtension,
  isValidUploadFile,
} from '@/util/FileHelpers';

import { FormRow } from '../Form/FormRow';

interface Props {
  categories: Option[];
}

type DocumentCategoryValues = DocumentCategory | '';

interface FormValues {
  name: string;
  category: DocumentCategoryValues;
}

interface FileInformationProps {
  setFile: (value: React.SetStateAction<File | null>) => void;
  fileUploadProgress: number;
  fileIsUploading: boolean;
  fileName?: string;
  deleteFileText: string;
}

const formValues: FormValues = { name: '', category: '' };

export const UploadFileModal: FC<Props> = ({ categories }) => {
  const [progress, setProgress] = useState<number>(0);
  const [cancelToken, setCancelToken] = useState<CancelTokenSource>();
  const [file, setFile] = useState<File | null>(null);
  const [fileValidationError, setFileValidationError] = useState<
    FileValidationError | undefined
  >();

  const texts = useTexts();
  const getTrackId = useGetTrackId();

  const { showUploadFileModal, setShowUploadFileModal } =
    useDocumentsArchiveContext();

  const [uploadDocumentFile, uploadDocumentFileRequest] =
    useUploadDocumentFileMutation();

  const [updateDocumentFile, updateDocumentFileRequest] =
    useUpdateDocumentFileMutation();

  /** Callback for reporting progress in percentage */
  const onProgress = (event: AxiosProgressEvent): void => {
    const percentage = (event.loaded / event.total) * 100;
    setProgress(percentage);
  };

  /** Callback for canceling the upload */
  const uploadDocumentFileCancel = useCallback(() => {
    cancelToken?.cancel();
  }, [cancelToken]);

  const isUploading =
    uploadDocumentFileRequest.isLoading || updateDocumentFileRequest.isLoading;

  useEffect(() => {
    if (!showUploadFileModal) {
      setFile(null);
      setFileValidationError(undefined);
    }
  }, [showUploadFileModal, setFile, setFileValidationError]);

  useKeyPress(() => {
    onCancel();
  }, ['Escape']);

  const giveNameInputFocus = (): void => {
    const input: HTMLInputElement | null = document.querySelector('#name');
    input?.focus();
    input?.select();
  };

  const handleFileSelect = (
    selectedFile: File,
    setFieldValue,
    values: FormValues,
  ): void => {
    setFileValidationError(undefined);
    const validationResults = isValidUploadFile(selectedFile);
    if (!validationResults.valid) {
      setFileValidationError(validationResults.error);
    } else {
      setFile(selectedFile);
      if (!values.name) {
        setFieldValue(
          'name',
          getFilenameWithoutPathAndExtension(selectedFile.name),
        );
      }
    }
  };

  const handleUpload = async ({
    name,
    category,
  }: FormValues): Promise<void> => {
    uploadDocumentFileRequest.reset();

    if (!isUploading && file) {
      const documentFile = await uploadDocumentFile({
        file,
        onProgress,
        onReceivedCancelToken: setCancelToken,
      }).unwrap();

      // Update the meta data of the uploaded file if the filename or
      // category is changed.
      if (name !== documentFile.name || category !== documentFile.category) {
        await updateDocumentFile({
          fileId: documentFile.id,
          fileName: `${name || documentFile.name}.${getFileExtension(
            file.name,
          )}`,
          category: category || documentFile.category,
        });
      }

      setShowUploadFileModal(false);
    }
  };

  const onCancel = (): void => {
    setShowUploadFileModal(false);
    uploadDocumentFileCancel();
  };

  return (
    <Show when={showUploadFileModal}>
      <Formik
        onReset={giveNameInputFocus}
        onSubmit={handleUpload}
        initialValues={formValues}
        enableReinitialize
        render={({ setFieldValue, values }) => (
          <Form id="upload-file-form">
            <Modal
              trigger={undefined}
              open={showUploadFileModal}
              onClose={onCancel}
            >
              <Heading>{texts.upload.field.placeholder}</Heading>
              <Content data-test="upload-file-modal">
                <FormRow>
                  <FormInput
                    fluid
                    id="name"
                    name="name"
                    label={texts.documentsArchivePage.uploadFileModal.name}
                    value={values.name}
                  />
                  <FormSelect
                    name="category"
                    label={texts.category.text}
                    options={[
                      {
                        label: `${texts.documentsArchivePage.chooseCategory}...`,
                        value: '',
                      },
                      ...categories,
                    ]}
                    fluid
                  />
                </FormRow>

                <FormRow>
                  <Show when={!!file}>
                    <FileInformation
                      setFile={setFile}
                      fileUploadProgress={progress}
                      fileIsUploading={isUploading}
                      fileName={file?.name}
                      deleteFileText={texts.iHub.delete.button}
                    />
                  </Show>
                  <Show when={fileValidationError !== undefined}>
                    <p>
                      <FileValidationErrorAlert error={fileValidationError} />
                    </p>
                  </Show>
                </FormRow>
                {uploadDocumentFileRequest.isError && (
                  <Alert
                    variant="error"
                    title={
                      (uploadDocumentFileRequest.error as AxiosError)?.message
                    }
                  />
                )}
              </Content>
              <Footer>
                <Show when={!file} key="show-when-no-file">
                  <FileSelector
                    onFileSelect={(selectedFile) =>
                      handleFileSelect(selectedFile, setFieldValue, values)
                    }
                  />
                </Show>
                <Show when={!!file} key="show-when-file">
                  <Button
                    type="submit"
                    disabled={isUploading}
                    form="upload-file-form"
                    mr="8px"
                    data-trackid={getTrackId(
                      'upload:upload-file-modal',
                      TrackingElement.Button,
                    )}
                    data-test="upload-file-modal-submit"
                  >
                    {texts.documentsArchivePage.upload}
                  </Button>
                </Show>
                <Button
                  variant="outlined"
                  type="button"
                  id="upload-modal-cancel-button"
                  onClick={onCancel}
                  key="modal-cancel"
                  data-trackid={getTrackId(
                    'cancel:upload-file-modal',
                    TrackingElement.Button,
                  )}
                >
                  {texts.admin.cancel}
                </Button>
              </Footer>
            </Modal>
          </Form>
        )}
      />
    </Show>
  );
};

const FileInformation: FC<FileInformationProps> = ({
  setFile,
  fileUploadProgress,
  fileIsUploading,
  fileName,
  deleteFileText,
}) => {
  const getTrackId = useGetTrackId();

  return (
    <>
      <Show when={fileIsUploading}>
        <ProgressBarWrapper>
          <ProgressBar progressInPercent={fileUploadProgress} />
        </ProgressBarWrapper>
      </Show>

      <span id="remove-upload-file">
        {fileName}
        <Show when={!fileIsUploading}>
          <Button
            ml="16px"
            variant="text"
            iconLeft="delete"
            onClick={() => {
              setFile(null);
            }}
            data-trackid={getTrackId(
              'remove-uploaded-file:upload-file-modal',
              TrackingElement.Link,
            )}
          >
            {deleteFileText}
          </Button>
        </Show>
      </span>
    </>
  );
};

const ProgressBarWrapper = styled.div`
  position: relative;
  width: 100%;
  div {
    position: absolute;
    top: -3px;
    width: 100%;
  }
  span {
    padding: 5px;
  }
`;
