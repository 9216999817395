import React, { useState } from 'react';

import { useFundEsgQuery } from '@/api/esg/esgApi';
import { Center } from '@/components/Center';
import { Column, Row } from '@/components/Grid';
import { Helper } from '@/components/Helper';
import { PushRight } from '@/components/PushRight';
import { H2, Hint, Paragraph, TextShortBold } from '@/components/Typography';
import { KeyStatistics3 } from '@/components/Typography/KeyStatistic3';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { useUserLocale } from '@/i18n/hooks';
import { CurrencyCode } from '@/types/CurrencyCode';

import { CarbonIntensityChart } from '../components/CarbonIntensityChart';
import { CurrencyChips } from '../components/CurrencyChips';
import { LargeText } from '../components/LargeText';
import { SpaceBetween } from '../components/SpaceBetween';

interface Props {
  isin: string;
}

export const EsgCarbonIntensities: React.FC<Props> = ({ isin }) => {
  const texts = useTexts();
  const esgFundQuery = useFundEsgQuery({ isin });
  const locale = useUserLocale();

  const [currency, setCarbonIntensityCurrency] = useState<CurrencyCode>(
    esgFundQuery.data.carbonIntensities[0]?.currency,
  );

  const carbonIntensity = esgFundQuery.data.carbonIntensities.find(
    (carbon) => carbon.currency === currency,
  );

  return (
    <>
      <SpaceBetween>
        <Center vertical>
          <H2>{texts.pages.fundEsg.carbonIntensities.title}</H2>

          {texts.pages.fundEsg.carbonIntensities.helpDescription && (
            <Helper
              id="fund-esg-carbon-intensities"
              description={
                texts.pages.fundEsg.carbonIntensities.helpDescription
              }
            />
          )}
        </Center>

        <WithGap gap="8">
          <TextShortBold>
            {texts.pages.fundEsg.carbonIntensities.currency}
          </TextShortBold>

          <CurrencyChips
            currencies={esgFundQuery.data.carbonIntensities.map(
              (carbon) => carbon.currency,
            )}
            currency={currency}
            onChange={(currency) => {
              setCarbonIntensityCurrency(currency);
            }}
          />
        </WithGap>
      </SpaceBetween>

      <Row>
        <Column $span={6}>
          <WithGap gap="16">
            <LargeText>{`${carbonIntensity?.kpi.value} ${texts.pages.esg.carbonFootprint.tonnage}`}</LargeText>

            <KeyStatistics3>{`CO2e/M${currency}`}</KeyStatistics3>

            <Paragraph>
              {fmsWithTemplate(
                texts.pages.fundEsg.carbonIntensities.description,
                {
                  currency,
                },
              )}
            </Paragraph>
          </WithGap>
        </Column>

        <Column $span={6}>
          {carbonIntensity?.benchmark && (
            <CarbonIntensityChart
              fundValue={carbonIntensity?.kpi.value}
              benchmarkValue={carbonIntensity?.benchmark.value}
            />
          )}
        </Column>
      </Row>

      <PushRight>
        <WithGap gap="16" direction="row">
          <Hint>
            {texts.pages.fundEsg.fund.coverage}{' '}
            {carbonIntensity?.kpi.coverageInPercent.toLocaleString(locale)}%
          </Hint>

          {carbonIntensity?.benchmark && (
            <Hint>
              {texts.pages.fundEsg.benchmark.coverage}{' '}
              {carbonIntensity.benchmark.coverageInPercent.toLocaleString(
                locale,
              )}
              %
            </Hint>
          )}
        </WithGap>
      </PushRight>
    </>
  );
};
