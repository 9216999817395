import Select, { Option } from '@storeblocks/select';
import React from 'react';

import { usePortfoliosQuery } from '@/api/portfolios';
import { useTexts } from '@/hooks/useTexts';

/**
 * Value for the "All portfolios" option in the select input.
 */
export const allPortfoliosKey = 'ALL-PORTFOLIOS';

interface Props {
  selectedPortfolioGroupId: string;
  onChange: (portfolioGroupId: string) => void;
  withAllOption?: boolean;
}

/**
 * Display a select input to choose a portfolio group.
 */
export const PortfolioGroupSelect: React.FC<Props> = ({
  selectedPortfolioGroupId,
  onChange,
  withAllOption,
}) => {
  const texts = useTexts();
  const { data } = usePortfoliosQuery();

  const options = data.portfolioGroups.map<Option>((portfolioGroup) => ({
    value: portfolioGroup.id,
    label: `${portfolioGroup.id} - ${portfolioGroup.name}`,
  }));

  if (withAllOption) {
    options.unshift({
      value: allPortfoliosKey,
      label: texts.portfolioSelector.all.title,
    });
  }

  return (
    <Select
      name="portfolio-group-select"
      id="portfolio-group-select"
      data-test="portfolio-group-select"
      label={texts.portfolioSelector.title}
      options={options}
      fluid
      value={selectedPortfolioGroupId}
      onChange={(event) => onChange(event.target.value)}
    />
  );
};
