import Breadcrumbs from '@storeblocks/breadcrumbs';
import React, { FC } from 'react';

import { OrderType } from '@/api/order/models/OrderType';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { InternalLink } from '@/components/InternalLink';
import { MainContent } from '@/components/MainContent';
import { PageHeading } from '@/components/PageHeading';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';

import { OrderSteps } from './components/OrderSteps';
import { useOrderPageTitle } from './hooks/useOrderPageTitle';
import { OrderPageProvider } from './OrderPageContext';
import { RequireSigningRight } from './steps/OrderDetails/components/RequireSigningRight';
import { getOrderPagePath } from './utils/getOrderPagePath';

interface Props {
  orderType: OrderType;
}

export const OrderPage: FC<Props> = ({ orderType }) => {
  const organizationCmId = useOrganizationCmId();
  const texts = useTexts();

  const pageTitle = useOrderPageTitle(orderType);
  const pagePath = getOrderPagePath(orderType);

  return (
    <RequireSigningRight>
      <OrderPageProvider orderType={orderType}>
        <WithGap>
          <Breadcrumbs>
            <InternalLink to={`/${organizationCmId}`}>
              {texts.menu.links.home}
            </InternalLink>
            <InternalLink to={`/${organizationCmId}/trading`}>
              {texts.menu.links.trading.trading}
            </InternalLink>
            <InternalLink to={`/${organizationCmId}/trading/${pagePath}`}>
              {pageTitle}
            </InternalLink>
          </Breadcrumbs>

          <MainContent>
            <PageHeading header={pageTitle} />

            <OrderSteps />
          </MainContent>
        </WithGap>
      </OrderPageProvider>
    </RequireSigningRight>
  );
};
