import { SystemIcon } from '@storeblocks/icon';
import InlineAlert from '@storeblocks/inline-alert';
import LoadingIndicator from '@storeblocks/loading-indicator';
import React from 'react';
import styled from 'styled-components';

import { Show } from '@/components/Show';
import { TextShort } from '@/components/Typography';
import { useHandleAsyncOperation } from '@/hooks/useHandleAsyncError';
import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';

interface Props {
  filename: string;
  fileId: string;
  onDownload: (fileId: string) => Promise<unknown>;
}

export const ReportListItem: React.FC<Props> = ({
  filename,
  fileId,
  onDownload,
}: Props) => {
  const texts = useTexts();
  const getTrackId = useGetTrackId();

  const startDownload = async (): Promise<void> => {
    await onDownload(fileId);
  };

  const [handleDownloadOnClick, isDownloading, isError] =
    useHandleAsyncOperation(startDownload);

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLLIElement>): void => {
    if (e.key === 'Enter') {
      handleDownloadOnClick();
    }
  };

  return (
    <ListItem
      onClick={handleDownloadOnClick}
      onKeyDown={handleOnKeyDown}
      data-trackid={getTrackId('download-file', TrackingElement.Link)}
      data-trackid-fileid={fileId}
      tabIndex={0}
    >
      <LinkText>{filename}</LinkText>

      <Show when={isError}>
        <InlineAlert variant="error" title={texts.download.singleFileError} />
      </Show>

      <Show when={isDownloading}>
        <SpinnerWrapper>
          <LoadingIndicator size="small" />
        </SpinnerWrapper>
      </Show>

      <Show when={!isError && !isDownloading}>
        <SystemIcon name="download" />
      </Show>
    </ListItem>
  );
};

const ListItem = styled.li`
  cursor: pointer;
  border-bottom: 1px solid var(--color-border);
  padding: 16px 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LinkText = styled(TextShort)`
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
`;

const SpinnerWrapper = styled.div`
  justify-content: flex-start;
  align-self: center;
`;
