import { Locale } from '@/i18n/locale';

interface FormatNumberOptions extends Intl.NumberFormatOptions {
  minimumFractionDigits?: number;
}

/**
 * Convert a number to the specified locale.
 *
 * @example
 * Without suffix:
 * 2109068 -> '2 109 068'
 *
 * With suffix:
 * 2109068 -> '2 109 068 NOK'
 */
export const formatNumber = (
  value: number,
  locale: Locale,
  suffix?: string,
  options: FormatNumberOptions = {},
): string => {
  const formatter = new Intl.NumberFormat(locale, {
    maximumFractionDigits: 4,
    ...options,
  });

  if (suffix) {
    return `${formatter.format(value)} ${suffix}`;
  }

  return `${formatter.format(value)}`;
};
