import Button from '@storeblocks/button';
import Input from '@storeblocks/input/lib';
import React, { FC } from 'react';
import styled from 'styled-components';

import { useTexts } from '@/hooks/useTexts';
import { padding } from '@/styles/variables';
import { TrackingElement, useGetTrackId } from '@/tracking';

interface Props {
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
  showAll: boolean;
  onShowAllClick: () => void;
  onAddClick: () => void;

  /** Used by Styled Component to apply styles. */
  className?: string;
}

export const Toolbar: FC<Props> = ({
  onSearch,
  searchValue,
  showAll,
  onShowAllClick,
  onAddClick,
  className,
}) => {
  const texts = useTexts();
  const getTrackId = useGetTrackId();

  return (
    <Container className={className} data-test="admin-organizations-toolbar">
      <Input
        id="organization-search"
        name="organization-search"
        label={texts.admin.organization.toolbar.search}
        onChange={onSearch}
        value={searchValue}
        large
      />
      <ButtonWrapper>
        {!showAll && (
          <Button
            variant="outlined"
            onClick={onShowAllClick}
            data-trackid={getTrackId(
              'show-all-organizations',
              TrackingElement.Button,
            )}
            data-test="show-all-organizations-button"
          >
            {texts.admin.organization.toolbar.viewAll}
          </Button>
        )}
        {showAll && (
          <Button
            variant="outlined"
            onClick={onShowAllClick}
            data-trackid={getTrackId(
              'show-search-result-organizations',
              TrackingElement.Button,
            )}
          >
            {texts.admin.organization.toolbar.viewSearchResults}
          </Button>
        )}
        <Button
          onClick={onAddClick}
          data-trackid={getTrackId(
            'add-new-organization',
            TrackingElement.Button,
          )}
          data-test="add-new-organizations-button"
        >
          {texts.admin.organization.toolbar.addNewOrganization}
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

const Container = styled.div`
  margin-top: ${padding.xl}px;
  justify-content: space-between;
  display: flex;
`;

const ButtonWrapper = styled.div`
  justify-self: flex-end;
  margin-top: 36px;

  > button {
    margin-right: 20px;
  }
`;
