import Button from '@storeblocks/button';
import React from 'react';

import { useTexts } from '@/hooks/useTexts';

export const OpenPrintDialogButton: React.FC = () => {
  const texts = useTexts();

  return (
    <div>
      <Button iconRight="print" onClick={() => window.print()}>
        {texts.pages.presentation.reportPreview.goToPrint}
      </Button>
    </div>
  );
};
