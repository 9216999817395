import Alert from '@storeblocks/alert';
import React from 'react';

import { useTexts } from '@/hooks/useTexts';

/**
 * Display a warning message to the user.
 * The visibility is triggered by defining a message in FMS.
 *
 * FMS keys:
 * - broadcast.warning.title
 * - broadcast.warning.description
 */
export const AnnouncementWarning: React.FC = () => {
  const texts = useTexts();

  if (!texts.broadcast.warning.title) {
    // There isn't any warning message in FMS, don't render anything.
    return null;
  }

  return (
    <div>
      <Alert
        title={texts.broadcast.warning.title}
        description={texts.broadcast.warning.description}
        variant="warning"
        fluid
      />
    </div>
  );
};
