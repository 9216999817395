import Table from '@storeblocks/table';
import uniqBy from 'lodash/uniqBy';
import React from 'react';
import styled from 'styled-components';

import { OrderLine, ValueType } from '@/api/orderList/models/Order';
import { useTexts } from '@/hooks/useTexts';
import { useUserLocale } from '@/i18n/hooks';
import { TableColumn } from '@/types/tables';
import { formatNumber } from '@/util/formatNumber';

import { orderStatusToFmsText } from '../utils/orderStatusToFmsText';

interface Props {
  orderLines: OrderLine[];
  'data-test': string;
}

type RowData = {
  fundName: string;
  value: string;
  status: string;
};

export const OrderLinesTable: React.FC<Props> = ({
  orderLines,
  'data-test': dataTest,
}) => {
  const texts = useTexts();
  const locale = useUserLocale();

  const columns: TableColumn<RowData>[] = [
    {
      dataType: 'text',
      name: texts.pages.orderList.fundTable.fund,
      key: 'fundName',
    },
    {
      dataType: 'text',
      name: texts.pages.orderList.fundTable.amount,
      key: 'value',
    },
    {
      dataType: 'text',
      name: texts.pages.orderList.fundTable.status,
      key: 'status',
    },
  ];

  const rows: RowData[] = orderLines.map((orderLine) => ({
    fundName: orderLine.fundName,

    // Not the pretties double ternary, could not find
    // an elegant way to do this.
    value:
      orderLine.valueType === ValueType.Amount
        ? // The order line is in amount.
          formatNumber(orderLine.value, locale, orderLine.currency)
        : orderLine.valueType === ValueType.Percent
          ? // The order line is in percent.
            `${orderLine.value} %`
          : // The order line is in units.
            `${orderLine.value} ${texts.orders.form.valueType.units}`,

    status: orderStatusToFmsText(orderLine.status, texts),
  }));

  const hasOnlyAmount = orderLines.every(
    (orderLine) => orderLine.valueType === ValueType.Amount,
  );

  const hasOnlyOneCurrency =
    uniqBy(orderLines, (orderLine) => orderLine.currency).length === 1;

  const footer: RowData[] = [];

  // The footer with the total amount can only be displayed if all order lines
  // are in amount and have the same currency.
  // Since the footer displays it like the example below, it is not possible to
  // display the total amount if the order lines are in percent or units. Or if
  // the order lines have different currencies.
  // "| Total | 2 000 000 NOK |"
  if (hasOnlyAmount && hasOnlyOneCurrency) {
    const totalAmount = orderLines.reduce(
      (totalAmount, orderLine) => totalAmount + orderLine.value,
      0,
    );

    footer.push({
      fundName: texts.pages.orderList.fundTable.total,
      value: formatNumber(totalAmount, locale, orderLines[0].currency),
      status: '',
    });
  }

  return (
    <StyledTable
      data-test={dataTest}
      columns={columns}
      data={rows}
      footerData={footer}
    />
  );
};

const StyledTable = styled(Table)`
  table-layout: fixed;
  line-height: 24px;

  td {
    white-space: nowrap;
    width: auto;
  }

  tr th:nth-child(2),
  tr td:nth-child(2) {
    text-align: right;
  }
`;
