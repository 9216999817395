import Select, { SelectChangeEvent } from '@storeblocks/select';
import { useField, useFormikContext } from 'formik';
import React from 'react';

import { useTexts } from '@/hooks/useTexts';
import { nameof } from '@/util/nameof';

import { useDiscretionaryPortfolios } from '../../hooks/useDiscretionaryPortfolios';
import { WithdrawalOrderFormValues } from '../../types/WithdrawalOrderFormValues';
import { createPortfolioBankAccountLabel } from '../../utils/createPortfolioBankAccountLabel';

interface Props {
  portfolioId: string;
}

export const WithdrawalPortfolioBankAccountSelect: React.FC<Props> = ({
  portfolioId,
}) => {
  const texts = useTexts();

  const [portfolio] = useDiscretionaryPortfolios().filter(
    (portfolio) => portfolio.id === portfolioId,
  );

  const portfolioBankAccounts = (portfolio.portfolioBankAccounts || []).map(
    (account) => ({
      label: createPortfolioBankAccountLabel(account),
      value: account.accountNumber,
    }),
  );

  const [field, meta, helpers] = useField<string>(
    nameof<WithdrawalOrderFormValues>('fromPortfolioBankAccount'),
  );

  const { setFieldValue } = useFormikContext<WithdrawalOrderFormValues>();

  const handlePortfolioBankAccountChanged = async (
    event: SelectChangeEvent,
  ): Promise<void> => {
    const accountNumber = event.target.value;

    await helpers.setValue(accountNumber);

    // Set the currency field in the form to the currency
    // of the selected portfolio bank account.
    const [account] = portfolio.portfolioBankAccounts.filter(
      (bankAccount) => bankAccount.accountNumber === accountNumber,
    );
    await setFieldValue(
      nameof<WithdrawalOrderFormValues>('currency'),
      account.currency,
    );
  };

  return (
    <Select
      {...field}
      id="withdrawal-portfolio-bank-account-selector"
      label={texts.orders.form.portfolioBankAccountSelect.withdrawal.title}
      hint={texts.orders.form.portfolioBankAccountSelect.withdrawal.description}
      placeholder={
        texts.orders.form.portfolioBankAccountSelect.withdrawal.placeholder
      }
      options={portfolioBankAccounts}
      error={meta.touched && meta.error}
      value={field.value || ''}
      onChange={handlePortfolioBankAccountChanged}
      fluid
    />
  );
};
