import Radio from '@storeblocks/radio';
import { useField } from 'formik';
import React, { useState } from 'react';

import { useTexts } from '@/hooks/useTexts';
import { nameof } from '@/util/nameof';

import {
  CitizenshipHighlightedCountries,
  citizenshipHighlightedCountriesOptions,
} from '../types/CitizenshipHighlightedCountries';
import { FormValues } from '../types/FormValues';
import { CitizenshipSelect } from './CitizenshipSelect';

export const CitizenshipRadio: React.FC = () => {
  const texts = useTexts();

  const [field, meta, helpers] = useField<string>(
    nameof<FormValues>('citizenship'),
  );

  const [isHighlightedCountry, setHighlightedCountry] = useState(
    // Empty value for the initial state should be considered as highlighted
    // country since we don't want the country select to be visible when the
    // form is in a pristine state.
    field.value === '' ||
      Object.values(CitizenshipHighlightedCountries).includes(
        field.value as CitizenshipHighlightedCountries,
      ),
  );

  const citizenshipOptions = citizenshipHighlightedCountriesOptions(texts);

  return (
    <>
      <Radio
        {...field}
        id="citizenship-radio"
        data-test="citizenship-radio"
        onChange={(e) => {
          if (e.target.value === CitizenshipHighlightedCountries.Other) {
            setHighlightedCountry(false);
            helpers.setValue('');
          } else {
            setHighlightedCountry(true);
            helpers.setValue(e.target.value);
          }
        }}
        value={
          isHighlightedCountry
            ? field.value
            : CitizenshipHighlightedCountries.Other
        }
        label={texts.orders.signatureRight.pep.citizenship.title}
        hint={texts.orders.signatureRight.pep.citizenship.description}
        options={citizenshipOptions}
        error={
          meta.touched &&
          meta.error &&
          // Don't display validation error on this component if the
          // option "Other" is selected.
          // The validation error will be displayed on the select component.
          isHighlightedCountry
            ? meta.error
            : null
        }
      />

      {/* Allow the user to specify the country them selves from a select list. */}
      {!isHighlightedCountry && <CitizenshipSelect />}
    </>
  );
};
