import Bars from '@storeblocks/bars';
import Button from '@storeblocks/button';
import React from 'react';
import { styled } from 'styled-components';

import { Esg } from '@/api/esg/models/Esg';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { Center } from '@/components/Center';
import { Gauge } from '@/components/Charts';
import { Column, Row } from '@/components/Grid';
import { InternalLink } from '@/components/InternalLink';
import { H2, H3 } from '@/components/Typography';
import { SurfaceWidget } from '@/components/Widget';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { EsgFundList } from '@/pages/Esg/widgets/EsgFundList/EsgFundList';
import { TrackingElement, useGetTrackId } from '@/tracking';

interface Props {
  esgData: Esg;
  portfolioGroupId?: string;
}

export const EsgOverview: React.FC<Props> = ({ esgData, portfolioGroupId }) => {
  const organizationCmId = useOrganizationCmId();
  const getTrackId = useGetTrackId();
  const texts = useTexts();

  return (
    <WithGap>
      <H2>{texts.dashboard.widgets.esgOverview.title}</H2>
      <Row data-test="esg-overview-widget">
        <Column $span={6}>
          <SurfaceWidget variant="border-alt">
            <WithGap gap="48">
              <Center horizontal vertical>
                <Gauge
                  percent={esgData.solutionCompanies.kpi.value}
                  description={texts.pages.esg.overview.sustainableInvestments}
                  size={300}
                />
              </Center>

              <List>
                <ListItem>
                  <MutedH3>{texts.pages.esg.overview.esgScore}</MutedH3>
                  <Bars
                    value={esgData.esgScore.kpi.value}
                    variant="sustainability"
                  />
                  {`${esgData.esgScore.kpi.value}/10`}
                </ListItem>
              </List>
            </WithGap>
            <AlignBottom>
              <Button
                as={InternalLink}
                variant="text"
                iconRight="arrow-right"
                to={`/${organizationCmId}/sustainability/esg${
                  portfolioGroupId ? `/${portfolioGroupId}` : ''
                }`}
                data-trackid={getTrackId('go-to-fund', TrackingElement.Link)}
                data-trackid-source="dashboard-page"
              >
                {texts.pages.esg.overview.goToEsgDashboard}
              </Button>
            </AlignBottom>
          </SurfaceWidget>
        </Column>

        <Column $span={6}>
          <EsgFundList portfolioGroupId={esgData.portfolioGroupId} />
        </Column>
      </Row>
    </WithGap>
  );
};

const AlignBottom = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
`;

const List = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 0px;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--color-border);

  & > *:first-child {
    // Make the first element push the rest of
    // the elements to the right. I.e. make the
    // first element be left aligned and the rest
    // of the elements be right aligned.
    flex-grow: 1;
  }
`;

const MutedH3 = styled(H3)`
  font-weight: normal;
`;
