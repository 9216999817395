import { useTexts } from '@/hooks/useTexts';
import { TableColumn } from '@/types/tables';

import { ContractNotesRow } from '../utils/getContractNotesRowsWithCheckbox';

type NotesColumns = TableColumn<ContractNotesRow>[];

export const useContractNotesColumns = (): NotesColumns => {
  const texts = useTexts();
  return [
    {
      key: 'date',
      name: texts.orders.history.table.orderDate,
      dataType: 'other',
      renderType: 'jsx',
      style: {
        padding: 0,
      },
    },
    {
      key: 'name',
      name: texts.orders.history.table.name,
      dataType: 'other',
      renderType: 'jsx',
      style: {
        padding: 0,
      },
    },
    {
      key: 'selectFile',
      name: texts.pages.contractNotes.selectFile,
      dataType: 'other',
      renderType: 'jsx',
      style: {
        padding: 0,
      },
    },
  ];
};
