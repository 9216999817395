import Button from '@storeblocks/button';
import { Illustration } from '@storeblocks/icon';
import React from 'react';
import styled from 'styled-components';

import { AnnouncementWarning } from '@/components/AnnouncementWarning';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { Column, Row } from '@/components/Grid';
import { MainContent } from '@/components/MainContent';
import { PageHeading } from '@/components/PageHeading';
import { WithGap } from '@/components/WithGap';
import { demoOrganizationCmId } from '@/demoMode/demoOrganizationCmId';
import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';

export const DemoModePage: React.FC = () => {
  const texts = useTexts();
  const getTrackId = useGetTrackId();

  return (
    <CenteredPageContent data-test="demo-mode-start-page">
      <AnnouncementWarning />

      <MainContent>
        <Row>
          <Column $span={6}>
            <WithGap gap="48">
              <PageHeading header={texts.pages.demo.title} />
              <p>{texts.pages.demo.description}</p>

              <div>
                <Button
                  as="a"
                  href={`/${demoOrganizationCmId}`}
                  iconRight="arrow-right"
                  data-trackid={getTrackId(
                    'go-to-demo-organization',
                    TrackingElement.Link,
                  )}
                >
                  {texts.pages.demo.goToConnect}
                </Button>
              </div>
            </WithGap>
          </Column>

          <Column $span={6}>
            <StyledIllustration name="profitable-sustainability" />
          </Column>
        </Row>
      </MainContent>
    </CenteredPageContent>
  );
};

const StyledIllustration = styled(Illustration)`
  --padding: 16px;

  width: calc(100% - var(--padding));
  height: calc(100% - var(--padding));
  padding: var(--padding);
`;
