import Button from '@storeblocks/button';
import React from 'react';
import styled from 'styled-components';

import { useTexts } from '@/hooks/useTexts';

interface Props {
  isExpanded: boolean;
  onChange: () => void;
}

export const ExpandFilterButton: React.FC<Props> = ({
  isExpanded,
  onChange,
}) => {
  const texts = useTexts();

  const iconName = isExpanded ? 'arrow-drop-up' : 'arrow-drop-down';

  const label = isExpanded
    ? texts.pages.presentation.filters.expandButton.expanded
    : texts.pages.presentation.filters.expandButton.collapsed;

  return (
    <StyledButton iconRight={iconName} variant="text" onClick={onChange}>
      {label}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  @media print {
    display: none;
  }
`;
