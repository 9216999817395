import ChipGroup, { Option } from '@storeblocks/chip/lib/group';
import React, { FC } from 'react';

import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { AssetReturnPeriod } from '../types';

interface PeriodOption extends Option {
  value: AssetReturnPeriod;
  label: string;
}

interface Props {
  id: string;
  checked: AssetReturnPeriod;
  onChange: (value: AssetReturnPeriod) => void;
}

export const AssetReturnPeriodChips: FC<Props> = ({
  id,
  checked,
  onChange,
}) => {
  const texts = useTexts();

  const getTrackId = useGetTrackId();
  const trackId = getTrackId(
    'select-asset-return-period',
    TrackingElement.FilterChip,
  );

  const periodOptions: Array<PeriodOption> = [
    {
      id: AssetReturnPeriod.YTD,
      value: AssetReturnPeriod.YTD,
      label: texts.charts.assetReturn.period.ytd,
      inputProps: {
        'data-trackid': trackId,
        'data-trackid-selected-filter': AssetReturnPeriod.YTD,
        'data-trackid-source': 'asset-returns',
        'data-trackid-type': 'filter-chips',
      },
    },
    {
      id: AssetReturnPeriod.QTD,
      value: AssetReturnPeriod.QTD,
      label: texts.charts.assetReturn.period.qtd,
      inputProps: {
        'data-trackid': trackId,
        'data-trackid-selected-filter': AssetReturnPeriod.QTD,
        'data-trackid-source': 'asset-returns',
        'data-trackid-type': 'filter-chips',
      },
    },
    {
      id: AssetReturnPeriod.MTD,
      value: AssetReturnPeriod.MTD,
      label: texts.charts.assetReturn.period.mtd,
      inputProps: {
        'data-trackid': trackId,
        'data-trackid-selected-filter': AssetReturnPeriod.MTD,
        'data-trackid-source': 'asset-returns',
        'data-trackid-type': 'filter-chips',
      },
    },
  ];

  return (
    <ChipGroup
      checked={[checked]}
      type="choice"
      id={id}
      options={periodOptions}
      onChange={(value) => onChange(value[0] as AssetReturnPeriod)}
    />
  );
};
