import Button from '@storeblocks/button';
import { Illustration } from '@storeblocks/icon';
import React from 'react';
import styled from 'styled-components';

import { Column, Row } from '@/components/Grid';
import { PageHeading } from '@/components/PageHeading';
import { Ingress } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { appConfig } from '@/constants/config';
import { useTexts } from '@/hooks/useTexts';
import { getBasename } from '@/routes/utils/getBasename';
import { TrackingElement, useGetTrackId } from '@/tracking';

export const InvitationalLinkSuccessPage: React.FC = () => {
  const texts = useTexts();
  const getTrackId = useGetTrackId();
  const redirectUri = `${window.location.origin}${getBasename()}/`;

  return (
    <Center data-test="invitation-success-page">
      <Page>
        <StyledRow>
          <Column $span={6}>
            <WithGap gap="24">
              <PageHeading header={texts.pages.newuser.success.title} />

              <Ingress>{texts.pages.newuser.success.description}</Ingress>

              <div>
                <Button
                  as="a"
                  href={redirectUri}
                  iconRight="arrow-right"
                  data-test="go-to-connect"
                  data-trackid={getTrackId(
                    'go-to-connect',
                    TrackingElement.Link,
                  )}
                >
                  {texts.pages.newuser.success.goToConnect}
                </Button>
              </div>
            </WithGap>
          </Column>
          <StyledColumn $span={6}>
            <Illustration name="start-up-one-person" height="500" />
          </StyledColumn>
        </StyledRow>
      </Page>
    </Center>
  );
};

const Center = styled.div`
  max-width: ${appConfig.maxPageWidth};
  margin: 0 auto;
`;

const Page = styled.div`
  margin-top: 72px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 24px;
`;

const StyledRow = styled(Row)`
  margin-top: 8px;
`;

const StyledColumn = styled(Column)`
  display: flex;
  justify-content: center;
`;
