import Breadcrumbs from '@storeblocks/breadcrumbs';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { useDocumentCategoriesQuery } from '@/api/documentArchive/documentArchiveApi';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { AnnouncementWarning } from '@/components/AnnouncementWarning';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { DeleteFileModal } from '@/components/FileModals/DeleteFileModal';
import { EditFileModal } from '@/components/FileModals/EditFileModal';
import { UploadFileModal } from '@/components/FileModals/UploadFileModal';
import { InternalLink } from '@/components/InternalLink';
import { MainContent } from '@/components/MainContent';
import { OrderToSignAlert } from '@/components/OrderToSignAlert/OrderToSignAlert';
import { PageHeading } from '@/components/PageHeading';
import { useTexts } from '@/hooks/useTexts';
import { Toolbar } from '@/pages/Archives/DocumentsArchive/Toolbar';

import { DocumentsArchiveProvider } from './DocumentsArchiveContext';
import { convertDocumentCategoriesToOptions } from './documentsArchiveLogic';
import { DocumentsArchiveTable } from './DocumentsArchiveTable';

export function DocumentsArchivePage(): JSX.Element {
  const organizationCmId = useOrganizationCmId();
  const texts = useTexts();

  const documentCategoriesQuery = useDocumentCategoriesQuery();
  const documentCategoryOptions = useMemo(
    () =>
      convertDocumentCategoriesToOptions(documentCategoriesQuery.data, texts),
    [convertDocumentCategoriesToOptions, documentCategoriesQuery.data, texts],
  );

  return (
    <DocumentsArchiveProvider>
      <CenteredPageContent data-test="documents-page">
        <AnnouncementWarning />
        <OrderToSignAlert />

        <Breadcrumbs>
          <InternalLink to={`/${organizationCmId}`}>
            {texts.menu.links.home}
          </InternalLink>
          <InternalLink to={`/${organizationCmId}/documents`}>
            {texts.menu.links.documents}
          </InternalLink>
        </Breadcrumbs>

        <MainContent>
          <PageHeading
            header={texts.menu.links.documents}
            ingress={texts.documentsArchivePage.description}
          />

          <div>
            <Toolbar />
            <DeleteFileModal />
            <EditFileModal categories={documentCategoryOptions} />
            <UploadFileModal categories={documentCategoryOptions} />
            <ContentWrapper>
              <DocumentsArchiveTable />
            </ContentWrapper>
          </div>
        </MainContent>
      </CenteredPageContent>
    </DocumentsArchiveProvider>
  );
}

const ContentWrapper = styled.div`
  margin-top: 35px;
`;
