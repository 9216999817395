import React from 'react';

import { Column, Row } from '@/components/Grid';
import { MainContent } from '@/components/MainContent';
import { H1 } from '@/components/Typography';
import { useTexts } from '@/hooks/useTexts';

import { ChangeStatusButtons } from './components/ChangeStatusButtons';
import { SigningRightBreadcrumb } from './components/SigningRightBreadcrumb';
import { Stepper } from './components/Stepper';

export const SigningRightInProgressPage: React.FC = () => {
  const texts = useTexts();

  return (
    <>
      <SigningRightBreadcrumb />

      <MainContent data-test="signing-right-in-progress">
        <Stepper activeStep={2} />

        <H1>{texts.orders.signatureRight.missingSignature.title}</H1>

        <Row>
          {/*
           * Force the text to wrap to make it easier to read,
           * rather than to have it on one long line.
           */}
          <Column $span={6}>
            <p>{texts.orders.signatureRight.missingSignature.description}</p>
          </Column>
        </Row>

        <ChangeStatusButtons />
      </MainContent>
    </>
  );
};
