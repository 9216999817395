import Button from '@storeblocks/button';
import { Illustration } from '@storeblocks/icon';
import React, { FC } from 'react';
import styled from 'styled-components';

import { getAuth } from '@/auth/Auth';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { Divider } from '@/components/Divider';
import { Column, Row } from '@/components/Grid';
import { MainContent } from '@/components/MainContent';
import { PageHeading } from '@/components/PageHeading';
import { WithGap } from '@/components/WithGap';
import { useRouteOrganizationCmId } from '@/hooks/useRouteOrganizationCmId';
import { useTexts } from '@/hooks/useTexts';

export const NotFoundPage: FC = () => {
  const organizationCmId = useRouteOrganizationCmId();
  const texts = useTexts();

  return (
    <CenteredPageContent data-test="not-found-page">
      <MainContent>
        <PageHeading header={texts.pages.notFound.title} />
        <Divider margin="8" />
        <ContentRow>
          <Column $span={6}>
            <WithGap>
              <Ingress>{texts.pages.notFound.description}</Ingress>
              <Ingress>
                {texts.pages.notFound.contactSupport.part1}{' '}
                <a href={`mailto:${texts.error.siosEmail}`}>
                  {texts.error.siosEmail}
                </a>{' '}
                {texts.pages.notFound.contactSupport.part2}
              </Ingress>
              <ButtonContainer>
                <Button
                  as="a"
                  href={organizationCmId ? `/${organizationCmId}` : '/'}
                  iconLeft="arrow-left"
                  variant="text"
                >
                  {texts.pages.notFound.backToConnect}
                </Button>
                <Button
                  onClick={() => getAuth().logout()}
                  iconLeft="arrow-left"
                  variant="text"
                >
                  {texts.pages.notFound.logOut}
                </Button>
              </ButtonContainer>
            </WithGap>
          </Column>
          <IllustrationColumn $span={6}>
            <Illustration name="something-went-wrong" height="500" />
          </IllustrationColumn>
        </ContentRow>
      </MainContent>
    </CenteredPageContent>
  );
};

const Ingress = styled.div`
  font-size: 18px;
  line-height: 24px;
`;

const ContentRow = styled(Row)`
  margin-top: 8px;
`;

const ButtonContainer = styled(WithGap)`
  align-items: start;
`;

const IllustrationColumn = styled(Column)`
  display: flex;
  justify-content: center;
`;
