import Button from '@storeblocks/button';
import { Illustration } from '@storeblocks/icon';
import React from 'react';
import styled from 'styled-components';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { Center } from '@/components/Center';
import { Column, Row } from '@/components/Grid';
import { InternalLink } from '@/components/InternalLink';
import { MainContent } from '@/components/MainContent';
import { H1, Paragraph } from '@/components/Typography';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';

/**
 * A page describing that the organization is not eligible for fund trading.
 * E.g.: If the organization does not have a fund account.
 */
export const NotEligibleForTradePage: React.FC = () => {
  const texts = useTexts();
  const organizationCmId = useOrganizationCmId();

  return (
    <StyledMainContent>
      <Row>
        <Column $span={6}>
          <WithGap gap="24">
            <H1>{texts.orders.notEligibleForTrading.title}</H1>

            <Paragraph>
              {texts.orders.notEligibleForTrading.description.part1}{' '}
              <a href="mailto:sios@storebrand.no">sios@storebrand.no</a>{' '}
              {texts.orders.notEligibleForTrading.description.part2}
            </Paragraph>
          </WithGap>

          <ButtonContainer>
            <Button
              as={InternalLink}
              to={`/${organizationCmId}`}
              iconLeft="arrow-left"
              variant="text"
            >
              {texts.orders.notEligibleForTrading.button.backToConnect}
            </Button>
          </ButtonContainer>
        </Column>

        <Column $span={6}>
          <Center horizontal>
            <Illustration name="b2b-simplicity1" height="300" />
          </Center>
        </Column>
      </Row>
    </StyledMainContent>
  );
};

const StyledMainContent = styled(MainContent)`
  margin-top: 80px;
`;

const ButtonContainer = styled.div`
  margin-top: 48px;
`;
