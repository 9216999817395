import { useTexts } from '@/hooks/useTexts';

import { usePresentationContext } from '../../PresentationContext';
import { FilterPeriod } from '../types/FilterPeriod';

export const usePeriodFilterText = (): string => {
  const {
    globalFilter: { period },
  } = usePresentationContext();

  const texts = useTexts();

  switch (period) {
    case FilterPeriod.YTD:
      return texts.pages.presentation.filters.period.ytdFull;
    case FilterPeriod.OneMonth:
      return texts.pages.presentation.filters.period.oneMonthFull;
    case FilterPeriod.ThreeMonths:
      return texts.pages.presentation.filters.period.threeMonthsFull;
    case FilterPeriod.SixMonths:
      return texts.pages.presentation.filters.period.sixMonthsFull;
    case FilterPeriod.OneYear:
      return texts.pages.presentation.filters.period.oneYearFull;
    case FilterPeriod.ThreeYears:
      return texts.pages.presentation.filters.period.threeYearsFull;
    default:
      throw new Error(
        `Unable to find filter text for filter period [${period}]`,
      );
  }
};
