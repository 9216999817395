import Button from '@storeblocks/button';
import { Illustration } from '@storeblocks/icon';
import React from 'react';
import styled from 'styled-components';

import { getAuth } from '@/auth/Auth';
import { appConfig } from '@/constants/config';
import { useTexts } from '@/hooks/useTexts';

import { Divider } from './Divider';
import { Column, Row } from './Grid';
import { PageHeading } from './PageHeading';
import { Ingress } from './Typography';
import { WithGap } from './WithGap';

export const NoAccessInternalUser: React.FC = () => {
  const texts = useTexts();

  return (
    <Container>
      <Center>
        <Page>
          <PageHeading header={texts.error.description.access.title} />
          <Divider margin="8" />
          <StyledRow>
            <Column $span={6}>
              <StyledWithGap>
                <Ingress>
                  {texts.error.description.access.part1}{' '}
                  <a href={texts.error.titoLink}>
                    {texts.error.description.access.part2}
                  </a>{' '}
                  {texts.error.description.access.part3}{' '}
                  <a href={texts.error.siosEmail}>{texts.error.siosEmail}</a>{' '}
                </Ingress>
                <Button
                  onClick={() => getAuth().logout()}
                  iconLeft="arrow-left"
                  variant="text"
                >
                  {texts.error.buttons.logOut}
                </Button>
              </StyledWithGap>
            </Column>
            <StyledColumn $span={6}>
              <Illustration name="something-went-wrong" height="500" />
            </StyledColumn>
          </StyledRow>
        </Page>
      </Center>
    </Container>
  );
};

const Container = styled.div`
  border-top: 3px solid var(--color-brand);
`;

const Center = styled.div`
  max-width: ${appConfig.maxPageWidth};
  margin: 0 auto;
`;

const Page = styled.div`
  margin-top: 72px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 24px;
`;

const StyledRow = styled(Row)`
  margin-top: 8px;
`;

const StyledColumn = styled(Column)`
  display: flex;
  justify-content: center;
`;

const StyledWithGap = styled(WithGap)`
  align-items: start;
`;
