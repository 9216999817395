import React from 'react';

import { QueryState } from '@/api/components/QueryState';
import { useFetchFundHoldingsQuery } from '@/api/fundHoldings/fundHoldingsApi';
import { useFundListQuery } from '@/api/funds/fundsApi';
import { usePortfoliosQuery } from '@/api/portfolios';
import { isNotReady } from '@/api/utils/isNotReady';
import { H2 } from '@/components/Typography';
import { useTexts } from '@/hooks/useTexts';
import { WithOrganizationEsgData } from '@/pages/Esg/WithOrganizationEsgData';
import { WithPortfolioGroupEsgData } from '@/pages/Esg/WithPortfolioGroupEsgData';

import { EsgOverview } from './EsgOverview';

type Props = {
  portfolioGroupId?: string;
};

export const EsgOverviewWidget: React.FC<Props> = ({ portfolioGroupId }) => {
  const texts = useTexts();
  const portfoliosQuery = usePortfoliosQuery();

  // Downstream components require data from these queries.
  const fundListQuery = useFundListQuery();
  const holdingsQuery = useFetchFundHoldingsQuery();

  if (isNotReady([portfoliosQuery, fundListQuery, holdingsQuery])) {
    return (
      <>
        <H2>{texts.dashboard.widgets.esgOverview.title}</H2>

        <QueryState query={[portfoliosQuery, fundListQuery, holdingsQuery]} />
      </>
    );
  }

  if (portfolioGroupId) {
    return (
      <WithPortfolioGroupEsgData
        portfolioGroupId={portfolioGroupId}
        render={(data) => (
          <EsgOverview esgData={data} portfolioGroupId={portfolioGroupId} />
        )}
      />
    );
  }

  return (
    <WithOrganizationEsgData
      render={(data) => <EsgOverview esgData={data} />}
    />
  );
};
