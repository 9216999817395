import round from 'lodash/round';

import { AssetPerformance } from '@/api/performanceData/models';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { Locale } from '@/i18n/locale';
import { FMSTexts } from '@/types/fms';
import { formatNumber } from '@/util/formatNumber';
import { roundHalfEven } from '@/util/roundHalfEven';

import { AssetAllocationViewMode } from '../../types/AssetAllocationViewMode';
import { AssetAllocationRow } from '../types/AssetAllocationRow';
import { mapToAssetAllocationRow } from './mapToAssetAllocationRow';

/**
 * Traverse the multiple asset performance data and map all asset allocation holding rows.
 *
 * @param assetPerformance The performance data to traverse.
 * @param locale The current locale used for number formatting.
 * @param texts FMS Texts.
 * @param fraction The weight fraction the total value of this row represents.
 * This is used for correctly calculating the total weight when data from multiple portfolios are displayed.
 * E.x. if there are 4 portfolios in total the fraction of this portfolio row would be '1/4' or '0.25'.
 * @returns The mapped row with all traversed and mapped sub rows.
 */
export const mapToMultiAssetAllocationRow = (
  assetPerformance: AssetPerformance,
  locale: Locale,
  texts: FMSTexts,
  viewMode: AssetAllocationViewMode,
  fraction: number,
): AssetAllocationRow => {
  const root = assetPerformance.assets[0];

  return {
    name: fmsWithTemplate(
      texts.pages.presentation.screens.assetAllocationTable.portfolio,
      { portfolioGroup: assetPerformance.portfolioGroup },
    ),

    ...((viewMode === AssetAllocationViewMode.Holdings ||
      viewMode === AssetAllocationViewMode.All) && {
      marketValue: formatNumber(roundHalfEven(root.marketValue, 0), locale),

      yearToDate: formatNumber(roundHalfEven(root.yearToDateAmount, 0), locale),

      weight: formatNumber(
        round(root.assetRatioOfTotalPercent * fraction, 1),
        locale,
        '%',
        { minimumFractionDigits: 1 },
      ),
    }),

    ...((viewMode === AssetAllocationViewMode.Performance ||
      viewMode === AssetAllocationViewMode.All) && {
      monthToDatePercent: formatNumber(
        round(root.monthToDatePercent, 2),
        locale,
        '%',
        { minimumFractionDigits: 2 },
      ),

      monthToDateBenchmark: formatNumber(
        round(root.benchmarkMonthToDatePercent, 2),
        locale,
        '%',
        { minimumFractionDigits: 2 },
      ),

      yearToDatePercent: formatNumber(
        round(root.yearToDatePercent, 2),
        locale,
        '%',
        { minimumFractionDigits: 2 },
      ),

      yearToDateBenchmark: formatNumber(
        round(root.benchmarkYearToDatePercent, 2),
        locale,
        '%',
        { minimumFractionDigits: 2 },
      ),
    }),

    subRows: assetPerformance.assets.map((asset) =>
      mapToAssetAllocationRow(asset, locale, viewMode, fraction),
    ),
  };
};
