import React from 'react';

import { UnitChips } from '@/components/Charts/components/UnitChips';
import { useTexts } from '@/hooks/useTexts';
import { Unit } from '@/types/Unit';

import { usePresentationContext } from '../PresentationContext';
import { Filter } from './Filter';

export const UnitFilter: React.FC = () => {
  const texts = useTexts();

  const { globalFilter, setGlobalFilter } = usePresentationContext();

  const handleUnitOnChange = (selectedUnit: Unit): void => {
    setGlobalFilter({
      ...globalFilter,
      unit: selectedUnit,
    });
  };

  return (
    <Filter label={texts.pages.presentation.filters.unit.label}>
      <UnitChips
        id="presentation-filter-unit"
        checked={globalFilter.unit}
        onChange={handleUnitOnChange}
      />
    </Filter>
  );
};
