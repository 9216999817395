import Select, { Option } from '@storeblocks/select';
import React, { useState } from 'react';

import { OrderType } from '@/api/order/models/OrderType';
import { useTexts } from '@/hooks/useTexts';

import { useFundPortfolios } from '../hooks/useFundPortfolios';

interface Props {
  orderType: OrderType.Redemption | OrderType.Subscription;
  initialPortfolioShortName: string | undefined;
  onChange: (portfolioShortName: string) => void;
}

export const FundPortfolioSelector: React.FC<Props> = ({
  orderType,
  initialPortfolioShortName,
  onChange,
}) => {
  const { orders } = useTexts();
  const portfolios = useFundPortfolios(orderType);

  const [selectedPortfolioShortName, setSelectedPortfolioShortName] =
    useState<string>(initialPortfolioShortName ?? '');

  const options = portfolios.map<Option>((portfolio) => ({
    value: portfolio.id,
    label: `${portfolio.id} - ${portfolio.name}`,
  }));

  const handleOnChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    setSelectedPortfolioShortName(event.target.value);
    onChange(event.target.value);
  };

  return (
    <Select
      name="selectedPortfolio"
      id="portfolio-selector"
      label={orders.form.portfolio.title}
      hint={orders.form.portfolio.message}
      placeholder={orders.form.portfolio.placeholder}
      options={options}
      value={selectedPortfolioShortName}
      onChange={handleOnChange}
      fluid
    />
  );
};
