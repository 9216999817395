import React from 'react';

import { OrderValueType } from '@/api/order/models/OrderValueType';
import { useTexts } from '@/hooks/useTexts';
import { RedemptionFundState } from '@/pages/Order/steps/OrderDetails/types/RedemptionFundState';
import { nameof } from '@/util/nameof';

import { RedemptionOrderFormValues } from '../../../types/RedemptionOrderFormValues';
import { useArrayFieldByIsin } from '../hooks/useArrayFieldByIsin';
import { CellWrapper } from './CellWrapper';
import { FundInput } from './FundInput';

interface Props {
  fund: RedemptionFundState;
}

export const RedemptionInput: React.FC<Props> = ({ fund }) => {
  const texts = useTexts();

  const fundFieldName = nameof<RedemptionOrderFormValues>('fromFunds');

  const [valueField, valueMeta, valueHelpers] = useArrayFieldByIsin<number>(
    fundFieldName,
    fund.isin,
    nameof<RedemptionFundState>('value'),
  );

  const handleOnChange = (value: number): void => {
    valueHelpers.setValue(value);
  };

  const handleOnBur = (event: React.FocusEvent<HTMLDivElement>): void => {
    valueField.onBlur(event);
  };

  const id = `input-redemption-fund-${fund.isin}`;

  switch (fund.valueType) {
    case OrderValueType.Amount:
      return (
        <CellWrapper>
          <FundInput
            id={id}
            name={valueField.name}
            value={valueMeta.value}
            suffix={fund.currency}
            disabled={fund.isClosed || fund.sellAll}
            error={valueMeta.touched && !!valueMeta.error}
            onChange={handleOnChange}
            onBlur={handleOnBur}
          />
        </CellWrapper>
      );
    case OrderValueType.Percent:
      return (
        <CellWrapper>
          <FundInput
            id={id}
            name={valueField.name}
            value={valueField.value}
            decimalScale={2}
            suffix="%"
            disabled={fund.isClosed || fund.sellAll}
            error={valueMeta.touched && !!valueMeta.error}
            onChange={handleOnChange}
            onBlur={handleOnBur}
          />
        </CellWrapper>
      );
    case OrderValueType.Units:
      return (
        <CellWrapper>
          <FundInput
            id={id}
            name={valueField.name}
            value={valueField.value}
            decimalScale={4}
            suffix={texts.orders.form.valueType.units.toLowerCase()}
            disabled={fund.isClosed || fund.sellAll}
            error={valueMeta.touched && !!valueMeta.error}
            onChange={handleOnChange}
            onBlur={handleOnBur}
          />
        </CellWrapper>
      );
    default:
      throw new Error(`Invalid order value type [${fund.valueType}]`);
  }
};
