import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

import { Show } from '@/components/Show';
import { DateSearch } from '@/components/Toolbar/DateSearch';
import { DownloadButton } from '@/components/Toolbar/DownloadButton';
import { DownloadErrorAlert } from '@/components/Toolbar/DownloadErrorAlert';
import { useSearchParams } from '@/components/Toolbar/hooks/useSearchParams';
import { TextSearch } from '@/components/Toolbar/TextSearch';
import { useHandleAsyncOperation } from '@/hooks/useHandleAsyncError';
import { useTexts } from '@/hooks/useTexts';
import { media } from '@/styles/variables';

export interface SearchParams {
  dateISO?: string;
  query?: string;
}

interface Props {
  numberOfFilesToDownload: number;
  onChange: (params: SearchParams) => void;
  onDownloadClick: () => Promise<void>;
}

export const Toolbar: React.FC<Props> = ({
  onChange: onSearchParamsChanged,
  onDownloadClick,
  numberOfFilesToDownload,
}: Props) => {
  const texts = useTexts();

  const [handleDownloadClick, , downloadError] =
    useHandleAsyncOperation(onDownloadClick);

  const searchParams = useSearchParams<SearchParams>(onSearchParamsChanged);

  const updateTextSearch = (query: string): void => {
    searchParams.update((state) => {
      state.query = query;
    });
  };

  const updateDateSearch = (date: Date | undefined): void => {
    const dateString = date ? format(date, 'yyyy-MM-dd') : '';

    searchParams.update((state) => {
      state.dateISO = dateString;
    });
  };

  return (
    <>
      <Wrapper>
        <TextSearch
          onChange={updateTextSearch}
          label={texts.reportsArchivePage.search}
        />
        <DateSearch
          onChange={updateDateSearch}
          label={texts.reportsArchivePage.tableHeaders.reportDate}
        />

        <DownloadButton
          onClick={handleDownloadClick}
          numberOfFilesToDownload={numberOfFilesToDownload}
        />
      </Wrapper>

      <Show when={downloadError}>
        <DownloadErrorAlert />
      </Show>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  height: 136px;

  & > div + div {
    margin-left: 25px;
  }

  & > button {
    /* Make the download button align to the right. */
    margin-left: auto;
    margin-top: 40px;
  }

  @media ${media.tabletAndBelow} {
    flex-flow: column;
    align-items: flex-start;

    & > div + div {
      margin-left: 0;
      margin-top: 16px;
    }

    & > button {
      margin-left: 0;
      margin-top: 16px;
    }
  }
`;
