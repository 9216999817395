import Checkbox from '@storeblocks/checkbox';
import React, { useState } from 'react';
import styled from 'styled-components';

import { usePortfoliosQuery } from '@/api/portfolios';
import { Divider } from '@/components/Divider';
import { useTexts } from '@/hooks/useTexts';

import {
  selectAllOptionValue,
  usePortfolioGroupOptions,
} from './hooks/usePortfolioGroupOptions';
import { usePresentationContext } from './PresentationContext';

export const PortfolioGroupCheckboxes: React.FC = () => {
  const texts = useTexts();

  const { data } = usePortfoliosQuery();

  const options = usePortfolioGroupOptions(data.portfolioGroups);

  const { globalFilter, setGlobalFilter } = usePresentationContext();

  const [selectAll, setSelectAll] = useState<boolean>(
    data.portfolioGroups.every((portfolioGroup) =>
      globalFilter.portfolioGroups
        .map((selectedPortfolioGroup) => selectedPortfolioGroup.id)
        .includes(portfolioGroup.id),
    ),
  );

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const selectedPortfolioGroups = event.target.checked
      ? options.map((option) => option.value)
      : [];

    setGlobalFilter({
      ...globalFilter,
      portfolioGroups: data.portfolioGroups.filter((portfolioGroup) => {
        return selectedPortfolioGroups.includes(portfolioGroup.id);
      }),
    });

    setSelectAll(event.target.checked);
  };

  const handleChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    selectedPortfolioGroups: Array<string>,
  ): void => {
    // Set all the selected screen routes.
    setGlobalFilter({
      ...globalFilter,
      portfolioGroups: data.portfolioGroups.filter((portfolioGroup) =>
        selectedPortfolioGroups.includes(portfolioGroup.id),
      ),
    });

    // Set select all to true if all portfolio groups are selected
    // or to false if not all portfolio groups are selected.
    setSelectAll(
      data.portfolioGroups.every((portfolioGroup) =>
        selectedPortfolioGroups.includes(portfolioGroup.id),
      ),
    );
  };

  return (
    <>
      <StyledCheckbox
        id="select-all-portfolio-groups"
        name="select-all-portfolio-groups"
        onChange={handleSelectAllChange}
        options={[
          {
            id: 'select-all-portfolio-groups',
            value: selectAllOptionValue,
            label: texts.pages.presentation.selectAllPortfolioGroups,
          },
        ]}
        value={selectAll}
      />
      <Divider margin="8" />
      <Checkbox
        id="choose-portfolio-groups"
        name="choose-portfolio-groups"
        onChange={handleChange}
        options={options}
        value={globalFilter.portfolioGroups.map(
          (portfolioGroup) => portfolioGroup.id,
        )}
      />
    </>
  );
};

const StyledCheckbox = styled(Checkbox)`
  font-weight: 600;
`;
