import ChipGroup, { Option } from '@storeblocks/chip/lib/group';
import React, { FC } from 'react';

import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';

import { usePresentationContext } from '../PresentationContext';
import { Filter } from './Filter';
import { FilterPeriod } from './types/FilterPeriod';

interface PeriodOption extends Option {
  value: FilterPeriod;
  label: string;
}

export const PeriodFilter: FC = () => {
  const texts = useTexts();

  const { globalFilter, setGlobalFilter } = usePresentationContext();

  const getTrackId = useGetTrackId();
  const trackId = getTrackId(
    'select-asset-return-period',
    TrackingElement.FilterChip,
  );

  const periodOptions: Array<PeriodOption> = [
    {
      id: FilterPeriod.YTD,
      value: FilterPeriod.YTD,
      label: texts.pages.presentation.filters.period.ytd,
      inputProps: {
        'data-trackid': trackId,
        'data-trackid-selected-filter': FilterPeriod.YTD,
        'data-trackid-source': 'presentation',
        'data-trackid-type': 'filter-chips',
      },
    },
    {
      id: FilterPeriod.OneMonth,
      value: FilterPeriod.OneMonth,
      label: texts.pages.presentation.filters.period.oneMonth,
      inputProps: {
        'data-trackid': trackId,
        'data-trackid-selected-filter': FilterPeriod.OneMonth,
        'data-trackid-source': 'presentation',
        'data-trackid-type': 'filter-chips',
      },
    },
    {
      id: FilterPeriod.ThreeMonths,
      value: FilterPeriod.ThreeMonths,
      label: texts.pages.presentation.filters.period.threeMonths,
      inputProps: {
        'data-trackid': trackId,
        'data-trackid-selected-filter': FilterPeriod.ThreeMonths,
        'data-trackid-source': 'presentation',
        'data-trackid-type': 'filter-chips',
      },
    },
    {
      id: FilterPeriod.SixMonths,
      value: FilterPeriod.SixMonths,
      label: texts.pages.presentation.filters.period.sixMonths,
      inputProps: {
        'data-trackid': trackId,
        'data-trackid-selected-filter': FilterPeriod.SixMonths,
        'data-trackid-source': 'presentation',
        'data-trackid-type': 'filter-chips',
      },
    },
    {
      id: FilterPeriod.OneYear,
      value: FilterPeriod.OneYear,
      label: texts.pages.presentation.filters.period.oneYear,
      inputProps: {
        'data-trackid': trackId,
        'data-trackid-selected-filter': FilterPeriod.OneYear,
        'data-trackid-source': 'presentation',
        'data-trackid-type': 'filter-chips',
      },
    },
    {
      id: FilterPeriod.ThreeYears,
      value: FilterPeriod.ThreeYears,
      label: texts.pages.presentation.filters.period.threeYears,
      inputProps: {
        'data-trackid': trackId,
        'data-trackid-selected-filter': FilterPeriod.ThreeYears,
        'data-trackid-source': 'presentation',
        'data-trackid-type': 'filter-chips',
      },
    },
  ];

  const handlePeriodOnChange = (value: Array<string>): void => {
    setGlobalFilter({
      ...globalFilter,
      period: value[0] as FilterPeriod,
    });
  };

  return (
    <Filter label={texts.pages.presentation.filters.period.label}>
      <ChipGroup
        checked={[globalFilter.period]}
        type="choice"
        id="presentation-filter-period"
        options={periodOptions}
        onChange={handlePeriodOnChange}
      />
    </Filter>
  );
};
