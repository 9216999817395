import { format, parseISO } from 'date-fns';
import React from 'react';

import { Esg } from '@/api/esg/models/Esg';
import { Column, Row } from '@/components/Grid';
import { H1, H2, Hint, Paragraph } from '@/components/Typography';
import { WidgetHeader } from '@/components/Widget';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';
import { fmsWithTemplate } from '@/i18n/fmsWithTemplate';
import { useUserDateFormat } from '@/i18n/hooks';
import { EsgCarbonFootprintComparison } from '@/pages/Esg/widgets/EsgCarbonFootprint/EsgCarbonFootprintComparison';
import { EsgCarbonFootprintTonnage } from '@/pages/Esg/widgets/EsgCarbonFootprint/EsgCarbonFootprintTonnage';
import { EsgScoreGauge } from '@/pages/Esg/widgets/ESGScore/EsgScoreGauge';
import { WithPortfolioGroupEsgData } from '@/pages/Esg/WithPortfolioGroupEsgData';
import { CurrencyCode } from '@/types/CurrencyCode';

import { A4WidthForPrint } from '../components/A4WidthForPrint';
import { AvoidPageBreaksInside } from '../components/AvoidPageBreakInside';
import { HeaderContainer } from '../components/HeaderContainer';
import { StyledStorebrandLogo } from '../components/StyledStorebrandLogo';
import { usePresentationContext } from '../PresentationContext';

export const EsgScoreAndCarbonFootprintScreen: React.FC = () => {
  const texts = useTexts();
  const dateFormat = useUserDateFormat();

  const { globalFilter } = usePresentationContext();

  return (
    <A4WidthForPrint>
      <WithGap data-test="esg-score-and-carbon-footprint-screen">
        <HeaderContainer>
          <H1>
            {texts.pages.presentation.screens.esg.scoreAndCarbonFootprint}
          </H1>
          <StyledStorebrandLogo />
        </HeaderContainer>

        <WithGap gap="48">
          <Row>
            <Column $span={6}>
              <WidgetHeader
                title={texts.pages.esg.esgScore.title}
                helperProps={{
                  id: 'esg-score',
                  title: texts.pages.esg.esgScore.helpTitle,
                  description: texts.pages.esg.esgScore.helpDescription,
                }}
              />
              <Paragraph>{texts.pages.esg.esgScore.description}</Paragraph>
            </Column>
            <Column $span={6}>
              <WidgetHeader
                title={texts.pages.esg.carbonFootprint.title}
                helperProps={{
                  id: 'esg-carbon-footprint',
                  title: texts.pages.esg.carbonFootprint.helpTitle,
                  description: texts.pages.esg.carbonFootprint.helpDescription,
                }}
              />
              <Paragraph>
                {texts.pages.esg.carbonFootprint.description}
              </Paragraph>
            </Column>
          </Row>

          <WithGap gap="48">
            {globalFilter.portfolioGroups.map((portfolioGroup) => (
              <AvoidPageBreaksInside key={portfolioGroup.id}>
                <WithGap gap="16">
                  <H2>{`${portfolioGroup.id} - ${portfolioGroup.name}`}</H2>

                  <WithPortfolioGroupEsgData
                    portfolioGroupId={portfolioGroup.id}
                    key={portfolioGroup.id}
                    render={(esgData: Esg) => {
                      const carbonFootprintData =
                        esgData.carbonIntensities.find(
                          (score) => score.currency === CurrencyCode.NOK,
                        );

                      return (
                        <>
                          <Hint>
                            {fmsWithTemplate(texts.common.lastUpdated, {
                              date: format(
                                parseISO(esgData.updatedDate),
                                dateFormat,
                              ),
                            })}
                          </Hint>

                          <Row>
                            <Column $span={6}>
                              <EsgScoreGauge
                                esgScore={esgData.esgScore}
                              ></EsgScoreGauge>
                            </Column>
                            <Column $span={6}>
                              {carbonFootprintData?.benchmark ? (
                                <EsgCarbonFootprintComparison
                                  data={carbonFootprintData}
                                />
                              ) : (
                                <EsgCarbonFootprintTonnage
                                  data={carbonFootprintData}
                                />
                              )}
                            </Column>
                          </Row>
                        </>
                      );
                    }}
                  />
                </WithGap>
              </AvoidPageBreaksInside>
            ))}
          </WithGap>
        </WithGap>
      </WithGap>
    </A4WidthForPrint>
  );
};
