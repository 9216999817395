import { format } from 'date-fns';
import React, { FC } from 'react';
import { styled } from 'styled-components';

import { useTexts } from '@/hooks/useTexts';
import { useUserDateFnsLocale, useUserLocale } from '@/i18n/hooks';
import { useOrderPageContext } from '@/pages/Order/OrderPageContext';
import { TransferOrder } from '@/pages/Order/types/Order';
import { formatNumber } from '@/util/formatNumber';

import { useDiscretionaryPortfolios } from '../../OrderDetails/hooks/useDiscretionaryPortfolios';
import { createPortfolioBankAccountLabel } from '../../OrderDetails/utils/createPortfolioBankAccountLabel';
import { expectedTransferDate } from '../../OrderDetails/utils/expectedTransferDate';
import { MainHeaderRow } from './MainHeaderRow';
import { Row } from './Row';

export const TransferOrderDetailsTable: FC = () => {
  const texts = useTexts();
  const locale = useUserLocale();
  const dateFnsLocale = useUserDateFnsLocale();

  const { currentOrder } = useOrderPageContext<TransferOrder>();

  const [fromPortfolio] = useDiscretionaryPortfolios().filter(
    (portfolio) => portfolio.id === currentOrder.fromPortfolioId,
  );

  const [fromPortfolioBankAccount] = fromPortfolio.portfolioBankAccounts.filter(
    (account) =>
      account.accountNumber === currentOrder.fromPortfolioBankAccountNumber,
  );

  const [toPortfolio] = useDiscretionaryPortfolios().filter(
    (portfolio) => portfolio.id === currentOrder.toPortfolioId,
  );

  const [toPortfolioBankAccount] = toPortfolio.portfolioBankAccounts.filter(
    (account) =>
      account.accountNumber === currentOrder.toPortfolioBankAccountNumber,
  );

  return (
    <StyledTable
      cellSpacing="0"
      cellPadding="0"
      data-test="transfer-order-details"
    >
      <thead>
        <MainHeaderRow title={texts.orders.form.table.orderDetails} />
      </thead>

      <tbody>
        <Row
          left={texts.orders.form.table.fromPortfolio}
          right={`${fromPortfolio.id} - ${fromPortfolio.name}`}
        />

        <Row
          left={texts.orders.form.table.fromPortfolioBankAccount}
          right={createPortfolioBankAccountLabel(fromPortfolioBankAccount)}
        />

        <Row
          left={texts.orders.form.table.toPortfolio}
          right={`${toPortfolio.id} - ${toPortfolio.name}`}
        />

        <Row
          left={texts.orders.form.table.toPortfolioBankAccount}
          right={createPortfolioBankAccountLabel(toPortfolioBankAccount)}
        />

        <Row
          left={texts.orders.form.table.amount}
          right={formatNumber(
            currentOrder.amount,
            locale,
            currentOrder.fromPortfolioBankAccountCurrency,
          )}
        />

        <Row
          left={texts.orders.form.table.date}
          right={format(
            currentOrder.expectedTransferDate,
            expectedTransferDate.dateFormat(dateFnsLocale),
          )}
        />

        <Row
          left={texts.orders.form.table.messageToSam}
          right={
            currentOrder.messageToSam?.trim() ||
            texts.orders.form.table.messageNotSpecified
          }
        />
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  line-height: 16px;
  width: 100%;
`;
