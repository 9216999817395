import round from 'lodash/round';

import { AssetPerformanceData } from '@/api/performanceData/models';
import { Locale } from '@/i18n/locale';
import { FMSTexts } from '@/types/fms';
import { formatNumber } from '@/util/formatNumber';
import { roundHalfEven } from '@/util/roundHalfEven';

import { AssetAllocationViewMode } from '../../types/AssetAllocationViewMode';
import { AssetAllocationRow } from '../types/AssetAllocationRow';

/**
 * Map the asset performance data to a total summary row.
 *
 * @param assetPerformance The performance data to summarize.
 * @param locale The current locale used for number formatting.
 * @param texts FMS Texts.
 */
export const mapToAssetAllocationSummaryRow = (
  assetPerformance: AssetPerformanceData,
  locale: Locale,
  viewMode: AssetAllocationViewMode,
  texts: FMSTexts,
): AssetAllocationRow => {
  return {
    name: texts.widgets.assetAllocation.holdingsTable.rows.total,

    ...((viewMode === AssetAllocationViewMode.Holdings ||
      viewMode === AssetAllocationViewMode.All) && {
      marketValue: formatNumber(
        roundHalfEven(assetPerformance.marketValue, 0),
        locale,
      ),

      yearToDate: formatNumber(
        roundHalfEven(assetPerformance.yearToDateAmount, 0),
        locale,
      ),

      weight: formatNumber(
        round(assetPerformance.assetRatioOfTotalPercent, 1),
        locale,
        '%',
        { minimumFractionDigits: 1 },
      ),
    }),

    ...((viewMode === AssetAllocationViewMode.Performance ||
      viewMode === AssetAllocationViewMode.All) && {
      monthToDatePercent: formatNumber(
        round(assetPerformance.monthToDatePercent, 2),
        locale,
        '%',
        { minimumFractionDigits: 2 },
      ),

      monthToDateBenchmark: formatNumber(
        round(assetPerformance.benchmarkMonthToDatePercent, 2),
        locale,
        '%',
        { minimumFractionDigits: 2 },
      ),

      yearToDatePercent: formatNumber(
        round(assetPerformance.yearToDatePercent, 2),
        locale,
        '%',
        { minimumFractionDigits: 2 },
      ),

      yearToDateBenchmark: formatNumber(
        round(assetPerformance.benchmarkYearToDatePercent, 2),
        locale,
        '%',
        { minimumFractionDigits: 2 },
      ),
    }),
  };
};
