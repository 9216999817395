import { OrderValueType } from '@/api/order/models/OrderValueType';
import { useTexts } from '@/hooks/useTexts';

export const useOrderValueTypeText = (valueType: OrderValueType): string => {
  const texts = useTexts();

  switch (valueType) {
    case OrderValueType.Amount:
      return texts.orders.form.valueType.amount;
    case OrderValueType.Percent:
      return texts.orders.form.valueType.percent;
    case OrderValueType.Units:
      return texts.orders.form.valueType.units;
    default:
      throw new Error(`Invalid order value type [${valueType}]`);
  }
};
