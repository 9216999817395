import React, { useState } from 'react';

import { HoldingsCalculationType } from '@/api/portfolioGroupHoldings/models/HoldingsCalculationType';
import { PortfolioHoldingsTable } from '@/components/PortfolioHoldingsTable/PortfolioHoldingsTable';
import { Widget, WidgetHeader } from '@/components/Widget';
import { useTexts } from '@/hooks/useTexts';
import { HoldingsCalculationTypeChips } from '@/pages/Presentation/filters/HoldingsCalculationTypeChips';

interface Props {
  portfolioGroupId: string;
}

export const PortfolioHoldingsTableWidget: React.FC<Props> = ({
  portfolioGroupId,
}) => {
  const texts = useTexts();

  const [selectedCalculationType, setSelectedCalculationType] =
    useState<HoldingsCalculationType>(HoldingsCalculationType.EOD);

  return (
    <Widget data-test={`widget-portfolio-holdings-table-${portfolioGroupId}`}>
      <WidgetHeader
        title={texts.widgets.portfolioHoldings.table.title}
        helperProps={{
          id: 'portfolio-holdings-table',
          title: texts.widgets.portfolioHoldings.table.help.title,
          description: texts.widgets.portfolioHoldings.table.help.description,
        }}
      />
      <HoldingsCalculationTypeChips
        checked={selectedCalculationType}
        onChange={setSelectedCalculationType}
      ></HoldingsCalculationTypeChips>
      <PortfolioHoldingsTable
        portfolioGroupIds={[portfolioGroupId]}
        calculationType={selectedCalculationType}
        expanded
        displayVerticalScrollbar
      />
    </Widget>
  );
};
