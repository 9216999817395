import Breadcrumbs from '@storeblocks/breadcrumbs';
import orderBy from 'lodash/orderBy';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import { QueryState } from '@/api/components/QueryState';
import { Source } from '@/api/contactUsForm/contactUsFormApi';
import { useIHubSharedReportsQuery } from '@/api/reports/reportsApi';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { isNotReady } from '@/api/utils/isNotReady';
import { FundAvailabilityAlert } from '@/components/Alert/FundAvailabilityAlert';
import { AnnouncementWarning } from '@/components/AnnouncementWarning';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { ContactForm } from '@/components/ContactForm/ContactForm';
import { Column, Row } from '@/components/Grid';
import { InternalLink } from '@/components/InternalLink';
import { MainContent } from '@/components/MainContent';
import { OrderToSignAlert } from '@/components/OrderToSignAlert/OrderToSignAlert';
import { PageHeading } from '@/components/PageHeading';
import { WithGap } from '@/components/WithGap';
import { useSearchParams } from '@/hooks/useSearchParams';
import { useTexts } from '@/hooks/useTexts';

import { ReportsProvider } from './context/ReportsProvider';
import { EnterSomeSearchCriteriaAlert } from './EnterSomeSearchCriteriaAlert';
import { ReportsTable } from './Table/ReportsTable';
import { FundReportsToolbar, SearchParams } from './Toolbar/FundReportsToolbar';
import { FundReportsFilter } from './types/FundReportsFilter';
import { Report, ReportSource } from './types/ReportModel';
import { mapIHubReportToReport } from './utils/mapIHubReportToReport';

const defaultIHubFileLimit = 100;

/**
 * Stable reference to an empty array if it is used in the
 * dependency array to other hooks.
 */
const emptyArray: Report[] = [];

export const FundReportsPage: React.FC = () => {
  const navigate = useNavigate();
  const organizationCmId = useOrganizationCmId();
  const texts = useTexts();

  const query = useSearchParams();

  // Initialize the search input field to the text from the query search parameter.
  const [initialQuery] = useState<string | undefined>(
    query.get('query') || undefined,
  );

  const [filter, setFilter] = useState<FundReportsFilter>({
    limit: defaultIHubFileLimit,
    name: initialQuery,
  });
  const isSearchParamsEmpty = !filter.name && !filter.endDate;

  useEffect(() => {
    // ? Do we have a query in the search parameters?
    if (initialQuery) {
      // Remove the query from the search parameters since the text is present
      // in the search field.
      query.delete('query');
      navigate(
        {
          search: query.toString(),
        },
        { replace: true },
      );
    }
  }, [initialQuery]);

  const iHubSharedReportsQuery = useIHubSharedReportsQuery(filter);

  // Map reports to view models.
  const reports = useMemo<Report[]>(() => {
    if (!iHubSharedReportsQuery.data) {
      return emptyArray;
    }

    const ihubReports = iHubSharedReportsQuery.data.map((report) =>
      mapIHubReportToReport(report, ReportSource.IHubShared),
    );

    return orderBy(ihubReports, (report) => report.reportDateISO, ['desc']);
  }, [iHubSharedReportsQuery.data]);

  const handleSearchParamsChanged = (params: SearchParams): void => {
    setFilter({
      name: params.query,
      startDate: params.date,
      endDate: params.date,
      limit: defaultIHubFileLimit,
    });
  };

  return (
    <ReportsProvider>
      <CenteredPageContent data-test="reports-funds-page">
        <AnnouncementWarning />
        <OrderToSignAlert />

        <Breadcrumbs>
          <InternalLink to={`/${organizationCmId}`}>
            {texts.menu.links.home}
          </InternalLink>
          <InternalLink to={`/${organizationCmId}/reports`}>
            {texts.menu.links.reports.reports}
          </InternalLink>
          <InternalLink to={`/${organizationCmId}/reports/funds`}>
            {texts.menu.links.reports.funds}
          </InternalLink>
        </Breadcrumbs>

        <MainContent>
          <WithGap gap="8">
            <PageHeading
              header={texts.menu.links.reports.funds}
              ingress={texts.fundReportsArchivePage.description}
            />
            <Row>
              <Column $span={9}>
                <ContactForm source={Source.FundReports} />
              </Column>
            </Row>
          </WithGap>

          <FundAvailabilityAlert contentType="overview" fluid />

          <FundReportsToolbar
            onSearchParamsChange={handleSearchParamsChanged}
            initialQuery={initialQuery}
          />

          {isNotReady(iHubSharedReportsQuery) ? (
            <QueryState query={iHubSharedReportsQuery} />
          ) : (
            <>
              <ReportsTable reports={reports} />

              {isSearchParamsEmpty && (
                <EnterSomeSearchCriteriaAlert
                  variant="info"
                  title={texts.reportsArchivePage.enterSomeCriteria}
                />
              )}
            </>
          )}
        </MainContent>
      </CenteredPageContent>
    </ReportsProvider>
  );
};
