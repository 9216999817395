import React, { FC } from 'react';
import styled from 'styled-components';

import { Helper } from '@/components/Helper';
import { Hint } from '@/components/Typography';
import { useTexts } from '@/hooks/useTexts';
import { useUserLocale } from '@/i18n/hooks';

interface Props {
  percentage: number;
}

/**
 * Renders a styled text used for displaying a ESG coverage percentage.
 *
 * @param percentage The coverage percentage.
 */
export const EsgCoverageText: FC<Props> = ({ percentage }) => {
  const locale = useUserLocale();
  const texts = useTexts();
  const coverageText = `${
    texts.pages.esg.coverageText
  } ${percentage.toLocaleString(locale)}%`;

  return (
    <HintStyled>
      {coverageText}
      <Helper
        id="esg-coverage"
        description={texts.pages.esg.coverageTextHelper}
      />
    </HintStyled>
  );
};

const HintStyled = styled(Hint)`
  color: var(--color-inactive);
  display: flex;
  align-items: center;
`;
