import { OrderValueType } from '@/api/order/models/OrderValueType';
import { useTexts } from '@/hooks/useTexts';
import { TableColumn } from '@/types/tables';

import { useOrderValueTypeText } from '../../../hooks/useOrderValueTypeText';
import { SubscriptionFundRow } from '../types/SubscriptionFundRow';

export const useCreateSubscriptionFundTableColumns = (
  valueType: OrderValueType,
  hideHoldings: boolean | undefined,
): Array<TableColumn<SubscriptionFundRow>> => {
  const texts = useTexts();

  const valueTypeText = useOrderValueTypeText(valueType);

  const columns: Array<TableColumn<SubscriptionFundRow>> = [
    {
      name: texts.orders.form.table.fund,
      key: 'fund',
      renderType: 'jsx',
      dataType: 'other',
    },
    !hideHoldings && {
      name: texts.orders.form.table.holdings,
      key: 'holdings',
      renderType: 'jsx',
      dataType: 'other',
    },
    {
      name: valueTypeText,
      key: 'amount',
      renderType: 'jsx',
      dataType: 'other',
    },
  ];

  return columns.filter(Boolean);
};
