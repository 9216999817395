import React, { FC } from 'react';

import { EsgCategory } from '@/api/esg/models/EsgCategory';
import { Center } from '@/components/Center';
import { Gauge } from '@/components/Charts';
import { Column, Row } from '@/components/Grid';
import { H2, Paragraph } from '@/components/Typography';
import { useTexts } from '@/hooks/useTexts';

interface Props {
  euTaxonomyEligibility: EsgCategory;
  euTaxonomyAlignment: EsgCategory;
}

export const EsgTaxonomyOverviewWidget: FC<Props> = ({
  euTaxonomyAlignment,
  euTaxonomyEligibility,
}) => {
  const texts = useTexts();
  const taxonomyTexts = texts.pages.esg.taxonomy;

  return (
    <Row>
      <Column $span={3}>
        <Center horizontal vertical>
          <Gauge
            description={taxonomyTexts.eligibilityTitle}
            percent={euTaxonomyEligibility.kpi.value}
          />
        </Center>
      </Column>

      <Column $span={3}>
        <Center horizontal vertical>
          <Gauge
            description={taxonomyTexts.alignmentTitle}
            percent={euTaxonomyAlignment.kpi.value}
          />
        </Center>
      </Column>

      <Column $span={6}>
        <div>
          <H2>{taxonomyTexts.details.overviewTitle}</H2>
          <Paragraph>{taxonomyTexts.details.overview}</Paragraph>
        </div>
      </Column>
    </Row>
  );
};
