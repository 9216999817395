import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { useTexts } from '@/hooks/useTexts';

export const InvestmentSolutionsTable: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const texts = useTexts();

  return (
    <Table cellSpacing={0}>
      <thead>
        <tr>
          <th></th>
          <th>{texts.pages.fundEsg.fund.title}</th>
        </tr>
      </thead>

      <tbody>{children}</tbody>
    </Table>
  );
};

interface InvestmentSolutionsRowProps {
  title: string;
  value: number;
}

export const InvestmentSolutionsRow: React.FC<InvestmentSolutionsRowProps> = ({
  title,
  value,
}) => {
  return (
    <tr>
      <td>{title}</td>
      <td>{value} %</td>
    </tr>
  );
};

const Table = styled.table`
  line-height: 24px;
  table-layout: auto;
  width: 100%;

  & th:nth-of-type(2),
  & td:nth-of-type(2) {
    text-align: right;
    white-space: nowrap;
  }

  & tr:nth-child(even) {
    background: rgba(25, 25, 25, 0.05);
  }
`;
