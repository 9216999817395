import Breadcrumbs from '@storeblocks/breadcrumbs';
import React, { FC } from 'react';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { InternalLink } from '@/components/InternalLink';
import { InternalLinkList } from '@/components/InternalLinkList';
import { MainContent } from '@/components/MainContent';
import { PageHeading } from '@/components/PageHeading';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';

export const IntermediateDiscretionaryOrderPage: FC = () => {
  const texts = useTexts();
  const organizationCmId = useOrganizationCmId();

  const links = [
    <InternalLink
      key={`/${organizationCmId}/discretionary/deposit`}
      to={`/${organizationCmId}/discretionary/deposit`}
      data-test="discretionary-deposit-link"
    >
      {texts.menu.links.trading.deposit}
    </InternalLink>,
    <InternalLink
      key={`/${organizationCmId}/discretionary/withdrawal`}
      to={`/${organizationCmId}/discretionary/withdrawal`}
      data-test="discretionary-withdrawal-link"
    >
      {texts.menu.links.trading.withdrawal}
    </InternalLink>,
    <InternalLink
      key={`/${organizationCmId}/discretionary/transfer`}
      to={`/${organizationCmId}/discretionary/transfer`}
      data-test="discretionary-transfer-link"
    >
      {texts.menu.links.trading.transfer}
    </InternalLink>,
  ];

  return (
    <WithGap>
      <Breadcrumbs>
        <InternalLink to={`/${organizationCmId}`}>
          {texts.menu.links.home}
        </InternalLink>
        <InternalLink to={`/${organizationCmId}/discretionary`}>
          {texts.menu.links.trading.discretionary}
        </InternalLink>
      </Breadcrumbs>

      <MainContent>
        <PageHeading header={texts.menu.links.trading.discretionary} />

        <InternalLinkList links={links} />
      </MainContent>
    </WithGap>
  );
};
