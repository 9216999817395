import Button from '@storeblocks/button';
import { SystemIcon } from '@storeblocks/icon';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { InternalLink } from '@/components/InternalLink';
import { LeftContainer } from '@/components/Navbar/LeftContainer';
import { StorebrandLogoWithMediaQuery } from '@/components/Navbar/Logo/StorebrandLogoWithMediaQuery';
import { Nav } from '@/components/Navbar/Nav';
import { RightContainer } from '@/components/Navbar/RightContainer';
import { useTexts } from '@/hooks/useTexts';
import { TrackingElement, useGetTrackId } from '@/tracking';

export const Navbar: React.FC = () => {
  const texts = useTexts();
  const organizationCmId = useOrganizationCmId();
  const getTrackId = useGetTrackId();

  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullScreen = (): void => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const handleFullscreenChange = (): void => {
    setIsFullscreen(!!document.fullscreenElement);
  };

  // We need to manually handle the switch to fullscreen mode when the user
  // presses F11. This is necessary because we are only able to close the
  // fullscreen mode with a button or the ESC key if the fullscreen mode
  // is triggered by document.documentElement.requestFullscreen().
  const handleF11FullscreenChange = (event: KeyboardEvent): void => {
    if (event.key === 'F11') {
      event.preventDefault();
      toggleFullScreen();
    }
  };

  useEffect(() => {
    window.addEventListener('fullscreenchange', handleFullscreenChange);
    window.addEventListener('keydown', handleF11FullscreenChange);

    return () => {
      window.removeEventListener('fullscreenchange', handleFullscreenChange);
      window.removeEventListener('keydown', handleF11FullscreenChange);
    };
  }, []);

  return (
    <StyledNav>
      <Container>
        <LeftContainer>
          <StorebrandLogoWithMediaQuery to={`/${organizationCmId}`} />
        </LeftContainer>

        <RightContainer>
          <Button
            variant="text"
            onClick={toggleFullScreen}
            data-trackid={getTrackId(
              'toggle-fullscreen',
              TrackingElement.Button,
            )}
          >
            {isFullscreen
              ? texts.pages.presentation.closeFullscreen
              : texts.pages.presentation.fullscreen}
          </Button>
          <Button
            as={InternalLink}
            to={`/${organizationCmId}/reports/presentation`}
            variant="text"
            title={texts.pages.presentation.returnToPresentationLayout}
          >
            <MenuIcon name="menu" />
          </Button>
        </RightContainer>
      </Container>
    </StyledNav>
  );
};

const StyledNav = styled(Nav)`
  @media print {
    display: none;
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 0 24px;
  display: flex;
  gap: 16px;
`;

const MenuIcon = styled(SystemIcon)`
  font-size: 24px;
  color: var(--color-on-background);
`;
