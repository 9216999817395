import Breadcrumbs from '@storeblocks/breadcrumbs';
import React, { useState } from 'react';

import { Source } from '@/api/contactUsForm/contactUsFormApi';
import { useOrganizationCmId } from '@/api/userSettings/hooks/useOrganizationCmId';
import { AnnouncementWarning } from '@/components/AnnouncementWarning';
import { CenteredPageContent } from '@/components/CenteredPageContent';
import { ContactForm } from '@/components/ContactForm/ContactForm';
import { Column, Row } from '@/components/Grid';
import { InternalLink } from '@/components/InternalLink';
import { MainContent } from '@/components/MainContent';
import { OrderToSignAlert } from '@/components/OrderToSignAlert/OrderToSignAlert';
import { PageHeading } from '@/components/PageHeading';
import { WithGap } from '@/components/WithGap';
import { useTexts } from '@/hooks/useTexts';

import { Content } from './Content';
import { ReportsProvider } from './context/ReportsProvider';
import { useReports } from './hooks/useReports';
import { ReportsToolbar, SearchParams } from './Toolbar/ReportsToolbar';
import { ReportsFilter } from './types/ReportsFilter';
import { noSearchParamsProvided } from './utils/noSearchParamsProvided';

const defaultIHubFileLimit = 100;

export const PortfolioReportsPage: React.FC = () => {
  const organizationCmId = useOrganizationCmId();
  const texts = useTexts();

  const [filter, setFilter] = useState<ReportsFilter>({
    reportSource: [],
    limit: defaultIHubFileLimit,
  });

  const { reports, reportsIsPending, reportsIsError } = useReports(filter);

  const handleSearchParamsChanged = (params: SearchParams): void => {
    setFilter({
      name: params.query,
      startDate: params.date,
      endDate: params.date,
      reportSource: params.sources,
      limit: defaultIHubFileLimit,
    });
  };

  return (
    <ReportsProvider>
      <CenteredPageContent data-test="reports-portfolio-page">
        <AnnouncementWarning />
        <OrderToSignAlert />

        <Breadcrumbs>
          <InternalLink to={`/${organizationCmId}`}>
            {texts.menu.links.home}
          </InternalLink>
          <InternalLink to={`/${organizationCmId}/reports`}>
            {texts.menu.links.reports.reports}
          </InternalLink>
          <InternalLink to={`/${organizationCmId}/reports/portfolio`}>
            {texts.menu.links.reports.portfolio}
          </InternalLink>
        </Breadcrumbs>

        <MainContent>
          <WithGap gap="8">
            <PageHeading
              header={texts.menu.links.reports.portfolio}
              ingress={texts.reportsArchivePage.description}
            />

            <Row>
              <Column $span={9}>
                <ContactForm source={Source.PortfolioReports} />
              </Column>
            </Row>
          </WithGap>

          <ReportsToolbar onSearchParamsChange={handleSearchParamsChanged} />

          <Content
            reports={reports}
            isPending={reportsIsPending}
            isError={reportsIsError}
            isSearchParamsEmpty={noSearchParamsProvided(filter)}
          />
        </MainContent>
      </CenteredPageContent>
    </ReportsProvider>
  );
};
