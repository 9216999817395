import round from 'lodash/round';
import sumBy from 'lodash/sumBy';

import { AssetPerformance } from '@/api/performanceData/models';
import { Locale } from '@/i18n/locale';
import { FMSTexts } from '@/types/fms';
import { formatNumber } from '@/util/formatNumber';
import { roundHalfEven } from '@/util/roundHalfEven';

import { AssetAllocationViewMode } from '../../types/AssetAllocationViewMode';
import { AssetAllocationRow } from '../types/AssetAllocationRow';

/**
 * Map the asset performance data to a total summary row.
 *
 * @param assetPerformance The performance data to summarize.
 * @param locale The current locale used for number formatting.
 * @param texts FMS Texts.
 * @param fraction The weight fraction the total value of this row represents.
 * This is used for correctly calculating the total weight when data from multiple portfolios are displayed.
 * E.x. if there are 4 portfolios in total the fraction of this portfolio row would be '1/4' or '0.25'.
 */
export const mapToMultiAssetAllocationSummaryRow = (
  assetPerformances: Array<AssetPerformance>,
  locale: Locale,
  texts: FMSTexts,
  viewMode: AssetAllocationViewMode,
  fraction: number,
): AssetAllocationRow => {
  return {
    name: texts.widgets.assetAllocation.holdingsTable.rows.total,

    ...((viewMode === AssetAllocationViewMode.Holdings ||
      viewMode === AssetAllocationViewMode.All) && {
      marketValue: formatNumber(
        roundHalfEven(
          sumBy(
            assetPerformances,
            (assetPerformance) => assetPerformance.assets[0].marketValue,
          ),
          0,
        ),
        locale,
      ),

      yearToDate: formatNumber(
        roundHalfEven(
          sumBy(
            assetPerformances,
            (assetPerformance) => assetPerformance.assets[0].yearToDateAmount,
          ),
          0,
        ),
        locale,
      ),

      weight: formatNumber(
        round(
          sumBy(
            assetPerformances,
            (assetPerformance) =>
              assetPerformance.assets[0].assetRatioOfTotalPercent,
          ) * fraction,
          1,
        ),
        locale,
        '%',
        { minimumFractionDigits: 1 },
      ),
    }),

    ...((viewMode === AssetAllocationViewMode.Performance ||
      viewMode === AssetAllocationViewMode.All) && {
      monthToDatePercent: formatNumber(
        round(
          sumBy(
            assetPerformances,
            (assetPerformance) => assetPerformance.assets[0].monthToDatePercent,
          ),
          2,
        ),
        locale,
        '%',
        { minimumFractionDigits: 2 },
      ),

      monthToDateBenchmark: formatNumber(
        round(
          sumBy(
            assetPerformances,
            (assetPerformance) =>
              assetPerformance.assets[0].benchmarkMonthToDatePercent,
          ),
          2,
        ),
        locale,
        '%',
        { minimumFractionDigits: 2 },
      ),

      yearToDatePercent: formatNumber(
        round(
          sumBy(
            assetPerformances,
            (assetPerformance) => assetPerformance.assets[0].yearToDatePercent,
          ),
          2,
        ),
        locale,
        '%',
        { minimumFractionDigits: 2 },
      ),

      yearToDateBenchmark: formatNumber(
        round(
          sumBy(
            assetPerformances,
            (assetPerformance) =>
              assetPerformance.assets[0].benchmarkYearToDatePercent,
          ),
          2,
        ),
        locale,
        '%',
        { minimumFractionDigits: 2 },
      ),
    }),

    subRows: [],
  };
};
